import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { chatContext } from "../context/ChatContext"
import '../style/container.css'
import generateUniqueId from "../util/generateuniqueid";
import typeText from "../util/typetext";
import loader from "../util/loader";
import chatStripe from "../util/chatStripe";
import { Divider } from "@material-ui/core";
import ConfidenceItems from "./ConfidenceItems";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "985px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function ConfidenceSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    loadingInterval,
    typingInterval,
    selfDefinedValue,
    setSelfDefinedValue,
  } = useContext(chatContext);

  const formRef = useRef(null);
  const [lastInputValue, setLastInputValue] = useState("");

  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [productName, setProductName] = useState("");
  const [productElements, setProductElements] = useState("")
  const [productEndResult, setProductEndResult] = useState("");
  const [productConfidence1, setProductConfidence1] = useState("")
  const [productConfidence2, setProductConfidence2] = useState("")
  const [reassurance, setReassurance] = useState("")
  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  let marketingPrompt


  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductElementsChange = (event) => {
    setProductElements(event.target.value);
  };

  const handleProductEndResultChange = (event) => {
    setProductEndResult(event.target.value);
  };

  const handleProductConfidence1Change = (event) => {
    setProductConfidence1(event.target.value);
  };

  const handleProductConfidence2Change = (event) => {
    setProductConfidence2(event.target.value);
  };

  const handleReassuranceChange = (event) => {
    setReassurance(event.target.value);
  };

  let query 
  query = `产品名字和种类是${productName}, 独特成分/技术是${productElements}，功效（最终结果）是${productEndResult},
  信心事项1是${productConfidence1}，信心事项2是${productConfidence2}，reassurance是${reassurance}`




  async function handleSubmit( id, e, lastInput = false, speechRes = false) {
    e && e.preventDefault();
    // const data = new FormData(formRef.current);


    if (id === 1) {
      marketingPrompt = `流程里不用太专业，要生动俏皮，语法简单粗暴可是又不失礼貌，要简单到5岁的小孩子都看得懂
      流程分为五个段落, 写得更生活化，加入一些internet slang
      每个流程句子里都要带有1个emoji
      注意不要流程格式里的指示写在生成出来的结果
      list {
        list-style-type: emojis
      }
      
      流程格式：
      注意不要自我介绍，不要称呼顾客。直接进入下面段落，不用加Header
      第一个段落先用一个markdown list把产品独特的成分/技术列出来，解释各个成分/技术
      第二个段落把信心事项1带出来，简短解释，生动有趣，目的是要客户放下防备心
      第三个段落把信心事项2带出来，简短解释，生动有趣，目的是要客户对我们的专业有信心
      第四个段落用一个简短的日常生活运用场景的故事叙述我们的产品，目的是要让客户觉得贴切，增加信心和购买欲
      第五个段落简短概括写出${reassurance}，然后直接结束流程。不用呼吁客户，下面不用多写东西了。
      `
    } else if (id === 2) {
      query = `产品名字和种类是${productName}, 独特成分/技术是${productElements}，功效（最终结果）是${productEndResult},
      客户群是${productConfidence1}，独特卖点是${productConfidence2}`

      marketingPrompt = `流程里不用太专业，要生动俏皮，语法简单粗暴可是又不失礼貌，要简单到5岁的小孩子都看得懂
      流程分为五个段落, 写得更生活化，加入一些internet slang
      每个流程句子里都要带有1个emoji
      注意不要流程格式里的指示写在生成出来的结果
      list {
        list-style-type: emojis
      }
      
      流程格式：
      注意不要自我介绍，不要称呼顾客。直接进入下面段落，不用加Header
      第一个段落用一个简短的抓人眼球的方式介绍我们产品和外面市场上的不一样
      第二个段落针对我们现代马来西亚客户群详细解释我们具体为什么和外面不一样，原因是什么<这个部分请你发挥创意，不用跟着我给的资料>
      第三个段落用一个markdown list把产品独特的成分/技术列出来，解释各个成分/技术
      第四个段落简短地概括我们产品主打的最终结果，例子：所以无论是...，然后直接结束流程。不用呼吁客户，下面不用多写东西了。`
    } else if (id === 3) {
      query = `产品名字和种类是${productName}, 独特卖点是${productConfidence2}，最终结果是${productEndResult}, ${productConfidence1}天疗程针对的痛点是${productConfidence1}， 
      ${productConfidence1}天疗程针对的痛点是${productConfidence1}
      `

      marketingPrompt = `流程里不用太专业，要生动俏皮，语法简单粗暴可是又不失礼貌，要简单到5岁的小孩子都看得懂
      流程分为五个段落, 写得更生活化，加入一些internet slang
      每个流程句子里都要带有1个emoji
      注意不要流程格式里的指示写在生成出来的结果
      list {
        list-style-type: emojis
      }
      
      流程格式：
      注意不要自我介绍，不要称呼顾客。直接进入下面流程，不用加Header
      第一个句子用一个亲切的抓人眼球的方式跟客户说'你看下你用了${productConfidence1}天-${productConfidence1}天后，会看到什么效果'
      下面就开始用一个markdown list整齐排列我给你的资料，注意痛点2会是你需要自己想出来的资料

      流程格式：

      header: <第一个疗程天数>
      - 痛点1
        - 故事描述痛点具体的痛苦点
        - 用了我们的产品在<第一个疗程天数>后具体的最终结果
      - 痛点2 
        - 故事描述痛点具体的痛苦点
        - 用了我们的产品在<第一个疗程天数>后具体的最终结果

      header: <第二个疗程天数>
      - 痛点1
        - 故事描述痛点具体的痛苦点
        - 用了我们的产品在<第二个疗程天数>后具体的最终结果
      - 痛点2 
        - 故事描述痛点具体的痛苦点
        - 用了我们的产品在<第二个疗程天数>后具体的最终结果

      资料写完就直接结束流程。不用呼吁客户，下面不用多写东西了。`
    } else if (id === 4) {
      query = `产品名字和种类是${productName}, 独特卖点是${productConfidence2}，客户群是${productConfidence1}, 详细成分是${productElements}，最终结果是${productEndResult}
      `

      marketingPrompt = `流程里不用太专业，要生动俏皮，语法简单粗暴可是又不失礼貌，要简单到5岁的小孩子都看得懂
      流程分为五个段落, 写得更生活化，加入一些internet slang
      每个流程句子里都要带有1个emoji
      注意不要流程格式里的指示写在生成出来的结果
      list {
        list-style-type: emojis
      }
      <这个部分请你发挥创意，不用跟着我给的资料>

      流程格式：
      注意不要自我介绍，不要称呼顾客。直接进入下面段落，不用加Header
      第一个段落用一个简短亲切的抓人眼球的方式和顾客说[你也许不知道...]
      第二个段落针对我们现代马来西亚客户群把我们的独特卖点用一个句子带出来
      第三个段落用一个markdown list把产品详细的成分/技术一个个列出来，不用解释。列完后用一个句子概括我们产品的强大。
      第四个段落用一个markdown list把之前写出来的成分的功效一个个列出来，可以发挥创意写独特的功效。然后直接结束流程。不用呼吁客户，下面不用多写东西了。`
    }

    
    let resPrompt = `${query + marketingPrompt}`
    let payload = { selfDefinedValue, query, resPrompt}

    if (!query) return;

    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // bot's chatStripe
    let uniqueId;
    uniqueId = generateUniqueId();
    setLastUniqueId(uniqueId);
    // chat_container_ref.current.innerHTML += chatStripe(true, " ", uniqueId);
    chat_container_ref.current.innerHTML = chatStripe(true, " ", uniqueId) + chat_container_ref.current.innerHTML;

    chat_container_ref.current.scrollTop =
      chat_container_ref.current.scrollHeight;
    const loadingDiv = document.getElementById(
      lastInput ? lastUniqueId : uniqueId
    );
    // document.querySelector(".MuiCardContent-root")
    if (lastInput)
      loadingDiv.style.color = classes === "light" ? "#383838" : "#dcdcdc";

    loader(loadingDiv, loadingInterval);

    // fetching data from server 
    fetch("https://ctg-api.onrender.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: payload,
        name: auth.user.displayName,
        uid: auth.user.uid,
        email: auth.user.email
      }),
    })
      .then(async (response) => {
        clearInterval(loadingInterval.current);
        loadingDiv.innerHTML = "";

        if (response.ok) {
          setError(false);
          const data = await response.json();
          const parsedData = data.bot.trim();

          if (parsedData === "") {
            loadingDiv.innerHTML =
              "No results Found 😞\n<small>Change model</small>";
            setLoading(false);
          } else {
            setTyping(true);
            typeText(
              loadingDiv,
              chat_container_ref.current,
              parsedData,
              typingInterval,
              setLoading,
              setTyping
            );
          }
        } else {
          setError(true);
          setLoading(false);
          // const err = await response.text();
          loadingDiv.innerHTML =
            "An error occurred. \n<small>Hint: Try to <b>reduce your word count</b> or check your internet connection and then <b>try again</b><small>";
          loadingDiv.style.color = "#EF4444";
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        loadingDiv.innerHTML =
          "Sorry, server is down right now, try again later";
        loadingDiv.style.color = "#EF4444";
        clearInterval(loadingInterval.current);
        console.log(err)
      });
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <ConfidenceItems 
              handleSubmit={handleSubmit} 
              onProductNameChange={handleProductNameChange}
              onProductElementsChange={handleProductElementsChange}
              onProductEndResultChange={handleProductEndResultChange}
              onProductConfidence1Change={handleProductConfidence1Change}
              onProductConfidence2Change={handleProductConfidence2Change}
              onReassuranceChange={handleReassuranceChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
      </Container>
    </Section>
  );
}

export default ConfidenceSection;



function OutputContainer({ typing, load, error, chatRef, id }) {
  const classes = useStyles({ typing, load, error });

  return (
    <div ref={chatRef} id={id}>
      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}