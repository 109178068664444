import React from "react";
import Meta from "../components/Meta";
import DescActSection from "../components/DescActSection";
import { requireProUser } from "../util/auth";

function DescActPage(props) {
  return (
    <>
      <Meta title="Description - Action Generator" />
      <DescActSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Description - Action"
        subtitle="中文文案"
      />
    </>
  );
}

export default requireProUser(DescActPage);
