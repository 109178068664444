import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import SwotSection from "../../components/eng/SwotSection";

function SwotPage(props) {
  return (
    <>
      <Meta title="SWOT Analysis Report Generator (English)" />
      <SwotSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="强弱危机分析 (英文)"
        subtitle="英文 SWOT"
      />
    </>
  );
}

export default requireProUser(SwotPage);
