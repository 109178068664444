import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import { RefreshProvider } from "../context/RefreshContext";
import BannedPage from "./banned";

import TrackingPage from "./track";
import ImgPage from "./image";
import ReplacePage from "./replace";
import AdminPage from "./admin";
import LandingPage from "./landing";
import TestPage from "./test";
import ConfidencePage from "./confidence";

import TutorialPage from "./tutorial";
import HistoryPage from "./history";
import ImageHistoryPage from "./imagehistory";
import ReplaceHistoryPage from "./replacehistory";
import ScraperHistoryPage from "./scraperhistory";
import SavedReplyPage from "./savedreply";
import ContentManagementPage from "./contentmanagement";
import AnnouncementPage from "./announcement";
import PaymentPage from "./payment";
import PaymentResultPage from "./payment_result";
import SettingsPage from "./settings";

import AttnDemPage from "./attn_dem";
import DescActPage from "./desc_act";
import SimplifierPage from "./simplifier";
import UspPage from "./usp";
import SwotPage from "./swot";
import ReplyPage from "./reply";
import CAGeneratorPage from "./contentanglegenerator";
import ImageContentPage from "./imagecontent";
import XhsPage from "./xhs";

import CfMenuPage from "./menupage";
import CfProductMenu from "../components/CfProductMenu";
import CfServiceMenu from "../components/CfServiceMenu";
import CfEndResultPage from "./confidencesub/cfendresult";
import CfGongSiPage from "./confidencesub/cfgongsi";
import CfZhuanYePage from "./confidencesub/cfzhuanye";
import CfJiangXiangPage from "./confidencesub/cfjiangxiang";
import CfQuanWeiPage from "./confidencesub/cfquanwei";
import CfJianZhengPage from "./confidencesub/cfjianzheng";
import CfXinXinPage from "./confidencesub/cfxinxin";
import CfUspPage from "./confidencesub/cfusp";
import CfIngredientsPage from "./confidencesub/cfingredients";
import CfTheoryPage from "./confidencesub/cftheory";

import DramaPage from "./dramasub/drama";
import ScriptPage from "./dramasub/script";
import PepcProblemPage from "./dramasub/pepcproblem";
import PepcEndResPage from "./dramasub/pepcendres";

import MalayAttnDemPage from "./malaysub/attn_dem";
import MalayDescActPage from "./malaysub/desc_act";
import MalaySimplifierPage from "./malaysub/simplifier";
import MalayUspPage from "./malaysub/usp";
import MalaySwotPage from "./malaysub/swot";
import MalayReplyPage from "./malaysub/reply";
import MalayCAGeneratorPage from "./malaysub/contentanglegenerator";

import MalayCfMenuPage from "./menupagemalay";
import MalayCfProductMenu from "../components/malay/CfProductMenu";
import MalayCfServiceMenu from "../components/malay/CfServiceMenu";
import MalayCfEndResultPage from "./malaysub/cfendresult";
import MalayCfGongSiPage from "./malaysub/cfgongsi";
import MalayCfZhuanYePage from "./malaysub/cfzhuanye";
import MalayCfJiangXiangPage from "./malaysub/cfjiangxiang";
import MalayCfQuanWeiPage from "./malaysub/cfquanwei";
import MalayCfJianZhengPage from "./malaysub/cfjianzheng";
import MalayCfXinXinPage from "./malaysub/cfxinxin";
import MalayCfUspPage from "./malaysub/cfusp";
import MalayCfIngredientsPage from "./malaysub/cfingredients";
import MalayCfTheoryPage from "./malaysub/cftheory";
import MalayCf2Page from "./malaysub/cf2generator";

import EngAttnDemPage from "./engsub/attn_dem";
import EngDescActPage from "./engsub/desc_act";
import EngSimplifierPage from "./engsub/simplifier";
import EngUspPage from "./engsub/usp";
import EngSwotPage from "./engsub/swot";
import EngReplyPage from "./engsub/reply";
import EngCAGeneratorPage from "./engsub/contentanglegenerator";

import EngCfMenuPage from "./menupageen";
import EngCfProductMenu from "../components/eng/CfProductMenu";
import EngCfServiceMenu from "../components/eng/CfServiceMenu";
import EngCfEndResultPage from "./engsub/cfendresult";
import EngCfGongSiPage from "./engsub/cfgongsi";
import EngCfZhuanYePage from "./engsub/cfzhuanye";
import EngCfJiangXiangPage from "./engsub/cfjiangxiang";
import EngCfQuanWeiPage from "./engsub/cfquanwei";
import EngCfJianZhengPage from "./engsub/cfjianzheng";
import EngCfXinXinPage from "./engsub/cfxinxin";
import EngCfUspPage from "./engsub/cfusp";
import EngCfIngredientsPage from "./engsub/cfingredients";
import EngCfTheoryPage from "./engsub/cftheory";
import EngCf2Page from "./engsub/cf2generator";


import ReelsPage from "./reels"
import RembgPage from "./rembg"
import Cf2Page from "./cf2generator"
import logoInverted from "../assets/logoInverted.png";
import logo from "../assets/logo.png";
import AccessDeniedPage from "./accessdenied";
import DataDeletionRequest from "../components/DataDeletionRequest";

import ColorPalettePage from "./colorpalette/colorpalette";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <RefreshProvider>
            <Router>
              <>
                <Navbar
                  color="default"
                  logo={logo}
                  logoInverted={logoInverted}
                />

                <Switch>
                  <Route exact path="/" component={IndexPage} />

                  <Route exact path="/about" component={AboutPage} />

                  <Route exact path="/faq" component={FaqPage} />

                  <Route exact path="/contact" component={ContactPage} />

                  <Route exact path="/pricing" component={PricingPage} />

                  <Route exact path="/dashboard" component={DashboardPage} />

                  {/* Main User Navigation */}
                  <Route exact path="/tutorial" component={TutorialPage} />
                  <Route exact path="/user-history" component={HistoryPage} />
                  <Route exact path="/image-history" component={ImageHistoryPage} />
                  <Route exact path="/replace-history" component={ReplaceHistoryPage} />
                  <Route exact path="/ad-analyzer" component={ScraperHistoryPage} />
                  <Route exact path="/savedreply" component={SavedReplyPage} />
                  <Route exact path="/content-management" component={ContentManagementPage} />
                  <Route exact path="/announcement" component={AnnouncementPage} />
                  <Route exact path="/payment" component={PaymentPage} />
                  <Route exact path="/payment_result" component={PaymentResultPage} />
                  <Route exact path="/settings" component={SettingsPage} />

                  {/* Main Functionality */}
                  <Route exact path="/attn_dem" component={AttnDemPage} />
                  <Route exact path="/desc_act" component={DescActPage} />
                  <Route exact path="/simplifier" component={SimplifierPage} />
                  <Route exact path="/usp" component={UspPage} />
                  <Route exact path="/swot" component={SwotPage} />
                  <Route exact path="/reply" component={ReplyPage} />
                  <Route exact path="/contentanglegenerator" component={CAGeneratorPage} />
                  <Route exact path="/imagecontent" component={ImageContentPage} />
                  <Route exact path="/xhs" component={XhsPage} />

                  {/* Main Functionality ENG */}
                  <Route exact path="/eng/attn_dem" component={EngAttnDemPage} />
                  <Route exact path="/eng/desc_act" component={EngDescActPage} />
                  <Route exact path="/eng/simplifier" component={EngSimplifierPage} />
                  <Route exact path="/eng/usp" component={EngUspPage} />
                  <Route exact path="/eng/swot" component={EngSwotPage} />
                  <Route exact path="/eng/reply" component={EngReplyPage} />
                  <Route exact path="/eng/contentanglegenerator" component={EngCAGeneratorPage} />

                  {/* Main Functionality BM */}
                  <Route exact path="/malay/attn_dem" component={MalayAttnDemPage} />
                  <Route exact path="/malay/desc_act" component={MalayDescActPage} />
                  <Route exact path="/malay/simplifier" component={MalaySimplifierPage} />
                  <Route exact path="/malay/usp" component={MalayUspPage} />
                  <Route exact path="/malay/swot" component={MalaySwotPage} />
                  <Route exact path="/malay/reply" component={MalayReplyPage} />
                  <Route exact path="/malay/contentanglegenerator" component={MalayCAGeneratorPage} />
                  

                  <Route exact path="/track" component={TrackingPage} />

                  {/* <Route exact path="/confidence" component={ConfidencePage} /> */}

                  <Route exact path="/cfmenu" component={CfMenuPage} />

                  <Route exact path="/confidence/product" component={CfProductMenu} />
                  <Route exact path="/confidence/service" component={CfServiceMenu} />

                  <Route exact path="/confidence/endresult" component={CfEndResultPage} />
                  <Route exact path="/confidence/company" component={CfGongSiPage} />
                  <Route exact path="/confidence/professional" component={CfZhuanYePage} />
                  <Route exact path="/confidence/awards" component={CfJiangXiangPage} />
                  <Route exact path="/confidence/authority" component={CfQuanWeiPage} />
                  <Route exact path="/confidence/testi" component={CfJianZhengPage} />
                  <Route exact path="/confidence/cfreply" component={CfXinXinPage} />
                  <Route exact path="/confidence/usp" component={CfUspPage} />
                  <Route exact path="/confidence/ingredients" component={CfIngredientsPage} />
                  <Route exact path="/confidence/theory" component={CfTheoryPage} />


                  <Route exact path="/eng/cfmenu" component={EngCfMenuPage} />

                  <Route exact path="/eng/confidence/product" component={EngCfProductMenu} />
                  <Route exact path="/eng/confidence/service" component={EngCfServiceMenu} />

                  <Route exact path="/eng/confidence/endresult" component={EngCfEndResultPage} />
                  <Route exact path="/eng/confidence/company" component={EngCfGongSiPage} />
                  <Route exact path="/eng/confidence/professional" component={EngCfZhuanYePage} />
                  <Route exact path="/eng/confidence/awards" component={EngCfJiangXiangPage} />
                  <Route exact path="/eng/confidence/authority" component={EngCfQuanWeiPage} />
                  <Route exact path="/eng/confidence/testi" component={EngCfJianZhengPage} />
                  <Route exact path="/eng/confidence/cfreply" component={EngCfXinXinPage} />
                  <Route exact path="/eng/confidence/usp" component={EngCfUspPage} />
                  <Route exact path="/eng/confidence/ingredients" component={EngCfIngredientsPage} />
                  <Route exact path="/eng/confidence/theory" component={EngCfTheoryPage} />
                  <Route exact path="/eng/cfgenerator" component={EngCf2Page} />

                  <Route exact path="/malay/cfmenu" component={MalayCfMenuPage} />

                  <Route exact path="/malay/confidence/product" component={MalayCfProductMenu} />
                  <Route exact path="/malay/confidence/service" component={MalayCfServiceMenu} />

                  <Route exact path="/malay/confidence/endresult" component={MalayCfEndResultPage} />
                  <Route exact path="/malay/confidence/company" component={MalayCfGongSiPage} />
                  <Route exact path="/malay/confidence/professional" component={MalayCfZhuanYePage} />
                  <Route exact path="/malay/confidence/awards" component={MalayCfJiangXiangPage} />
                  <Route exact path="/malay/confidence/authority" component={MalayCfQuanWeiPage} />
                  <Route exact path="/malay/confidence/testi" component={MalayCfJianZhengPage} />
                  <Route exact path="/malay/confidence/cfreply" component={MalayCfXinXinPage} />
                  <Route exact path="/malay/confidence/usp" component={MalayCfUspPage} />
                  <Route exact path="/malay/confidence/ingredients" component={MalayCfIngredientsPage} />
                  <Route exact path="/malay/confidence/theory" component={MalayCfTheoryPage} />
                  <Route exact path="/malay/cfgenerator" component={MalayCf2Page} />

                  <Route exact path="/video/idea" component={DramaPage} />
                  <Route exact path="/video/script" component={ScriptPage} />

                  <Route exact path="/video/pepc-p" component={PepcProblemPage} />
                  <Route exact path="/video/pepc-2" component={PepcEndResPage} />

                  <Route exact path="/reels" component={ReelsPage} />
                  <Route exact path="/rembg" component={RembgPage} />
                  <Route exact path="/cfgenerator" component={Cf2Page} />
                  
                  <Route exact path="/image" component={ImgPage} />

                  <Route exact path="/replace" component={ReplacePage} />

                  <Route exact path="/bannedwords" component={BannedPage} />

                  <Route
                    exact
                    path="/access-denied"
                    component={AccessDeniedPage}
                  />

                  <Route exact path="/auth/:type" component={AuthPage} />

                  <Route exact path="/admin" component={AdminPage} />

                  <Route exact path="/landing" component={LandingPage} />

                  <Route exact path="/legal/:section" component={LegalPage} />

                  <Route
                    exact
                    path="/purchase/:plan"
                    component={PurchasePage}
                  />

                  <Route exact path="/test123" component={TestPage} />

                  <Route
                    exact
                    path="/delete-data"
                    component={DataDeletionRequest}
                  />

                  <Route
                    exact
                    path="/color-palette"
                    component={ColorPalettePage}
                  />

                  <Route
                    exact
                    path="/firebase-action"
                    component={FirebaseActionPage}
                  />

                  <Route component={NotFoundPage} />
                </Switch>

                <Footer
                  bgColor="light"
                  size="normal"
                  bgImage=""
                  bgImageOpacity={1}
                  description="A short description of what you do here"
                  copyright={`© ${new Date().getFullYear()} CTG`}
                  logo={logo}
                  logoInverted={logoInverted}
                  sticky={true}
                />
              </>
            </Router>
          </RefreshProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
