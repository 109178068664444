import React, { useState } from 'react';
import { Button, TextField, Container, Typography } from '@material-ui/core';

const DataDeletionRequest = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you could add the logic to send the email notification
        alert(`Deletion request received for: ${email}`);
        setEmail('');
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Data Deletion Request
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    required
                    id="email"
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                    fullWidth
                />
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary"
                    fullWidth
                >
                    Submit Request
                </Button>
            </form>
        </Container>
    );
};

export default DataDeletionRequest;
