import React from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import TutorialSection from "../components/TutorialSection";

function TutorialPage(props) {
  return (
    <>
      <Meta title="Tutorial" />
      <TutorialSection 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Tutorial"
        subtitle="教程"
      />
    </>
  );
}

export default requireAuth(TutorialPage);
