// removed automatic scrolling since chatstripe is now appended to top of chat container
// hence no need for navigating to bottom of the chat container

// function isUserAtBottom(chatContainer) {
//   const threshold = 20; // A small threshold to account for potential rounding errors
//   const distanceFromBottom = chatContainer.scrollHeight - chatContainer.scrollTop - chatContainer.clientHeight;
//   return distanceFromBottom <= threshold;
// }

function typeText(
  el,
  chatContainer,
  text,
  typingInterval,
  setLoading,
  setTyping
) {
  let idx = 0;

  function typeNextCharacter() {
    if (idx < text.length) {
      el.innerHTML += text[idx++];
      // const shouldScroll = isUserAtBottom(chatContainer);

      // if (shouldScroll) {
      //   chatContainer.scrollIntoView({ behavior: "smooth" });
      //   chatContainer.scrollTop = chatContainer.scrollHeight;
      // }

      setTimeout(typeNextCharacter, 3);
    } else {
      setLoading(false);
      setTyping(false);
    }
  }

  typeNextCharacter();
}

export default typeText;
