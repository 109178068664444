import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>
        At {props.domain}, we prioritize and respect your personal privacy. This
        Privacy Policy explains what information we collect from you, how we
        use it, and your rights in relation to it. We comply with all
        applicable laws and regulations regarding your personal information.
      </p>
      <p>
        This policy was last updated on May 30th, 2023. We may change this
        policy from time to time. Please review this policy regularly to stay
        informed of any updates.
      </p>
      <h2>Information we collect</h2>
      <p>
        We collect personal information and non-personal information. Personal
        information is any information that can be used to identify you. 
        Non-personal information is any information that cannot be used to
        identify you.
      </p>
      <h3>Personal information</h3>
      <p>
        We collect personal information that you voluntarily provide to us.
        This can include information such as your name, email address, and
        other information that can be used to identify you.
      </p>
      <h3>Non-personal information</h3>
      <p>
        When you visit our website, our servers automatically log information
        provided by your browser. This can include your IP address, device
        type, browser type and version, the pages you visit, the time and date
        of your visit, and how much time you spend on each page.
      </p>
      <h2>Use of your information</h2>
      <p>
        We use your personal and non-personal information to provide, improve,
        and secure our services. We also use this information to support our
        internal operations, such as customer service, marketing, and to
        enforce our terms and conditions.
      </p>
      <h2>Your rights</h2>
      <p>
        You have certain rights in relation to the personal information we
        hold about you. These may include the right to access, correct, delete,
        or restrict the use of your personal information. You also have the
        right to complain to a privacy regulator about our collection and use
        of your personal information.
      </p>
      <h2>Contact us</h2>
      <p>
        If you have any questions about this Privacy Policy, your rights, or
        want to make a complaint, please contact us at <strong>support@ctg4u.com.</strong>
      </p>
    </LongContent>
  );
}

export default LegalPrivacy;
