import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import ContentAngleSection from "../../components/eng/ContentAngleSection";

function CAGeneratorPage(props) {
  return (
    <>
      <Meta title="Content Angle Generator (English)" />
      <ContentAngleSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Content Angle (英文)"
        subtitle="英文 销售角度生成"
      />
    </>
  );
}

export default requireProUser(CAGeneratorPage);
