import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function FeedbackSection(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const navigateToForm = () => {
    window.open('https://forms.gle/fNgaDWwMfG47Tdoh9', '_blank');
  };
  
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md" className={classes.container}>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          textAlign="center"
        />
            <Button variant="contained" color={props.buttonColor} size="large" type="submit" onClick={navigateToForm}>
              {/* Feedback Form */}
              {t("MPG009")}
            </Button>
      </Container>
    </Section>
  );
}

export default FeedbackSection;
