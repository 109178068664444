export const promptArray = {
    "cafe": "Interior of a cozy Malaysian cafe, traditional meets modern minimalist, cultural wall decorations, hyperrealistic 3D render style, Behance-inspired, medium shot, 35mm lens effect, bright natural light through windows, emphasis on texture and color",
    "office": "An immaculately organized professional office, stock photo aesthetic, featuring a sleek, modern desk neatly arranged with high-end office supplies, a state-of-the-art laptop, framed family photos, a lush potted plant providing a touch of green, bathed in soft, natural light filtering through large windows, creating a harmonious and productive workspace",
    "hospital": "Interior of a local Malaysian hospital, minimalistic and functional design, bright and evenly distributed lighting creating a calm and clean environment. Wide hallways with sleek, modern medical equipment neatly arranged, soft white walls with minimal decorative elements, subtle signage for easy navigation, waiting areas with comfortable seating and indoor plants for a touch of greenery, high ceiling with large windows allowing natural light to complement artificial lighting, photorealistic details",
    "trad-kitchen": "Traditional Malaysian kitchen, rustic and homely ambiance, rich wooden textures and warm earthy tones, open hearth or traditional wood-fired stove, hanging pots and pans made of brass or copper, shelves lined with spices and ceramic jars, woven rattan baskets, stone mortar and pestle, ambient lighting with flickers of firelight adding warmth, the aroma of spices and herbs in the air, intricately patterned tiles, family gathering, cooking traditional Malaysian dishes, a sense of history and cultural heritage",
    "modern-kitchen": "Sleek modern kitchen design, minimalistic style with clean lines, high-gloss cabinetry in monochromatic tones, state-of-the-art stainless steel appliances, a central island with a smooth quartz countertop, under-cabinet LED lighting providing a bright and clean atmosphere, open-plan layout integrating with a contemporary living space, large windows allowing natural light to flood in, smart kitchen technology features, hanging pendant lights over the island, bar stools for casual dining, a touch of greenery with indoor plants, photorealistic and high-quality rendering",
    "trad-livingroom": "Traditional Malaysian living room, a harmonious blend of cultural heritage and comfort, ethnic Malay, Chinese, and Indian influences reflected in decor, hand-carved wooden furniture with intricate designs, floor seating with colorful cushions, batik or songket fabric accents, low tables, woven rattan mats and baskets, ornamental wood panels and traditional Malay art, hanging lanterns providing soft ambient lighting, indoor plants like ferns and orchids adding a touch of nature, family heirlooms and cultural artifacts displayed, warm and welcoming atmosphere, vibrant yet earthy color palette",
    "modern-livingroom": "Sleek and contemporary Malaysian living room, a fusion of modern design with subtle traditional Malaysian influences, clean lines and open space concept, neutral color palette accented with vibrant pops of color, comfortable yet stylish furniture with a minimalist aesthetic, large windows allowing natural light to enhance the airy feel, contemporary art pieces alongside traditional Malaysian crafts, smart home technology integrated seamlessly, indoor plants like orchids for a tropical touch, soft and efficient LED lighting, an inviting and sophisticated space that reflects modern Malaysian lifestyle",
    "garage": "Empty modern car garage in Malaysia, clearly no cars inside, showcasing sleek, minimalist design, polished concrete floors, spacious interior designed for multiple cars but currently unoccupied, walls equipped with state-of-the-art tools and equipment, each tool in its designated place, bright LED lighting enhancing the clean, open space, large automated garage doors, automotive-themed decor but without any vehicles, a central workbench for car maintenance, advanced security system, well-ventilated and climate-controlled, focus on the architecture and design details of the garage",
    "pet-cafe": "Charming pet cafe in malaysia for dogs and cats",
    "clinic": "Modern and clean clinic interior, a professional and welcoming healthcare environment, bright and airy reception area with comfortable seating, calming neutral color palette with soft blue and green accents, reception desk with efficient and friendly staff, informative posters on health and wellness on the walls, spotless and well-organized examination rooms with advanced medical equipment, privacy curtains, hand sanitizer stations, natural light complemented with soft, artificial lighting, a sense of calm and care in the atmosphere, high-quality and photorealistic rendering",
    "restaurant": "Elegant and luxurious restaurant interior, opulent decor with a sophisticated ambiance, plush seating and fine linen tablecloths, dimmed, ambient lighting creating a romantic and exclusive atmosphere, walls adorned with tasteful art and ornate decorations, a grand chandelier as the centerpiece, tables set with fine china, crystal glassware, and polished silverware, gourmet dishes artistically presented, an open kitchen where chefs can be seen preparing exquisite meals, live soft music in the background, attentive and impeccably dressed waitstaff, panoramic windows offering breathtaking city views, a fusion of classic elegance and modern luxury, high-quality and photorealistic rendering"
  };