import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import SimplifierItems from "./SimplifierItems";
import { Link, useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import '../../style/container.css'
import generateUniqueId from "../../util/generateuniqueid";
import typeText from "../../util/typetext";
import loader from "../../util/loader";
import chatStripe from "../../util/chatStripe";
import { Divider } from "@material-ui/core";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "695px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function SimplifierSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [industry, setIndustry] = useState("product");
  const [copywriting, setCopywriting] = useState("");
  const [tone, setTone] = useState("");
  const [cwStyle, setCwStyle] = useState("general");
  const [negativePrompt, setNegativePrompt] = useState("");

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleCopywritingChange = (event) => {
    setCopywriting(event.target.value);
  };
  const handleCwStyleChange = (event) => {
    setCwStyle(event.target.value);
  };
  const handleNegativePromptChange = (event) => {
    setNegativePrompt(event.target.value);
  };
      

  async function handleSubmit( id, e, lastInput = false, speechRes = false, credit = 0.2) {
    e && e.preventDefault();

    let query 

    if (cwStyle === "general") {
      query = `Data: Below is the copy that I want to rewrite./n ${copywriting}/n The purpose is to use this copy to advertise towards general public of Malaysian on Facebook.`
    } else if (cwStyle === "年轻人") {
      query = `Data: Below is the copy that I want to rewrite./n ${copywriting}/n The purpose is to use this copy to advertise towards youngster public of Malaysian on Facebook.`
    } else if (cwStyle === "中年人") {
      query = `Data: Below is the copy that I want to rewrite./n ${copywriting}/n The purpose is to use this copy to advertise towards middle-aged public of Malaysian on Facebook.`
    } else if (cwStyle === "老年人") {
      query = `Data: Below is the copy that I want to rewrite./n ${copywriting}/n The purpose is to use this copy to advertise towards elderly public of Malaysian on Facebook.`
    }

    // original with negative prompt
    // if (cwStyle === "general") {
    //   query = `资料： 我要重写的文案是${copywriting}，目的是要在脸书打广告给马来西亚华人。避免用<${negativePrompt}这些字眼。>`
    // } else if (cwStyle === "年轻人") {
    //   query = `资料： 我要重写的文案是${copywriting}，目的是要在脸书打广告给马来西亚华人，主要族群是${cwStyle}。避免用<${negativePrompt}这些字眼。`
    // } else if (cwStyle === "中年人") {
    //   query = `资料： 我要重写的文案是${copywriting}，目的是要在脸书打广告给马来西亚华人，主要族群是${cwStyle}。避免用<${negativePrompt}这些字眼。`
    // } else if (cwStyle === "老年人") {
    //   query = `资料： 我要重写的文案是${copywriting}，目的是要在脸书打广告给马来西亚华人，主要族群是老年人的看护者。避免用<${negativePrompt}这些字眼。`
    // }

    let payload = { industry, selfDefinedValue, query, id, credit }

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <SimplifierItems 
              handleSubmit={handleSubmit} 
              copywriting={copywriting}
              onCopywritingChange={handleCopywritingChange}
              onCwStyleChange={handleCwStyleChange}
              onNegativePromptChange={handleNegativePromptChange}
              cwStyle={cwStyle}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </Section>
  );
}

export default SimplifierSection;