import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfQuanWeiSection from "../../components/malay/CfQuanWeiSection";

function CfQuanWeiPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" />
      <CfQuanWeiSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="权威认证"
      />
    </>
  );
}

export default requireProUser(CfQuanWeiPage);
