import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import ServiceSection from "../components/ServiceSection";
import FeaturesSection3 from "../components/FeaturesSection3";
import FeaturesSection from "./../components/FeaturesSection";

import NewsletterSection from "./../components/NewsletterSection";
import BannerImage from '../assets/banner.jpg'
import FaqSection from "../components/FaqSection";
import FeedbackSection from "../components/FeedbackSection";
import PricingSection from "../components/HomePagePricingSection";
import { useTranslation } from "react-i18next";

function IndexPage(props) {
  const { t, i18n } = useTranslation();
  
  return (
    <>
      <Meta title="CTG-AI"/>
      <HeroSection2 />
      {/* <FeaturesSection2 /> */}
      <ServiceSection />
      {/* <FeaturesSection3 
        title="Features"
        subtitle="Feel the magical wonders of technology"
      /> */}
      <PricingSection
        // title="Pricing"
        title={t("MPG005")}
        // subtitle="Our pricing"
        subtitle={t("MPG006")}
        buttonColor="primary"
      />
      <FeedbackSection 
        // title="Feedback"
        title={t("MPG007")}
        // subtitle="Submit your suggestions" 
        subtitle={t("MPG008")}
        buttonColor="primary"
      />
      {/* <FaqSection 
        title="FAQ"
        subtitle="Your questions, our answers" 
      /> */}

      {/* <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Schedule A Demo"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
    </>
  );
}

export default IndexPage;
