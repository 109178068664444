import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../util/auth';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // minHeight: '100vh', // Adjust this value to control the height of the hero section
    padding: theme.spacing(10),
    // border: "1px solid red"
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    lineHeight: "80px",
    // letterSpacing: "-2px",
    [theme.breakpoints.down('sm')]: {
      lineHeight: "40px",
      fontSize: "2.5rem",
    },
  },
  subtitle: {
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
    },
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 6), // Add padding to the button, top/bottom padding: 2 * 8px, left/right padding: 6 * 8px
    fontSize: '1.25rem', // Increase the font size
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark, // Change the hover background color
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 4),
      fontSize: '1rem',
    },
  },
  featuresContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  feature: {
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

function HeroSection() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    if (!auth.user) {
      history.push('/auth/signup');
    } else {
      history.push('/');
    }
  }

  return (
    <Container maxWidth="lg" style={{ textAlign: "center" }} className={classes.heroContainer}>
      <Typography variant="h1" component="h1" className={classes.title}>
        {/* Marketing <br /> */}
        {/* {isSmallScreen ? 'With AI' : 'With\nAI'} */}
        {t("MPG001")}
      </Typography>
      <Typography variant="h5" component="h2" className={classes.subtitle}>
        {/* Create optimized content for your ads, <br /> */}
        {/* business & more 10x faster. */}
        {t("MPG002")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        onClick={handleClick}
      >
        {/* Sign Up For Free */}
        {t("MPG003")}
      </Button>
      <Box className={classes.featuresContainer}>
        <Typography variant="body1" component="span" className={classes.feature}>
          {/* amazing content */}
          {t("MPG004")}
        </Typography>
        {/* <Typography variant="body1" component="span" className={classes.feature}>
          cancel anytime
        </Typography> */}
      </Box>
    </Container>
  );
}

export default HeroSection;