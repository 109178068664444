import { useEffect, useState } from 'react';
import { useAuth } from '../util/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../util/firebase';
import Button from "@material-ui/core/Button";

function CreditsNavbar() {
    const [credits, setCredits] = useState(null);
    const auth = useAuth();
    const userId = auth.user?.uid; 
    const db = getFirestore(firebaseApp);
    // const handleClick = () => {
    //     console.log(auth.user)
    // }
  useEffect(() => {
    const userRef = doc(db, 'users', userId);

    // Listen for changes in the user's credits
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credits);
      } else {
        console.log('No such user!');
      }
    });

    // Clean up the listener when the component is unmounted
    return () => {
      unsubscribe();
    };
  }, [userId, db]);

  return (
    <>
        {/* <button onClick={handleClick}>test data</button> */}
        {/* navigate to topup page for more credits */}
        <Button
            color="inherit"
        >
            Credit : RM{credits}
        </Button>
    </>
  );
}

export default CreditsNavbar;