import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import Cf2Section from "../../components/eng/Cf2Section";

function EngCf2Page(props) {
  return (
    <>
      <Meta title="Confidence Reply Generator" />
      <Cf2Section
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply"
        subtitle="信心回复生成"
      />
    </>
  );
}

export default requireProUser(EngCf2Page);
