import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "820px",
  },
  featured: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  colorPicker: {
    width: 200,
    height: 100,
  },
}));

function RembgItems(props) {
  const {
    handleSubmit,
    onImageUploadChange,
    setPreviewImage,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const handleRemoveImage = () => {
    setPreviewImage(null);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
          <Typography variant="h5">Generator</Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} alignItems="center">
          {!props.previewImage && (
            <Box mt={2} display="flex" justifyContent="center">
              <InsertPhotoIcon style={{ fontSize: 200, color: "gray" }} />
            </Box>
          )}
          {!props.previewImage && (
            <Button
              variant="contained"
              component="label"
              htmlFor="raised-button-file"
              style={{ width: "70%" }}
            >
              Upload Image
              <input
                accept="image/png"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={onImageUploadChange}
              />
            </Button>
          )}

          {props.previewImage && (
            <Box mt={2} display="flex" justifyContent="center">
              <img
                src={props.previewImage}
                alt="Preview"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </Box>
          )}

          {props.previewImage && (
            <Box mt={2} display="flex" justifyContent="space-around" minWidth="300px">
              <Button variant="contained" component="label" htmlFor="raised-button-file">
                {/* Change Image */}
                {t("CPW037")}
                <input
                  accept="image/png"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={onImageUploadChange}
                />
              </Button>
              <Button variant="contained" onClick={handleRemoveImage}>
                {/* Remove Image */}
                {t("CPW038")}
              </Button>
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(1, e)}
          >
            {/* 移除背景 */}
            {t("CPW103")}
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default RembgItems;
