import React from "react";
import Meta from "../components/Meta";
import SavedReplySection from "../components/SavedReplySection";
import { requireAuth } from "../util/auth";

function SavedReplyPage(props) {
  return (
    <>
      <Meta title="Saved Reply" />
      <SavedReplySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Saved Reply"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(SavedReplyPage);