import React from "react";
import Meta from "../components/Meta";
import TrackingSection from "../components/TrackingSection";
import { requireAdmin } from "../util/auth";

function TrackingPage(props) {
  return (
    <>
      <Meta title="Tracking" />
      <TrackingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Description"
        subtitle=""
      />
    </>
  );
}

export default requireAdmin(TrackingPage);
