import React from "react";
import Meta from "../../components/Meta";
import { requireEliteUser } from "../../util/auth";
import PepcEndResSection from "../../components/drama/PepcEndResSection";

function PepcEndResPage(props) {
  return (
    <>
      <Meta title="剧本 PEPC" />
      <PepcEndResSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="剧本 PEPC"
        subtitle="End Result"
      />
    </>
  );
}

export default requireEliteUser(PepcEndResPage);
