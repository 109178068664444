import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ImageContentSection from "../components/ImageContentSection";

// function UspPage(props) {
function ImageContentPage(props) {
  return (
    <>
      <Meta title="Image Content Generator" />
      <ImageContentSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="图片文案生成"
        subtitle="Image Content Generator"
      />
    </>
  );
}

export default requireProUser(ImageContentPage);