import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfTheorySection from "../../components/eng/CfTheorySection";

function CfTheoryPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (English)" />
      <CfTheorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (English)"
        subtitle="原理解释"
      />
    </>
  );
}

export default requireProUser(CfTheoryPage);
