import React from "react";
import Meta from "../components/Meta";
import AnnouncementSection from "../components/AnnouncementSection";
import { useRouter } from "../util/router";

function AnnouncementPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Announcement" />
      <AnnouncementSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        // section={router.query.section}
        // key={router.query.section}
        title="Latest Updates"
        subtitle="更新公告"
      />
    </>
  );
}

export default AnnouncementPage;
