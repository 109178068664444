import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChatStripe from "../ChatStripe2";
import generateUniqueId from "../../util/generateuniqueid";

const useStyles = makeStyles((theme) => ({
  ai: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

export async function fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval) {
  let uniqueId;
  uniqueId = generateUniqueId();

  setMessages(oldMessages => [
    {
      isUser: false,
      value: '',
      error: false,
      uniqueId: uniqueId,
      loading: true
    },
    ...oldMessages
  ]);

  // fetching data from server 
  // fetch("http://localhost:5001/v2", {
  fetch("https://ctg-api.onrender.com/v2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      prompt: payload,
      name: auth.user.displayName,
      uid: auth.user.uid,
      email: auth.user.email,
    }),
  })
  .then(async (response) => {
    if (response.ok) {
      setError(false);
      const data = await response.json();
      const parsedData = data.bot.trim();
      
      if (parsedData === "") {
        setMessages(oldMessages => [{
          isUser: false,
          value: "No results Found 😞\n<small>Change model</small>",
          error: false,
          uniqueId: uniqueId
        }, ...oldMessages]);
      } else {
        setTyping(true);
        setTimeout(() => {
          setMessages(oldMessages =>
            oldMessages.map(message => 
              message.uniqueId === uniqueId
                ? {
                    ...message,
                    value: parsedData,
                    loading: false
                  }
                : message
            )
          )
          setTyping(false);
        }, typingInterval);
      }
    } else {
      setError(true);
      setMessages(oldMessages =>
        oldMessages.map(message => 
          message.uniqueId === uniqueId
            ? {
                ...message,
                value: "An error occurred. \n<small>Hint: Try to <b>reduce your word count</b> or check your internet connection and then <b>try again</b><small>",
                error: true,
                loading: false
              }
            : message
        )
      );
    }
  })
  .catch((err) => {
    setError(true);
    setLoading(false);
    setMessages(oldMessages =>
      oldMessages.map(message => 
        message.uniqueId === uniqueId
          ? {
              ...message,
              value: "Sorry, server is down right now, try again later",
              error: true,
              loading: false
            }
          : message
      )
    );
    console.log(err)
    });
}

export function OutputContainer({ typing, error, messages, id }) {
  const classes = useStyles({ typing, error });

  return (
    <div id={id}>
      {messages.map((message, index) => (
        <ChatStripe
          key={message.uniqueId || index}
          isAi={!message.isUser}
          value={message.value}
          uniqueId={message.uniqueId}
          showIcon={true} 
          loading={message.loading}
        />
      ))}

      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}