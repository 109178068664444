import React, { useState, useEffect } from "react";
import {Box, Divider, Typography, Container} from "@material-ui/core";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "../util/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { firebaseApp } from "../util/firebase";
  
const PaymentResultSection = (props) => {
    const auth = useAuth();
    const db = getFirestore(firebaseApp);
    const [loading, setLoading] = useState(true);
    const [transactionResult, setTransactionResult] = useState("");
    let user_id = sessionStorage.getItem("user_id");
    let detail = sessionStorage.getItem("detail");
    let amount = sessionStorage.getItem("amount");
    // // additional params
    // let order_id = sessionStorage.getItem("order_id");
    // let status_id, msg;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        
        // URL params from SenangPay
        const status_id = urlParams.get('status_id');
        const order_id = urlParams.get('order_id');
        const msg = urlParams.get('msg');
        const transaction_id = urlParams.get('transaction_id');
        const hash = urlParams.get('hash');

        insertPaymentTransaction(status_id, order_id, msg, transaction_id, hash, detail, amount);
        updateReferee(order_id, transaction_id, status_id);

        if(status_id == 1){
            if (detail == "CTG_AI_Elite_Plan"){
                const userDocRef = doc(db, 'users', user_id);
                setDoc(userDocRef, {
                    role: "Elite",
                    credits: +1000
                }, { merge: true });
            } else if (detail == "CTG_AI_Pro_Plan"){
                const userDocRef = doc(db, 'users', user_id);
                setDoc(userDocRef, {
                    role: "Pro",
                    credits: +1000
                }, { merge: true });
            } else if (detail == "CTG_AI_Credits_Reload"){
                const userDocRef = doc(db, 'users', user_id);
                setDoc(userDocRef, {
                    credits: +100
                }, { merge: true });
            }
        }

        console.log(msg);
        setTransactionResult(`https://app.senangpay.my/payment/receipt/${transaction_id}`);
    }, []);

    // Write into sql database (payment_transaction)
    async function insertPaymentTransaction(status_id, order_id, msg, transaction_id, hash, detail, amount) {
        // return fetch("http://localhost:5001/sql_insert_payment_transaction", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_insert_payment_transaction", {
        return fetch("https://ctg-api.onrender.com/sql_insert_payment_transaction", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            status_id,
            order_id,
            msg,
            transaction_id,
            hash,
            detail,
            amount
        })
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = data;
                return parsedData;
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    // Update into sql database (referee)
    async function updateReferee(order_id, transaction_id, status_id) {
        // return fetch("http://localhost:5001/sql_update_referee", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_update_referee", {
        return fetch("https://ctg-api.onrender.com/sql_update_referee", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            order_id,
            transaction_id,
            status_id
        })
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = data; // updated index in referee
                return parsedData;
            }
        }).catch((err) => {
            console.error(err);
        })
    }


    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <Box textAlign="center">
                    <SectionHeader
                        title={props.title}
                        size={4}
                    />
                    {transactionResult && (
                        // SenangPay Receipt
                        <p>
                            Transaction result: <a href={transactionResult} target="_blank" rel="noopener noreferrer">{transactionResult}</a>
                            <iframe title="Transaction Result" src={transactionResult} width="100%" height="500px"></iframe>
                        </p>
                    )}
                </Box>
            </Container>
        </Section>
    );
}

export default PaymentResultSection;