import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfJianZhengSection from "../../components/malay/CfJianZhengSection";

function CfJianZhengPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" />
      <CfJianZhengSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="客户见证"
      />
    </>
  );
}

export default requireProUser(CfJianZhengPage);
