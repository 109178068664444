import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "850px",
  },
  featured: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function PepcEndResItems(props) {
  const { handleSubmit } = props;
  const {
    onProductNameChange,
    onProductUspChange,
    onProductEndResultChange,
    onTimePeriod1Change,
    onTimePeriod2Change,
    onPainPoint1Change,
    onPainPoint2Change,
    timePeriod1,
  } = props;

  const classes = useStyles();

  const auth = useAuth();

  const { data: items, status: itemsStatus, error: itemsError } = useItemsByOwner(auth.user.uid);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive && (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, {
        featured: !item.featured,
      });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
          <Typography variant="h5">Generator</Typography>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 1 (required)</Typography>
          <FormControl>
            <InputLabel id="select-label" margin="dense" disableAnimation={true}>
              背景
            </InputLabel>
            <Select
              labelId="select-label"
              id="select"
              label="timePeriod1"
              value={timePeriod1}
              onChange={onTimePeriod1Change}
              required
            >
              <MenuItem value={"个人背景"}>个人背景</MenuItem>
              <MenuItem value={"企业背景"}>企业背景</MenuItem>
            </Select>
          </FormControl>
        </Box>
        
        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 1 (required)</Typography>
          <TextField
            onChange={onProductNameChange}
            // onChange={(e) => setProductName(e.target.value)}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            placeholder={timePeriod1 === "个人背景" ? "输入名字" : "输入公司名字" }
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 2 (required)</Typography>
          <TextField
            onChange={onProductUspChange}
            value={props.productUsp}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            placeholder={timePeriod1 === "个人背景" ? "输入专业背景" : "输入企业背景" }
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 4 (required)</Typography>
          <TextField
            onChange={onTimePeriod2Change}
            value={props.timePeriod2}
            margin="dense"
            name="timePeriod2"
            fullWidth
            variant="outlined"
            placeholder="输入在什么方面有多久的经验"
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 5 (optional)</Typography>
          <TextField
            onChange={onPainPoint1Change}
            value={props.painPoint1}
            margin="dense"
            name="painPoint1"
            fullWidth
            variant="outlined"
            placeholder={"输入得过的认证/奖项"}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 6 (required)</Typography>
          <TextField
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            placeholder={"输入影片要解决的问题"}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(3, e)}
          >
            PEPC - E
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default PepcEndResItems;
