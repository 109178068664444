import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card, CardMedia, IconButton, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summary: {
    minWidth: '704px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  player: {
    width: '672px',
    height: '380px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  card: {
    width: '672px',
    height: '380px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  iconButton: {
    position: 'absolute',
  },
  iconOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    borderRadius: '20px',
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
    },
  },
}));

function VideoAccordion({ videos }) {
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const classes = useStyles();

  const handleOpen = (videoId) => {
    setPlayingVideoId(videoId);
  };

  return (
    videos.map((video) => (
      <Accordion key={video.id}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{video.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {playingVideoId === video.id ? (
            <ReactPlayer className={classes.player} url={`https://www.youtube.com/watch?v=${video.id}`} playing width={"100%"} />
          ) : (
            <Card className={classes.card}>
              <CardMedia
                component="img"
                alt={video.title}
                image={`https://img.youtube.com/vi/${video.id}/maxresdefault.jpg`}
              />

              <IconButton className={classes.iconButton} onClick={() => handleOpen(video.id)}>
              <div className={classes.iconOverlay}>
                <PlayArrowIcon fontSize="large" />
              </div>
              </IconButton>
            </Card>
          )}
        </AccordionDetails>
      </Accordion>
    ))
  );
}

export default VideoAccordion;
