import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import DescActItems from "./DescActItems";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext";
import "../../style/container.css";
import { Divider } from "@material-ui/core";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  copy: {
    display: ({ typing, load, error }) => (typing || load || error ? "none" : "block"),
    cursor: "pointer",
    "& img": { width: "22px" },
  },
}));

function DescActSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [productIngredient, setProductIngredient] = useState("");
  const [productCert, setProductCert] = useState("");

  const [industry, setIndustry] = useState("product");
  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductIngredientChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductIngredient(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductCertChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductCert(event.target && event.target.value ? event.target.value : event);
    }
  };

  async function handleSubmit(id, e, lastInput = false, speechRes = false, credit = 0.4) {
    e && e.preventDefault();
    e && e.stopPropagation();

    let query;

    if (industry === "product") {
      // query = `Product name and type is ${productName}, unique selling point is ${productUSP}，`;
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n`;

      if (productIngredient.length > 2) {
        query = query + `The unique ingredient or technology is: ${productIngredient} \n`;
      }

      if (productCert.length > 2) {
        query = query + `Has certification of: ${productCert} \n`;
      }
    } else {
      // query = `Product name and type is ${productName}, unique selling point is ${productUSP}，`;
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n`;

      if (productIngredient.length > 2) {
        query = query + `The unique ingredient or technology is: ${productIngredient} \n`;
      }

      if (productCert.length > 2) {
        query = query + `Confidence boosting recognitions are: ${productCert} \n`;
      }
    }

    if (id === "da-1-cn" || id === "da-1-en" || id === "da-1-malay") {
      if (industry === "product") {
        id += "-product";
      } else if (industry === "service") {
        id += "-service";
      }
    } else if (id === "da-2-cn" || id === "da-2-en" || id === "da-2-malay") {
      credit = 0.1;
    }

    let payload = { industry, selfDefinedValue, id, query, credit };

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />

        <Grid container={true} spacing={4}>
          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <DescActItems
              handleSubmit={handleSubmit}
              industry={industry}
              productName={productName}
              productUSP={productUSP}
              productIngredient={productIngredient}
              productCert={productCert}
              onIndustryChange={handleIndustryChange}
              onProductNameChange={handleProductNameChange}
              onProductUSPChange={handleProductUSPChange}
              onProductIngredientChange={handleProductIngredientChange}
              onProductCertChange={handleProductCertChange}
              legacyMode={legacyMode} 
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Grid>

          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>
                    <OutputContainer 
                      typing={typing}
                      load={loading}
                      error={error}
                      messages={messages}
                      id="chat_container"
                    />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </Section>
  );
}

export default DescActSection;