import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../util/db";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "820px",
  },
  featured: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  colorPicker: {
    width: 200,
    height: 100,
  },
}));

function ReplaceItems(props) {
  const {
    handleSubmit,
    onImageUploadChange,
    setPreviewImage,
    onColorChange,
    onThemeChange,
    theme,
    onDescriptionChange,
    description,
    onPositionChange,
    position,
    colorCode,
    showColorPicker,
    onColorPickerChange
  } = props;
  // const previewImage = props.previewImage
  const [openDialog, setOpenDialog] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleInputChange = (event) => {
    const newColor = event.target.value;
    // if (/^#([0-9A-F]{3}){1,2}$/i.test(newColor)) {
    onColorChange(newColor);
    // }
  };
  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const handleRemoveImage = () => {
    setPreviewImage(null);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
          <Typography variant="h5">Generator</Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} alignItems="center">
          {!props.previewImage && (
            <Box mt={2} display="flex" justifyContent="center">
              <InsertPhotoIcon style={{ fontSize: 200, color: "gray" }} />
            </Box>
          )}
          {!props.previewImage && (
            <Button
              variant="contained"
              component="label"
              htmlFor="raised-button-file"
              style={{ width: "70%" }}
            >
              Upload Image
              <input
                accept="image/png"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={onImageUploadChange}
              />
            </Button>
          )}

          {props.previewImage && (
            <Box mt={2} display="flex" justifyContent="center">
              <img
                src={props.previewImage}
                alt="Preview"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </Box>
          )}

          {props.previewImage && (
            <Box mt={2} display="flex" justifyContent="space-around" minWidth="300px">
              <Button variant="contained" component="label" htmlFor="raised-button-file">
                {/* Change Image */}
                {t("CPW037")}
                <input
                  accept="image/png"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={onImageUploadChange}
                />
              </Button>
              <Button variant="contained" onClick={handleRemoveImage}>
                {/* Remove Image */}
                {t("CPW038")}
              </Button>
            </Box>
          )}
        </Box>


        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box display="flex" flexDirection="column" py={2} px={3} minWidth="200px" width="60%">
            <Typography component="span">
              Step 2: {t("CPW039")}
            </Typography>
            <FormControl>
              <InputLabel id="select-label" margin="dense" disableAnimation={true}>
                {/* 选择主题 */}
                {t("CPW080")}
              </InputLabel>
              <Select
                labelId="select-label"
                id="theme"
                label="tone"
                value={theme}
                onChange={onThemeChange}
              >
                <MenuItem value={"self"}>自定义 Self-Defined</MenuItem>
                <MenuItem value={"Studio"}>摄影棚 Studio</MenuItem>
                <MenuItem value={"Silk"}>丝绸 Silk</MenuItem>
                <MenuItem value={"Cafe"}>咖啡厅 Cafe</MenuItem>
                <MenuItem value={"Tabletop"}>桌面上 Tabletop</MenuItem>
                <MenuItem value={"Bathroom"}>洗手间 Bathroom</MenuItem>
                {/* <MenuItem value={"Outdoors"}>户外 Outdoors</MenuItem> */}
                <MenuItem value={"Furniture"}>家具 Furniture</MenuItem>
                <MenuItem value={"Beach"}>海滩 Beach</MenuItem>
                <MenuItem value={"Nature"}>大自然 Nature</MenuItem>
                <MenuItem value={"Gifts"}>礼物 Gifts</MenuItem>
              </Select>
            </FormControl>


            {theme === "self" && (
              <Box display="flex" flexDirection="column" pt={1} px={1}>
                <TextField
                  id="description"
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={t("CPW081")}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box display="flex" flexDirection="column" py={2} px={3} minWidth="200px" width="60%">
            <Typography component="span">Step 3</Typography>
            <FormControl>
              <InputLabel id="position-label" margin="dense" disableAnimation={true}>
                {/* 产品位置 */}
                {t("CPW040")}
              </InputLabel>
              <Select
                labelId="position-label"
                id="position"
                label="tone"
                value={position}
                onChange={onPositionChange}
              >
                <MenuItem value={"Left"}>{t("CPW041")}</MenuItem>
                <MenuItem value={"Center"}>{t("CPW042")}</MenuItem>
                <MenuItem value={"Right"}>{t("CPW043")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box display="flex" flexDirection="column" py={2} px={3} width="60%">
            <Typography component="span">
              Step 4: {t("CPW044")}
            </Typography>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showColorPicker}
                    onChange={() => onColorPickerChange()}
                  />
                }
                // label="自定义色系"
                label={t("CPW044")}
              />
            </Box>
            {showColorPicker && (
              <>
                <Box display="flex" mt={2} justifyContent="space-around">
                  <Box
                    borderRadius={"50px"}
                    width={"3rem"}
                    height={"3rem"}
                    bgcolor={colorCode !== "" ? colorCode : "#000000"}
                    onClick={() => setOpenDialog(true)}
                  />

                  <TextField
                    // label="色系"
                    label={t("CPW045")}
                    variant="standard"
                    value={colorCode !== "" ? colorCode : "#000000"}
                    onChange={handleInputChange}
                  />
                </Box>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                  <DialogContent style={{ padding: "50px" }}>
                    <HexColorPicker
                      color={colorCode}
                      onChange={onColorChange}
                      className={classes.colorPicker}
                    />
                  </DialogContent>
                </Dialog>
              </>
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(1, e)}
          >
            {/* 生成产品拍摄图 */}
            {t("CPW046")}
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default ReplaceItems;
