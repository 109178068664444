import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../util/db";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "1050px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function ConfidenceItems(props) {
  const { handleSubmit } = props
  const { 
    onProductNameChange, 
    onProductElementsChange,
    onProductEndResultChange,
    onProductConfidence1Change,
    onProductConfidence2Change,
    onReassuranceChange,
    } = props;

  
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);


  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 1 (required)</Typography>
          <TextField 
            onChange={onProductNameChange}
            // onChange={(e) => setProductName(e.target.value)}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            placeholder="输入产品名字和种类"
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 4 (required)</Typography>
          <TextField 
            onChange={onProductElementsChange}
            value={props.productElements}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            placeholder="输入特殊成分/技术"
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 2 (required)</Typography>
          <TextField 
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            placeholder="输入End Result"
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 3 (optional)</Typography>
          <TextField 
            onChange={onProductConfidence1Change}
            value={props.productConfidence1}
            margin="dense"
            name="productConfidence1"
            fullWidth
            variant="outlined"
            placeholder="输入信心事项1"
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 4 (required)</Typography>
          <TextField 
            onChange={onProductConfidence2Change}
            value={props.productConfidence2}
            margin="dense"
            name="productConfidence2"
            fullWidth
            variant="outlined"
            placeholder="输入信心事项2"
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 5 (required)</Typography>
          <TextField 
            onChange={onReassuranceChange}
            value={props.reassurance}
            margin="dense"
            name="reassurance"
            fullWidth
            variant="outlined"
            placeholder="输入Reassurance"
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(1, e)}
          >
            成分/技术
          </Button>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(2, e)}
          >
            独特卖点
          </Button>

        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(3, e)}
          >
            END RES
          </Button>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(4, e)}
          >
            原理解释
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default ConfidenceItems;
