import React from "react";
import Meta from "../components/Meta";
import ScraperHistorySection from "../components/scraper/ScraperHistorySection";
import { requireAuth } from "../util/auth";

function ScraperHistoryPage(props) {
  return (
    <>
      <Meta title="User History" />
      <ScraperHistorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="广告分析"
        subtitle="History"
      />
    </>
  );
}

export default requireAuth(ScraperHistoryPage);
