import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import UspSection from "../components/UspSection";

function UspPage(props) {
  return (
    <>
      <Meta title="USP Generator" />
      <UspSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="独特卖点"
        subtitle="USP"
      />
    </>
  );
}

export default requireProUser(UspPage);
