import React from "react";
import Meta from "../components/Meta";
import HistorySection from "../components/HistorySection";
import { requireAuth } from "../util/auth";

function HistoryPage(props) {
  return (
    <>
      <Meta title="User History" />
      <HistorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="History"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(HistoryPage);
