import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import botIcon from '../assets/ctg.ico';
import Loader from './Loader';
import CopyButton from './CopyButton';

function ChatStripe({ isAi, value, uniqueId, showIcon = true, loading = false, noCopy = false}) {
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    if (loading) return;

    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < value.length) {
        setTypedValue((typedValue) => typedValue + value[i]);
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 5); // speed of typing can be adjusted here

    return () => {
      clearInterval(typingInterval);
    };
  }, [loading, value]);

  // Render different types of values
  const renderValue = () => {
    if (typeof value === "string") {
      return <div className="message" id={uniqueId}>{typedValue}</div>;
    } else if (React.isValidElement(value)) {
      return <div className="message" id={uniqueId}>{value}</div>;
    } else {
      return null;
    }
  };

  return (
    <Box className={`wrapper ${isAi ? 'ai' : ''}`}>
      <div className="chat">
        {showIcon && (
          <div className="profile">
            <img src={botIcon} alt={isAi ? "bot-icon" : "user-icon"} />
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          renderValue()
        )}
        {loading ? (
          <></>
        ) : (
          noCopy ? <></> : <CopyButton content={value}/>
        )

        }
      </div>
    </Box>
  );
}

export default ChatStripe;