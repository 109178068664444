import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfZhuanYeSection from "../../components/confidence/CfZhuanYeSection";

function CfZhuanYePage(props) {
  return (
    <>
      <Meta title="Confidence Reply" />
      <CfZhuanYeSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply"
        subtitle="专业背景"
      />
    </>
  );
}

export default requireProUser(CfZhuanYePage);
