import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import SimplifierSection from "../../components/eng/SimplifierSection";

function SimplifierPage(props) {
  return (
    <>
      <Meta title="Copywriting Simplifier (English)" />
      <SimplifierSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="文案转白话 (英文)"
        subtitle="英文 Simplifier"
      />
    </>
  );
}

export default requireProUser(SimplifierPage);
