import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collectionGroup,
  query,
  orderBy,
  onSnapshot,
  limit,
  startAfter,
  endBefore,
  where,
  getCountFromServer,
  limitToLast,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import Box from "@material-ui/core/Box";
import {
  Paper,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  Card,
  Grid,
  Divider,
  Avatar,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { Link } from "../../util/router";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import Modal from "@material-ui/core/Modal";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "@orrisroot/react-html-parser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTheme } from "@material-ui/core/styles";
import AdLibraryFilter from "./AdLibraryFilter";
// import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
  },
  button: {
    color: "white",
    backgroundColor: "#3f51b5",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
    margin: "10px",
  },
  disabledButton: {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    margin: "10px",
  },
  typography: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "flex-start",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
    height: "80vh",
    overflow: "auto",
  },
  modalAdContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalContainerButtons: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  modalAdContent: {
    width: "80%",
  },
  squareCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: "95%",
    // height: "40rem",
    maxHeight: "95%",
    overflow: "auto",
  },
  cardBox: {
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  cardActionArea: {
    display: "block",
    textAlign: "inherit",
    width: "100%",
  },
}));

const ScraperHistorySection = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();

  const [usageData, setUsageData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const mountedRef = useRef(true);
  const userEmail = auth.user.email;
  const [isTextTruncated, setIsTextTruncated] = useState(true);
  const [expandedDiv, setExpandedDiv] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterResults, setFilterResults] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Slice the usageData array to get only the items for the current page
  const currentItems = usageData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(usageData.length / itemsPerPage);

  const toggleTruncateText = (index) => {
    setExpandedDiv(expandedDiv === index ? null : index);
  };

  const handleFilterChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterValue(selectedValue);
    setCurrentPage(1);
  };

  const handleFilterResultsChange = (selectedValue) => {
    // console.log(selectedValue)
    setFilterResults(selectedValue);
    // setCurrentPage(1);
  };

  const handleCardClick = (index) => {
    setSelectedCards((prevSelectedCards) => {
      if (prevSelectedCards.includes(index)) {
        // If the card is already selected, remove it from the selection
        return prevSelectedCards.filter((cardIndex) => cardIndex !== index);
      } else {
        // If the card is not selected, add it to the selection
        return [...prevSelectedCards, index];
      }
    });
  };

  const handleClearSelection = () => {
    setSelectedCards([]);
  };

  // Fetch the document
  const fetchData = () => {
    const db = getFirestore(firebaseApp);
    // console.log("selectedValue:", selectedValue)
    // console.log("filterValue:", filterValue)
    if (filterValue !== null) {
      const userDocPath = `users/${auth.user.uid}/adData/${filterValue}`;

      const docRef = doc(db, userDocPath);

      // Listen for real-time updates
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Access the items array
          const items = data.items;
          // Now you can use the items array
          // console.log(items)
          setUsageData(items);
        } else {
          console.log("No such document!");
        }
      });

      // Return the unsubscribe function to clean up the listener
      return unsubscribe;
    }
  };

  const saveMedia = async () => {
    const storage = getStorage(firebaseApp);

    selectedCards.forEach(async (index) => {
      const data = usageData[index];
      const promises = [];

      if (data.images) {
        for (let i = 0; i < data.images.length; i++) {
          const image = data.images[i];
          promises.push(
            (async () => {
              const imageRef = ref(storage, image);
              const url = await getDownloadURL(imageRef);
              const response = await fetch(url);
              const blob = await response.blob();
              saveAs(blob, `image_${data.adArchiveID}_${i}.png`);
            })()
          );
        }
      }

      if (data.videos) {
        for (let i = 0; i < data.videos.length; i++) {
          const video = data.videos[i];
          promises.push(
            (async () => {
              const videoRef = ref(storage, video);
              const url = await getDownloadURL(videoRef);
              const response = await fetch(url);
              const blob = await response.blob();
              saveAs(blob, `video_${data.adArchiveID}_${i}.mp4`);
            })()
          );
        }
      }

      // Wait for all promises to resolve before moving to the next card
      await Promise.all(promises);
    });
  };

  // Fetch data on component mount
  useEffect(() => {
    const unsubscribe = fetchData();

    return unsubscribe;
  }, [filterValue]);

  // Modal handlers
  const handleOpen = (data) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handleModalPrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <AdLibraryFilter
          onSearchClick={() => setSelectedCards([])}
          selectedCards={selectedCards}
          saveMedia={saveMedia}
          onFilterChange={handleFilterChange}
          onFilterResultsChange={handleFilterResultsChange}
          onClearSelection={handleClearSelection}
          clearData={() => setUsageData([])}
        />
        {/* <Button
          onClick={() => {
            console.log("total items", usageData);
            console.log("current items", currentItems);
          }}
        >
          test data
        </Button> */}
        {usageData.length > 10 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
              disabled={currentPage === 1}
            >
              Prev
            </Button>

            <Typography variant="subtitle2">
              Page {currentPage} of {totalPages}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setCurrentPage((old) => Math.min(old + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        )}
        <Grid container>
          {currentItems.map((data, index) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Card
                    className={classes.squareCard}
                    key={index}
                    // onClick={() => handleCardClick(index)}
                    style={{
                      // backgroundColor: selectedCards.includes(index)
                      //   ? "#f0f0f0"
                      //   : "white",
                      border: selectedCards.includes(index)
                        ? "1px solid #087F8C"
                        : "none",
                      boxShadow: selectedCards.includes(index)
                        ? "0px 0px 10px 2px rgba(63,81,181,0.5)"
                        : "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
                      transition: "all 0.1s ease",
                    }}
                  >
                    <Box className={classes.cardActionArea}>
                      <CardContent style={{ position: "relative" }}>
                        <CheckCircleIcon
                          fontSize="large"
                          style={{
                            color: selectedCards.includes(index)
                              ? "#3f51b5"
                              : "#ccc",
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleCardClick(index);
                          }}
                        />

                        <Box mb={1}>
                          <Typography variant="body2">
                            Library ID: {data.adArchiveID}
                          </Typography>
                        </Box>

                        {/* <Box mb={1}>
                          <Typography variant="body2">
                            <CheckCircleIcon fontSize="inherit" />
                            Active
                          </Typography>
                        </Box>

                        <Box mb={1}>
                          <Typography variant="body2">
                            Started running on 1 Nov 2023
                          </Typography>
                        </Box> */}

                        <Box mb={1}>
                          <Typography variant="body2">
                            <strong>{data.collationCount} ads</strong> using
                            this creative and text
                          </Typography>
                        </Box>
                        <Divider
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        />

                        <Box display="flex" alignItems="center" mb={3}>
                          <Avatar
                            style={{ width: "2rem", height: "2rem" }}
                            src={data.page_profile_picture}
                          />
                          <Box ml={1.5}>
                            <Typography
                              style={{ fontWeight: "900" }}
                              variant="body2"
                              component="h4"
                            >
                              {data.page_name}
                            </Typography>
                            <Typography
                              style={{ fontWeight: "700" }}
                              variant="caption"
                              color="textSecondary"
                            >
                              Sponsored
                            </Typography>
                          </Box>
                        </Box>

                        <Box mb={3} onClick={() => toggleTruncateText(index)}>
                          <div>
                            {data &&
                              data.copywriting &&
                              data.copywriting.length > 0 &&
                              ReactHtmlParser(
                                expandedDiv === index
                                  ? data.copywriting[0]
                                  : `${data.copywriting[0].substring(
                                      0,
                                      100
                                    )} ...`
                              )}
                          </div>
                        </Box>
                        {data && data.images && data.images.length > 0 && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <Carousel
                              showThumbs={false}
                              showStatus={false}
                              dynamicHeight={true}
                              width="200px"
                              infiniteLoop={true}
                              autoPlay={true}
                              stopOnHover={true}
                              showIndicators={false}
                            >
                              {data.images.map((image, index) => (
                                <div key={index}>
                                  <img
                                    src={image}
                                    alt={`Winning ads image ${index}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </Box>
                        )}
                        {/* {data && data.images && data.images[0] && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <img
                              src={data.images[0]}
                              alt="Winning ads image"
                              style={{ width: "200px", height: "200px" }}
                            />
                          </Box>
                        )} */}

                        {data && data.videos && data.videos[0] && (
                          <Box
                            bgcolor={theme.palette.grey[50]}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="100%"
                          >
                            <video
                              src={data.videos[0]}
                              alt="Winning ads video"
                              style={{ width: "200px", height: "200px" }}
                              controls
                            />
                          </Box>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <h2>
              {selectedData &&
                new Date(
                  selectedData.date.seconds * 1000 +
                    selectedData.date.nanoseconds / 1000000
                ).toLocaleString()}
            </h2>

            {selectedData && selectedData.length > 0 && (
              <div className={classes.modalAdContainer}>
                <div className={classes.modalContainerButtons}>
                  <Button
                    onClick={handleModalPrev}
                    disabled={currentIndex === 0}
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={handleModalNext}
                    disabled={currentIndex === selectedData.length - 1}
                  >
                    Next
                  </Button>
                </div>
                <Card className={classes.modalAdContent}>
                  <CardContent>
                    <h3>
                      Ad Archive ID: {selectedData[currentIndex].adArchiveID}
                    </h3>
                    {selectedData[currentIndex].images &&
                      selectedData[currentIndex].images.map((image, i) => (
                        <img
                          key={i}
                          src={image}
                          style={{ maxWidth: "300px" }}
                        />
                      ))}
                    {selectedData[currentIndex].videos &&
                      selectedData[currentIndex].videos.map((video, i) => (
                        <video key={i} width="300" height="300" controls>
                          <source src={video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ))}
                    {selectedData[currentIndex].copywriting.map((copy, i) => (
                      <div key={i}>{ReactHtmlParser(copy)}</div>
                    ))}
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        </Modal>
      </Container>
    </Section>
  );
};

export default ScraperHistorySection;
