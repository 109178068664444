import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfUspSection from "../../components/eng/CfUspSection";

function CfUspPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (English)" />
      <CfUspSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (English)"
        subtitle="独特卖点"
      />
    </>
  );
}

export default requireProUser(CfUspPage);
