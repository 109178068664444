import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { useAuth } from "./../util/auth";
import { getUserLists } from '../util/db';

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    flex: 1,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    marginLeft: theme.spacing(3),
  },
  list: {
    maxHeight: '100px',
    overflow: 'auto',
  },
}));

function SettingsAffiliate() {
  const classes = useStyles();
  const auth = useAuth();
  const [registeredList, setRegisteredList] = useState([]);
  const [paidList, setPaidList] = useState([]);

  const referralLink = auth.user.referralLink;
  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
  };

  useEffect(() => {
    const fetchUserLists = async () => {
      const { registeredList, paidList } = await getUserLists(auth.user.uid);
      setRegisteredList(registeredList);
      setPaidList(paidList);
    };

    fetchUserLists();
  }, [auth.user.uid]);

  return (
    <Box mt={3}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h6" className={classes.title}>
            Referral Link:
          </Typography>
          <Typography>
            {referralLink}
            <IconButton
              onClick={handleCopy}
              color="primary"
              size="small"
              className={classes.iconButton}
            >
              <FileCopy />
            </IconButton>
          </Typography>
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" className={classes.title}>
                No. of users referred:
              </Typography>
              <Typography>{auth.user.registered_referral_count}</Typography>
              <List className={classes.list}>
                {registeredList.map((user, index) => (
                  <ListItem key={index}>{user}</ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" className={classes.title}>
                No. of Paid users referred:
              </Typography>
              <Typography>{auth.user.success_referral_count}</Typography>
              <List className={classes.list}>
                {paidList.map((user, index) => (
                  <ListItem key={index}>{user}</ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SettingsAffiliate;