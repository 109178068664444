function loader(element, loadingInterval) {
    console.log('in queue, please wait')
    element.textContent = "生成中...";
  
    loadingInterval.current = setInterval(() => {
      switch (element.textContent) {
        case "生成中...":
          element.textContent = "生成中";
          break;
        case "生成中":
          element.textContent = "生成中.";
          break;
        case "生成中.":
          element.textContent = "生成中..";
          break;
        case "生成中..":
          element.textContent = "生成中...";
          break;
        default:
          element.textContent = "生成中...";
      }
    }, 300);
  }
  export default loader;
  