import React, { useContext, useState, useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import ImageItems from "./ImageItems";
import { Link, useRouter } from "../../util/router";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import '../../style/container.css'
import generateUniqueId from "../../util/generateuniqueid";
import typeText from "../../util/typetext";
import loader from "../../util/loader";
import chatStripe from "../../util/chatStripe";
import { Divider } from "@material-ui/core";
import ChatStripe from "../ChatStripe2";
import { calcLength } from "framer-motion";
import { promptArray } from "./PromptArray";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "695px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
}));

function ImageSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    loadingInterval,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const formRef = useRef(null);
  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");
  const [industry, setIndustry] = useState("product");
  const [imagePrompt, setImagePrompt] = useState("");
  const [theme, setTheme] = useState("");
  const [description, setDescription] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");
  const [model, setModel] = useState("realistic");
  const [distance, setDistance] = useState("");

  // State to manage the list of messages
  const [messages, setMessages] = useState([]); 

  let marketingPrompt
  let query

  query = imagePrompt

  const handleThemeChange = (event) => {
    setDescription("")
    setTheme(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleImagePromptChange = (event) => {
    setImagePrompt(event.target.value);
  };
  const handleAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };
  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };


  useEffect(()=> {
    if (theme === "self") {
      setImagePrompt(description)
    } else {
      setImagePrompt(promptArray[theme])
    }
  }, [theme, description])

  const [queue, setQueue] = useState([]);
  let isProcessing = useRef(false);

  useEffect(() => {
    if (!isProcessing.current && queue.length > 0) {
      processQueue();
    }
  }, [queue]);

  function handleSubmit(id, e, lastInput = false) {
    e && e.preventDefault();
    setLoading(true);
    setIsChatOpen(true);
    setClearChat(false);

    // bot's chatStripe
    let uniqueId;
    uniqueId = generateUniqueId();

    setMessages(oldMessages => [
      {
        isUser: false,
        value: '',
        error: false,
        uniqueId: uniqueId,
        loading: true
      },
      ...oldMessages
    ]);

    setQueue(oldQueue => [...oldQueue, { id, e, lastInput, uniqueId }]);
  }
  async function processQueue() {
    if (queue.length === 0) {
      isProcessing.current = false;
      return;
    }

    isProcessing.current = true;
    const { id, e, lastInput, uniqueId } = queue[0];
    await handleFetch(id, e, lastInput, uniqueId);
    setQueue(oldQueue => oldQueue.slice(1));
    isProcessing.current = false;
  }

  async function handleFetch(id, e, lastInput = false, uniqueId) {
    // fetching data from server
    let endpointUrl
    if (id === 1) {
      endpointUrl = "https://ctg-api.onrender.com/v2_generate"
      // endpointUrl = "http://localhost:5001/v2_generate"
    } else if (id === 2) {
      endpointUrl = "https://ctg-api.onrender.com/v2_image_optimizer"
      // endpointUrl = "http://localhost:5001/v2_image_optimizer"
    }

    return fetch(endpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: imagePrompt,
        aspectRatio: aspectRatio,
        name: auth.user.displayName,
        uid: auth.user.uid,
        email: auth.user.email,
        model,
        distance,
      }),
    })
      .then(async (response) => {
        // console.log('Request completed', response);
        if (response.ok) {
          setError(false);
          const data = await response.json();
          const parsedData = data.data;

          if (parsedData === "") {
            setMessages(oldMessages => [{
              isUser: false,
              value: "No results Found 😞",
              error: false,
              uniqueId: uniqueId
            }, ...oldMessages]);
          } else {
            // Create an img element with the imageUrl as its src
            const imageElement = <img src={parsedData} alt="Generated image" style={{ width: "100%", height: "100%" }} />;
            setTyping(true);
            setTimeout(() => {
              setMessages(oldMessages =>
                oldMessages.map(message =>
                  message.uniqueId === uniqueId
                    ? {
                      ...message,
                      value: imageElement,
                      loading: false
                    }
                    : message
                )
              );
              setTyping(false);
            }, typingInterval);

          }
        } else {
          setError(true);
          setMessages(oldMessages =>
            oldMessages.map(message =>
              message.uniqueId === uniqueId
                ? {
                  ...message,
                  value: "An error occurred. Try again later",
                  error: true,
                  loading: false
                }
                : message
            )
          );
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        setMessages(oldMessages =>
          oldMessages.map(message =>
            message.uniqueId === uniqueId
              ? {
                ...message,
                value: "Sorry, server is down right now, try again later",
                error: true,
                loading: false
              }
              : message
          )
        );
      });

  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            {/* <button onClick={()=>{
              console.log(distance)
            }}>
              test data
            </button> */}
            <ImageItems
              theme={theme}
              description={description}
              aspectRatio={aspectRatio}
              model={model}
              distance={distance}
              onThemeChange={handleThemeChange}
              handleSubmit={handleSubmit}
              onImagePromptChange={handleImagePromptChange}
              onDescriptionChange={handleDescriptionChange}
              onAspectRatioChange={handleAspectRatioChange}
              onModelChange={handleModelChange}
              onDistanceChange={handleDistanceChange}
            />
          </Grid>

          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>
                  <OutputContainer
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    chatRef={chat_container_ref}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
      </Container>
    </Section>
  );
}

export default ImageSection;



function OutputContainer({ typing, error, messages, id }) {
  const classes = useStyles({ typing, error });

  return (
    <div id={id}>
      {messages.map((message, index) => (
        <ChatStripe
          key={message.uniqueId || index}
          isAi={!message.isUser}
          value={message.value}
          uniqueId={message.uniqueId}
          showIcon={true}
          loading={message.loading}
          noCopy={true}
        />
      ))}

      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}