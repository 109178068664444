import React from "react";
import Meta from "../components/Meta";
import SimplifierSection from "../components/SimplifierSection";
import { requireProUser } from "../util/auth";

function SimplifierPage(props) {
  return (
    <>
      <Meta title="Copywriting Simplifier" />
      <SimplifierSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="文案转白话"
        subtitle="Simplifier"
      />
    </>
  );
}

export default requireProUser(SimplifierPage);
