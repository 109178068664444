import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collectionGroup, query, orderBy, onSnapshot, limit, startAfter, endBefore, where, getCountFromServer, limitToLast  } from 'firebase/firestore';
import { firebaseApp } from '../util/firebase';

import Box from "@material-ui/core/Box";
import { Paper, Typography, Button, ImageList, ImageListItem } from '@material-ui/core';
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../util/auth";
import { Link } from "../util/router";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    boxShadow: theme.shadows[4],
  },
  button: {
    color: 'white',
    backgroundColor: '#3f51b5',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
    margin: '10px',
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    margin: '10px',
  },
  typography: {
    fontWeight: 'bold',
  },
    container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ReplaceHistorySection = (props) => {
  const classes = useStyles();
  const auth = useAuth();

  const [usageData, setUsageData] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [firstVisibleDoc, setFirstVisibleDoc] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const maxResults = 10;
  const userEmail = auth.user.email;
  // const userEmail = "callum.theadspert@gmail.com";

  useEffect(()=> {
    const getCount = async () => {
        const db = getFirestore(firebaseApp);
        const q = query(collectionGroup(db, 'replace'), where("obj.email", "==", userEmail))
        const snapshot = await getCountFromServer(q)
        console.log(snapshot.data())
        
        // calculate total count and total pages here
        const count = snapshot.data().count;
        const total = count > 0 ? Math.ceil(count / maxResults) : 0;

        // update state 
        setTotalCount(count);
        setTotalPages(total);
    }
    getCount().catch(err=> console.log(err))
}, [])

  // Helper function to create a query based on the direction and cursor
  const createQuery = (db, direction, cursor) => {
    if (direction === "next") {
      return cursor
        ? query(
            collectionGroup(db, "replace"),
            orderBy("obj.time", "desc"),
            startAfter(cursor),
            limit(maxResults),
            where("obj.email", "==", userEmail),
          )
        : query(collectionGroup(db, "replace"), orderBy("obj.time", "desc"), limit(maxResults), where("obj.email", "==", userEmail));
    } else if (direction === "prev") {
      return query(
        collectionGroup(db, "replace"),
        orderBy("obj.time", "desc"),
        endBefore(cursor),
        limitToLast(maxResults),
        where("obj.email", "==", userEmail),
      );
    }
  };

  // Fetch usage data from Firestore
  const fetchData = async (cursor = null, direction = "next") => {
    const db = getFirestore(firebaseApp);
    const q = createQuery(db, direction, cursor);
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFirstVisibleDoc(snapshot.docs[0]);
        setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
        setUsageData(data);
        setHasNextPage(data.length === maxResults);
        setHasPrevPage(!!cursor);
      } else {
        setHasNextPage(false);
      }
    });
  
    return unsubscribe;
  };

  // Navigate to the next page
  const nextPage = () => {
    if (hasNextPage) {
      const unsubscribe = fetchData(lastVisibleDoc, "next");
      setCurrentPage(currentPage + 1);
      return unsubscribe;
    }
  };
  
  // Navigate to the previous page
  const prevPage = () => {
    if (hasPrevPage) {
      const unsubscribe = fetchData(firstVisibleDoc, "prev");
      setCurrentPage(currentPage - 1);
      return unsubscribe;
    }
  };

  // Download the image when the image is clicked
  const downloadImage = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.png';
    link.target = '_blank';
    link.click();
  };
  
  // Fetch data on component mount
  useEffect(() => {
    const unsubscribe = fetchData();

    return unsubscribe
  }, []);

  
  // Render the component
  return (
    <Section
    bgColor={props.bgColor}
    size={props.size}
    bgImage={props.bgImage}
    bgImageOpacity={props.bgImageOpacity}
  >
    <Container>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={4}
        textAlign="center"
      />

    <Box sx={{ m: 5, p: 5, display: "flex", flexDirection: "column", justifyContent: "center" }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button 
        onClick={prevPage} 
        disabled={currentPage === 1} 
        className={currentPage === 1 ? classes.disabledButton : classes.button}
      >
        Prev
      </Button>
      <Box sx={{ p: 2}}>
        <Typography variant='subtitle2'>
          Page {currentPage} of {totalPages}
        </Typography>
      </Box>

      <Button 
        onClick={nextPage} 
        disabled={currentPage === totalPages} 
        className={currentPage === totalPages ? classes.disabledButton : classes.button}
      >
        Next
      </Button>
    </Box>
      <Button color="inherit" textalign="right" component={Link} to="/user-history">
        Back to Text History
      </Button>
      <ImageList cols={2} rowHeight={500}>
        {usageData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.obj.output}?w=248&fit=crop&auto=format`}
              srcSet={`${item.obj.output}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              onClick={() => downloadImage(item.obj.output)}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
    </Container>
    </Section>
    );
    };

export default ReplaceHistorySection;
