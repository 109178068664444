import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfJiangXiangSection from "../../components/confidence/CfJiangXiangSection";

function CfJiangXiangPage(props) {
  return (
    <>
      <Meta title="Confidence Reply" />
      <CfJiangXiangSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply"
        subtitle="获得奖项"
      />
    </>
  );
}

export default requireProUser(CfJiangXiangPage);
