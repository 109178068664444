import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Box, Container, Typography, Button} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { getFirestore, collection, query, doc, where, getDoc, getDocs, setDoc, increment, arrayUnion } from "firebase/firestore";
import { firebaseApp } from "../util/firebase";
import axios from "axios";
import md5 from "md5";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8),
    },
    cardContent: {
      marginBottom: theme.spacing(3),
      height: "auto",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        display: "none"
      },
    },
    copy: {
      display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
      cursor: "pointer",
      "& img": { width: "22px" },
    },
    columnDirection: {
      flexDirection: 'column',
    },
  }));
  
const PaymentSection = (props) => {
    const classes = useStyles();
    const auth = useAuth();
    const db = getFirestore(firebaseApp);
    let searchforReferee, referee_name, referee_email, referee_id;
    let referrer_id, referrer_name, referrer_email, referrer_success_count;
    let detail, amount, order_id, hash;
    const role = auth.user ? auth.user.role : null;
    const [history, setHistory] = useState([]);

    const plans = [
        {
            // Elte plan
            id: "Elite",
            name: "CTG AI Elite Plan",
            detail: "CTG_AI_Elite_Plan",
            price: "1900",
            description: "以上价格不包括 8% SST （总价RM2052）",
            perks: [
                "终身应用更新",
                "所有功能使用无阻",
                "含有 1000 credits",
            ],
            totalamt: "2052.00", // change to RM5.00 if for testing
        },
    ];

    useEffect(async () => {
        searchforReferee = false;
        await handleReferralData(searchforReferee);
        getTransactionHistory(referee_id).then((history) => {
            setHistory(history);
        },[]);
    }, []);

    const handleSubmit = async (plan) =>{
        const merchantId = "604159903260726"; // live merchant id
        const secretKey = "22745-664"; // live secret key
        // const merchantId = "756169329240271"; // test merchant id
        // const secretKey = "5919-269"; // test secret key

        // Fetch user data from database
        detail = plan.detail; // name of the product
        amount = plan.totalamt; // amount to be paid for the product
        let data = await getOrderID();
        order_id = data.latest_order_id + 1; // id get from sql database table payment_order

        const tobeHashed = secretKey + detail + amount + order_id;
        // hash = sha256(tobeHashed);
        hash = md5(tobeHashed);

        searchforReferee = true;
        await handleReferralData(searchforReferee);

        await insertPaymentOrder(order_id, referee_name, "", referee_email, detail, amount, hash);

        if (referrer_id){
            await insertRefereeData(referee_id, order_id, "", "", detail, amount, referee_name, "", referee_email, hash, referrer_id, referrer_name, referrer_email);
            await insertReferrerData(referrer_id, referrer_name, referrer_email, referee_id, referee_name, detail);
        }
        sessionStorage.setItem('user_id', auth.user.uid);
        sessionStorage.setItem('detail', detail);
        sessionStorage.setItem('amount', amount);
        // // additional params
        // sessionStorage.setItem('order_id', order_id);

        await submitSenangPay();
    }

    // function to handle referral data
    const handleReferralData = async (searchforReferee) => {
        const userDocRef = doc(db, "users", auth.user.uid);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
            referee_name = docSnapshot.get('name');
            referee_email = docSnapshot.get('email');
            referee_id = docSnapshot.get('referralID');
            referrer_id = docSnapshot.get('referralCode');
        } else {
            console.log("No such document!");
        }

        if(searchforReferee == true){
            await handleReferrerData();
        }
    }

    // function to handle referrer data
    const handleReferrerData = async () => {
        // search referee data from firebase
        const usersCollectionRef = collection(db, "users");
        const q = query(usersCollectionRef, where("referralID", "==", referrer_id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            querySnapshot.forEach(async (documentRef) => {
                referrer_name = documentRef.get('name');
                referrer_email = documentRef.get('email');
                referrer_success_count = documentRef.get('success_referral_count');

                // Update success_referral_count by 1
                const userDocRef = doc(db, 'users', documentRef.id);
                await setDoc(userDocRef, {
                    success_referral_count: increment(1),
                    paid_list: arrayUnion(referee_name),
                }, { merge: true });
            });
        } else {
            console.log("No such document!");
        }
    }

    // call SenangPay API for transaction
    const submitSenangPay = async () => {
        const userInfo = {
            name: referee_name, // get from firebase
            email: referee_email, // get from firebase
            phone: "", // should be empty
        };

        const payload = {
            detail,
            amount,
            order_id,
            hash,
            ...userInfo,
        }

        // Send POST request to SenangPay
        try {
            const response = await axios({
              method: "POST",
            //   url: "http://localhost:5001/senangpay",
            //   url: "https://ctg-api-dev.onrender.com/senangpay",
              url: "https://ctg-api.onrender.com/senangpay",
              data: payload,
            });
      
            if (response.data) {
              document.open();
              document.write(response.data);
              document.close();
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Fetch transaction history from sql database
    async function getTransactionHistory(referee_id) {
        // return fetch(`http://localhost:5001/sql_transaction_history?referee_id=${referee_id}`, {
        // return fetch(`https://ctg-api-dev.onrender.com/sql_transaction_history?referee_id=${referee_id}`, {
        return fetch(`https://ctg-api.onrender.com/sql_transaction_history?referee_id=${referee_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const history = data.filter(transaction => transaction !== null && transaction.detail !== null && transaction.transactionId !== null && transaction.amountPaid !== null && transaction.status !== null && transaction.createdAt !== null).map(transaction => ({
                    details: transaction.detail,
                    perks: [
                        "Transaction ID: " + transaction.transactionId,
                        "Total amount paid: RM" + transaction.amountPaid,
                        "Status: " + (transaction.status === '1' ? "Payment Success" : "Payment Failed"),
                        "Transaction Date: " + new Date(transaction.createdAt).toLocaleString()
                    ],
                }));
                return history;
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    // Fetch order id from sql database
    async function getOrderID() {
        // return fetch("http://localhost:5001/sql_latest_order_id", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_latest_order_id", {
        return fetch("https://ctg-api.onrender.com/sql_latest_order_id", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = data;
                return parsedData;
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    // Write into sql database (payment_order)
    async function insertPaymentOrder(order_id, referee_name, referee_phone, referee_email, detail, amount, hash) {
        // return fetch("http://localhost:5001/sql_insert_payment_order", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_insert_payment_order", {
        return fetch("https://ctg-api.onrender.com/sql_insert_payment_order", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            order_id,
            referee_name,
            referee_phone,
            referee_email,
            detail,
            amount,
            hash
        })
        }).then(async (response) => {
            // console.log("Payment call completed");
            if (response.ok) {
                const data = await response.json();
                const parsedData = data; // inserted orderID in payment_order
                return parsedData;
            }
        }).catch((err) => {
            // console.log("Payment call incomplete");
            console.error(err);
        })
    }

    // Write into sql database (referee)
    async function insertRefereeData(referee_id, order_id, transaction_id, status, detail, amount, referee_name, referee_phone, referee_email, hash, referrer_id, referrer_name, referrer_email) {
        // return fetch("http://localhost:5001/sql_insert_referee", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_insert_referee", {
        return fetch("https://ctg-api.onrender.com/sql_insert_referee", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            referee_id,
            order_id,
            transaction_id,
            status,
            detail,
            amount,
            referee_name,
            referee_phone,
            referee_email,
            hash,
            referrer_id,
            referrer_name,
            referrer_email
        })
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = data; // inserted index in referee
                return parsedData;
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    // Write into sql database (referrer)
    async function insertReferrerData(referrer_id, referrer_name, referrer_email, referee_id, referee_name, detail) {
        // return fetch("http://localhost:5001/sql_insert_referrer", {
        // return fetch("https://ctg-api-dev.onrender.com/sql_insert_referrer", {
        return fetch("https://ctg-api.onrender.com/sql_insert_referrer", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            referrer_id,
            referrer_name,
            referrer_email,
            referee_id,
            referee_name,
            detail
        })
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = data; // inserted index in referrer
                return parsedData;
            }
        }).catch((err) => {
            console.error(err);
        })
    }
      
    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <Box textAlign="center" paddingLeft={6} paddingRight={6}>
                    <SectionHeader
                        title="Pricing on Plan"
                        // subtitle={props.subtitle}
                        size={4}
                    />
                    <Grid container={true} spacing={4}>
                        {/* Left Grid */}
                        <Grid item={true} xs={12} md={6}>
                        {plans.map((plan, index) => (
                            <Grid className={classes.columnDirection} item={true} xs={12} md={12} key={index}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="h6" component="h2">
                                            {plan.name}
                                        </Typography>
                                        <Box className={classes.price} mt={1}>
                                            <Typography variant="h4">RM {plan.price}</Typography>
                                        </Box>

                                        {plan.description && (
                                            <Box mt={2}>
                                            <Typography component="p" color="textSecondary">
                                                {plan.description}
                                            </Typography>
                                            </Box>
                                        )}

                                        {plan.perks && (
                                            <Box mt={1}>
                                            <List aria-label="perks">
                                                {plan.perks.map((perk, index) => (
                                                <ListItem
                                                    className={classes.listItem}
                                                    disableGutters={true}
                                                    key={index}
                                                >
                                                    <ListItemIcon className={classes.perkIcon}>
                                                    <CheckIcon />
                                                    </ListItemIcon>
                                                    <ListItemText>{perk}</ListItemText>
                                                </ListItem>
                                                ))}
                                            </List>
                                            </Box>
                                        )}

                                        <Box mt="auto" pt={3}>
                                            <Button
                                                onClick={async (e) => {
                                                    handleSubmit(plan, e);
                                                }}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                fullWidth={true}
                                                disabled={role === 'Elite' || role === 'Pro'}
                                            >
                                            {role === 'Elite' || role === 'Pro' ? '感谢您的购买!' : '点击购买并开始使用!'}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                        </Grid>

                        {/* Right Grid */}
                        {history && history.length > 0 ? (
                            <Grid item={true} xs={12} md={6}>
                                <Typography variant="h4" align="center" gutterBottom>
                                Latest Transaction Detail
                                </Typography>
                                {history.map((historyItem, index) => (
                                <Card className={classes.card} key={index}>
                                    <CardContent className={classes.cardContent}>
                                    <Typography
                                        variant="subtitle1"
                                        align="left"
                                        className={classes.transactionDetails}
                                    >
                                        {historyItem.details}
                                    </Typography>
                                    {historyItem.perks && (
                                        <Box mt={1} className={classes.perksBox} align="left">
                                        {historyItem.perks.map((perk, index) => (
                                            <Typography variant="body1" key={index}>
                                            - {perk}
                                            </Typography>
                                        ))}
                                        </Box>
                                    )}
                                    </CardContent>
                                </Card>
                                ))}
                            </Grid>
                        ) : (
                            <Grid item={true} xs={12} md={6}>
                            <Typography variant="h4" align="center" gutterBottom>
                                No recent transactions
                            </Typography>
                            </Grid>
                        )}
                        
                    </Grid>
                </Box>
            </Container>
        </Section>
    );
}

export default PaymentSection;