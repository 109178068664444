import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import DescActSection from "../../components/malay/DescActSection";

function DescActPage(props) {
  return (
    <>
      <Meta title="Description - Action Generator (Malay)" />
      <DescActSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Description - Action (马来文)"
        subtitle="马来文 文案"
      />
    </>
  );
}

export default requireProUser(DescActPage);
