import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      <p>
      Welcome to CTG-AI ("the Service"), a content generation platform based on GPT. 
      The Service is owned and operated by CTG ("us", "we", or "our"). 
      These Terms of Service ("Terms") govern your access to and use of the Service. 
      By accessing or using the Service, you agree to be bound by these Terms.

      </p>
      <p>
        These Terms of Service govern your use of {props.domain} and any related
        services provided by {props.companyName}. By accessing {props.domain},
        you agree to abide by these Terms of Service and to comply with all
        applicable laws and regulations.
      </p>
      <p>
        If you do not agree with these Terms of Service, you are prohibited from
        using or accessing {props.domain} and from using any other services we
        provide. We reserve the right to review and amend any of these Terms of
        Service at our sole discretion. Upon doing so, we will update this page.
        Any changes to these Terms of Service will take effect immediately from
        the date of publication.
      </p>
      <p>This policy was last updated on March 27th, 2023.</p>

      <h2>Eligibility</h2>
      <p>
        Access to the closed beta is by invitation only, 
        and you may not share your access or invite others to use the Service without our express written consent.
      </p>

      <h2>Closed Beta Restrictions</h2>
      <p>
        As a participant in the closed beta, you agree not to share access to the Service, demonstrate the Service to non-access users, or disclose any information about the Service to third parties without our prior written consent. 
        Any unauthorized sharing or disclosure may result in the termination of your access to the closed beta.
      </p>

      <h2>User Conduct</h2>
      <p>
        You agree not to use the Service for any unlawful or prohibited purpose. 
        You may not use the Service in any manner that could damage, disable, overburden, or impair the 
        Service or interfere with any other party's use and enjoyment of the Service.
      </p>

      <h2>Termination</h2>
      <p>
        We reserve the right, in our sole discretion, 
        to terminate your access to the closed beta at any time, for any reason, without notice or liability.
      </p>

      <h2>Change to Terms</h2>
      <p>
        We may modify these Terms at any time, in our sole discretion. If we make changes to these Terms, we will update the "Last updated" date at the top of this page. 
        By continuing to access or use the Service after any changes to the Terms, you agree to be bound by the revised Terms.
      </p>
    </LongContent>
  );
}

export default LegalTerms;
