import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import XhsSection from "../components/XhsSection";

function XhsPage(props) {
  return (
    <>
      <Meta title="Xiao Hong Shu Generator" />
      <XhsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="小红书文案生成"
        subtitle="Xiao Hong Shu (RED)"
      />
    </>
  );
}

export default requireProUser(XhsPage);