import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import {
  saveInputs,
  retrieveInputs,
  resetInput,
  legacySavedOptionsMethod,
} from "../../util/db";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Toaster, toast } from "react-hot-toast";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SavedReplySection from "./../SavedReplySection";
import { useRefresh } from "../../context/RefreshContext";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./../functionalComponent/LegacyModeSwitch";
import LanguageOutput from './../functionalComponent/LanguageOutput';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "950px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
}));

function DescActItems(props) {
  const { handleSubmit } = props;
  const {
    industry,
    onIndustryChange,
    onProductNameChange,
    onProductUSPChange,
    onProductIngredientChange,
    onProductCertChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { refresh } = useRefresh();
  const { t, i18n } = useTranslation();
  // default input declaration
  const [productName, setProductName] = useState([]);
  const [productUSP, setProductUSP] = useState([]);
  const [productIngredient, setProductIngredient] = useState([]);
  const [productCert, setProductCert] = useState([]);
  // default input legacy declaration
  const [legacyMode, setLegacyMode] = useState(false);
  const [productNamelegacy, setProductNamelegacy] = useState([]);
  const [productUSPlegacy, setProductUSPlegacy] = useState([]);
  const [productIngredientlegacy, setProductIngredientlegacy] = useState([]);
  const [productCertlegacy, setProductCertlegacy] = useState([]);
  const [savedInputs, setSavedInputs] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [uspOptions, setUSPOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [certOptions, setCertOptions] = useState([]);
  // savedInputs dialog
  const [openDialog, setOpenDialog] = useState(false);
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  // Handles the clearing of inputs
  const clearInputs = () => {
    setProductName([]);
    setProductUSP([]);
    setProductIngredient([]);
    setProductCert([]);
  };
  
  // Handles the legacy mode change
  const handleLegacyModeChange = (newValue) => {
    setLegacyMode(newValue);
  };

  // Handles the dialog toggle for savedInputs
  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  // Save inputs from the text fields (Only in Legacy Mode)
  const handleSaveInputs = () => {
    const inputsArray = [
      { key: "product_name", value: productNamelegacy },
      { key: "usp", value: productUSPlegacy },
      { key: "product_ingredient", value: productIngredientlegacy },
      { key: "product_cert", value: productCertlegacy },
    ];
    // Check if any of the values are undefined
    const hasUndefinedValues = inputsArray.some(
      (input) => input.value === undefined
    );
    if (hasUndefinedValues) {
      console.error("Some input values are undefined");
      return;
    }
    // Filter out inputs that already exist in the retrieved inputs
    const uniqueInputs = inputsArray.filter(
      (newInput) =>
        !savedInputs.some(
          (savedInput) =>
            savedInput.key === newInput.key &&
            savedInput.value === newInput.value
        )
    );
    // If all inputs are duplicates, stop execution
    if (uniqueInputs.length === 0) {
      console.log("No new inputs to save");
      return;
    }
    // Execute savedInputs
    saveInputs(auth.user.uid, uniqueInputs).then((savedKeys) => {
      handleRetrieveInputs(); // Retrieve the inputs again after saving
      if (savedKeys == undefined) {
        toast.success("Input has been saved");
      }
    });
  };

  // Delete inputs from the dropdown (Only in Legacy Mode)
  const handleDeleteInput = (inputId, inputValue) => {
    resetInput(auth.user.uid, inputId, inputValue)
      .then(() => {
        handleRetrieveInputs();
      })
      .catch((error) => {
        console.error("Error resetting input:", error);
      });
  };

  // Handles the retrieval of savedInputs
  const handleRetrieveInputs = async () => {
    try {
      const inputs = await retrieveInputs(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedInputs(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  const fetchLegacyMode = async () => {
    const legacyModeValue = await legacySavedOptionsMethod(auth.user.uid);
    setLegacyMode(legacyModeValue);
    props.onLegacyModeChange(legacyModeValue);
  };

  // useEffect for retrieve legacy mode status and set savedInputs 
  useEffect(async () => {
    fetchLegacyMode();
    handleRetrieveInputs();
  }, []);

  // useEffect for retrieve and set savedInputs after refresh
  useEffect(() => {
    handleRetrieveInputs();
  }, [refresh]);

  // useEffect to set savedInputs into options
  useEffect(() => {
    setNameOptions(
      savedInputs
        .filter((input) => input.key === "product_name")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setUSPOptions(
      savedInputs
        .filter((input) => input.key === "usp")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setIngredientOptions(
      savedInputs
        .filter((input) => input.key === "product_ingredient")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setCertOptions(
      savedInputs
        .filter((input) => input.key === "product_cert")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );
  }, [savedInputs]);

  // Only when legacy mode is false
  const onNameSelectChange = (event) => {
    setProductName(event.target.value);
    const nameValues = event.target.value.map((item) => item.value).join(" ");
    onProductNameChange(nameValues);
  };

  const onUSPSelectChange = (event) => {
    setProductUSP(event.target.value);
    const uspValues = event.target.value.map((item) => item.value).join(" ");
    onProductUSPChange(uspValues);
  };

  const onIngredientSelectChange = (event) => {
    setProductIngredient(event.target.value);
    const ingredientValues = event.target.value.map((item) => item.value).join(" ");
    onProductIngredientChange(ingredientValues);
  };

  const onCertSelectChange = (event) => {
    setProductCert(event.target.value);
    const certValues = event.target.value.map((item) => item.value).join(" ");
    onProductCertChange(certValues);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Toaster position="bottom-right" reverseOrder={false} />
          <Typography variant="h5">Generator</Typography>
          <Box>
            {!legacyMode && (
              <>
                <Tooltip title="Clear Inputs">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={clearInputs}
                    style={{ marginLeft: "1rem" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleDialogToggle}
                    style={{ marginLeft: "1rem" }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <LegacyModeSwitch
              auth={auth}
              initialLegacyMode={legacyMode}
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Box>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 1: 选择是否是产品/服务 */}
            Step 1: {t("CPW002") + t("CPW018")}
          </Typography>
          <FormControl>
            <InputLabel
              id="select-label"
              margin="dense"
              disableAnimation={true}
            >
              {/* 你卖什么 */}
              {t("CPW019")}
            </InputLabel>
            <Select
              labelId="select-label"
              id="select"
              label="industry"
              value={industry}
              onChange={onIndustryChange}
              required
            >
              <MenuItem value={"product"}>
                {/* 产品 */}
                {t("CPW020")}
              </MenuItem>
              <MenuItem value={"service"}>
                {/* 服务 */}
                {t("CPW021")}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 2: 品牌名字和卖的产品/服务 */}
            Step 2: {t("CPW006")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={nameOptions}
              value={productNamelegacy}
              onChange={(value) => {
                setProductNamelegacy(value);
                onProductNameChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Name", value)}
              // placeholder="输入品牌名字和卖的产品/服务"
              placeholder={t("CPW001") + t("CPW006")}
              minRowsValue={2}
              maxRowsValue={2}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={nameOptions}
              value={productName}
              onChange={onNameSelectChange}
              // label="Product Name"
              label={t("CPW006")}
              id="product_name"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 3: 品牌卖点 (USP) */}
            Step 3: {t("CPW015")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={uspOptions}
              value={productUSPlegacy}
              onChange={(value) => {
                setProductUSPlegacy(value);
                onProductUSPChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("USP", value)}
              // placeholder="输入品牌卖点(USP)的数据"
              placeholder={t("CPW001") + t("CPW015")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={uspOptions}
              value={productUSP}
              onChange={onUSPSelectChange}
              // label="USP"
              label={t("CPW015")}
              id="usp"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 4: 独特成分/材料/制作方式/科技 */}
            Step 4: {t("CPW022")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={ingredientOptions}
              value={productIngredientlegacy}
              onChange={(value) => {
                setProductIngredientlegacy(value);
                onProductIngredientChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Ingredient", value)}
              // placeholder="输入独特成分/材料/制作方式/科技"
              placeholder={t("CPW001") + t("CPW022")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={ingredientOptions}
              value={productIngredient}
              onChange={onIngredientSelectChange}
              // label="独特成分/材料/制作方式/科技"
              label={t("CPW022")}
              id="ingredient"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 5: 认证/奖项/可以增加信心的东西 */}
            Step 5: {t("CPW023")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={certOptions}
              value={productCertlegacy}
              onChange={(value) => {
                setProductCertlegacy(value);
                onProductCertChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Certification", value)}
              // placeholder="输入认证/奖项/可以增加信心的东西"
              placeholder={t("CPW001") + t("CPW023")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={certOptions}
              value={productCert}
              onChange={onCertSelectChange}
              // label="认证/奖项/可以增加信心的东西"
              label={t("CPW023")}
              id="cert"
            />
          )}
        </Box>

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let daId1;
              if (selectedLanguage === "Chinese") {
                daId1 = "da-1-cn";
              } else if (selectedLanguage === "English") {
                daId1 = "da-1-en";
              } else if (selectedLanguage === "Malay") {
                daId1 = "da-1-malay";
              }
              await handleSubmit(daId1, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Description (0.4)
          </Button>

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let daId2;
              if (selectedLanguage === "Chinese") {
                daId2 = "da-2-cn";
              } else if (selectedLanguage === "English") {
                daId2 = "da-2-en";
              } else if (selectedLanguage === "Malay") {
                daId2 = "da-2-malay";
              }
              await handleSubmit(daId2, e);
              legacyMode && handleSaveInputs();
            }}
          >
            Action (0.1)
          </Button>
        </Box>
      </Paper>

      {/* Dialog for users to edit saved replies */}
      <Dialog
        open={openDialog}
        onClose={handleDialogToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {/* Edit Saved Replies */}
          {t("CPW003")}
        </DialogTitle>
        <DialogContent>
          <SavedReplySection />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DescActItems;
