import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ReplySection from "../components/ReplySection";

function ReplyPage(props) {
  return (
    <>
      <Meta title="Reply SOP" />
      <ReplySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="回复攻略"
        subtitle="Reply SOP"
      />
    </>
  );
}

export default requireProUser(ReplyPage);
