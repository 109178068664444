import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  arrayUnion,
  arrayRemove,
  increment
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create and save new referral ID
export function createReferralIDs(uid, data) {
  const referralIDsRef = doc(db, "referralIDs", uid);
  const docSnap = getDoc(referralIDsRef);
  if (docSnap) {
    // If the document already exists, merge the new referral ID into the existing array
    return setDoc(referralIDsRef, { referralIDs: arrayUnion(data.referralID) }, { merge: true });
  } else {
    // If the document doesn't exist, create a new document with the referral ID as an array
    return setDoc(referralIDsRef, { referralIDs: [data.referralID] });
  }
}

// Update registered_referral_count if the new user was referred
export async function updateRegisteredReferralCount(referrer_id, referee_name) {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("referralID", "==", referrer_id));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const updatePromises = querySnapshot.docs.map((documentRef) => {
      // Update registered_referral_count by 1 and add referee_name to registered_list
      const userDocRef = doc(db, 'users', documentRef.id);
      return setDoc(userDocRef, {
        registered_referral_count: increment(1),
        registered_list: arrayUnion(referee_name)
      }, { merge: true });
    });

    await Promise.all(updatePromises);
  } else {
    console.log("No such document!");
  }
}

export async function insertReferralList(referrer_code, referee_code, referee_name, referee_email) {
  const usersCollectionRef = collection(db, "users");
  const q = query(usersCollectionRef, where("referralID", "==", referrer_code));
  const querySnapshot = await getDocs(q);

  const { name: referrer_name, email: referrer_email } = querySnapshot.docs[0].data();
  
  console.log("About to make fetch call");
  return fetch("http://localhost:5001/sql_insert_referral_lists", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        referrer_code,
        referrer_name,
        referrer_email,
        referee_code,
        referee_name,
        referee_email
    })
  })
  .then(async (response) => {
      if (response.ok) {
          const data = await response.json();
          const parsedData = data;
          return parsedData;
      }
  })
  .catch((err) => {
      console.error("Error in fetch call: ", err);
  })
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

// Subscribe to all users
export function useUsers() {
  return useQuery(
    ["users"],
    createQuery(() => collection(db, "users")),
    { enabled: true }
  );
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

let usedKeys = [];
// Retrieve user legacy saved input mode status
export const legacySavedOptionsMethod = async (authUserId) => {
  const userDocRef = doc(db, "users", authUserId);
  const userDocSnapshot = await getDoc(userDocRef);
  const userData = userDocSnapshot.data();
  const savedLegacyModeValue = userData.savedLegacyMode;
  return savedLegacyModeValue;
};

// Initialize the emptyObj and usedKeys
const emptyObj = Object.fromEntries(
  Array.from({ length: 15 }, (_, i) => [`key${i + 1}`, ""])
);
// Save user typed inputs into Firebase (Legacy Method)
export const saveInputs = async (authUserId, inputsArray) => {
  const userDocRef = doc(db, "users", authUserId);
  const docsCollectionRef = collection(userDocRef, "savedInputs");
  const nonEmptyInputs = inputsArray.filter(
    (input) => input.value && input.value.trim() !== ""
  );

  if (nonEmptyInputs.length === 0) {
    console.log("No inputs to save. Please enter a value in the input field.");
    return;
  }

  let failedKey;
  // Create or update the document for each input
  for (const input of nonEmptyInputs) {
    const { key, value } = input;
    const documentRef = doc(docsCollectionRef, key);
    const docSnapshot = await getDoc(documentRef);
    const objData = docSnapshot.exists() ? docSnapshot.data().obj || {} : emptyObj;

    // Check if the value already exists in the object data
    if (Object.values(objData).includes(value)) {
      console.log(`Value "${value}" already exists in document with key "${key}". Skipping.`);
      return key;
    }

    // Check if there's any available empty string in the object array
    const emptyKeys = Object.keys(objData).filter((key) => objData[key] === "");
    emptyKeys.sort(sortKeys);

    if (emptyKeys.length > 0) {
      try {
        const updateData = docSnapshot.exists() ? { [`obj.${emptyKeys[0]}`]: value } : { key, obj: { ...objData, [emptyKeys[0]]: value } };
        await (docSnapshot.exists() ? updateDoc : setDoc)(documentRef, updateData);
        console.log("OK");
        // console.log("Document created and object array saved successfully in Firebase!");
      } catch (error) {
        console.log("NOT OK");
        // console.error("Error creating document and saving object array to Firebase:", error);
      }
    } else {
      console.log("Save fail as it reaches the limit of the object array.");
      failedKey = key;
    }
  }
  return(failedKey);
};

// sort keys for saved inputs
function sortKeys(a, b) {
  const numericPartA = parseInt(a.replace(/\D/g, ''), 10);
  const numericPartB = parseInt(b.replace(/\D/g, ''), 10);
  return numericPartA - numericPartB;
}

// Retrieve user typed inputs from Firebase (Legacy and New Method)
export const retrieveInputs = async (authUserId) => {
  const userDocRef = doc(db, "users", authUserId);
  const collectionName = "savedInputs";
  const docsCollectionRef = collection(userDocRef, collectionName);

  return getDocs(docsCollectionRef)
    .then((querySnapshot) => {
      const inputs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return inputs;
    })
    .catch((error) => {
      console.error("Error retrieving inputs:", error);
    });
};

// Deletes input given the docId (Legacy Method)
export const resetInput = async (authUserId, inputId, inputValue) => {
  const userDocRef = doc(db, "users", authUserId);
  const docsCollectionRef = collection(userDocRef, "savedInputs");

  try {
    const documentRef = doc(docsCollectionRef, inputId);
    const docSnapshot = await getDoc(documentRef);

    if (docSnapshot.exists()) {
      const objData = docSnapshot.data().obj || {};

      const keyToReset = Object.keys(objData).find(
        (key) => objData[key] === inputValue
      );

      if (keyToReset) {
        await updateDoc(documentRef, {
          [`obj.${keyToReset}`]: "",
        });
        console.log("OK");
        // console.log("Input value reset successfully in Firebase!");
      } else {
        console.log("NOT OK");
        // console.log("Input value not found. Nothing to reset.");
      }
    } else {
      console.log("NOTHING");
      // console.log("Document not found. Nothing to reset.");
    }
  } catch (error) {
    console.error("Error resetting input in Firebase:", error);
    throw error;
  }
};

// Fetch user data for registered_list and paid_list
export async function getUserLists(uid) {
  const userDocRef = doc(db, "users", uid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    const registeredList = userData.registered_list || [];
    const paidList = userData.paid_list || [];
    return { registeredList, paidList };
  } else {
    console.log("No such document!");
    return { registeredList: [], paidList: [] };
  }
}


// Retrieve user typed inputs from Firebase (Legacy and New Method)
export const retrieveCMLabels = async (authUserId) => {
  const userDocRef = doc(db, "users", authUserId);
  const collectionName = "labels";
  const docsCollectionRef = collection(userDocRef, collectionName);

  return getDocs(docsCollectionRef)
    .then((querySnapshot) => {
      const inputs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return inputs;
    })
    .catch((error) => {
      console.error("Error retrieving labels:", error);
    });
};

export const saveCreatedLabel = async (authUserId, options) => {
  const labelsCollectionRef = collection(db, "users", authUserId, "labels");
  const labelsDocRef = doc(labelsCollectionRef, "labels");
  const docSnap = await getDoc(labelsDocRef);

  let currentObj = docSnap.exists() && Array.isArray(docSnap.data().obj) ? docSnap.data().obj : [];
  for (const option of options) {
    const labelValue = option.label;
    if (labelValue !== undefined && option.__isNew__) {
      currentObj.push(labelValue);
    } else {
      console.error("Error: labelValue is undefined or __isNew__ is false");
    }
  }

  if (docSnap.exists()) {
    return updateDoc(labelsDocRef, { obj: currentObj }, { merge: true });
  } else {
    return setDoc(labelsDocRef, { key: "labels", obj: currentObj });
  }
};

export const deleteLabel = async (authUserId, label) => {
  const labelsDocRef = doc(db, "users", authUserId, "labels", "labels");

  return updateDoc(labelsDocRef, {
    obj: arrayRemove(label),
  })
    .then(() => {
      console.log("Label successfully deleted!");
    })
    .catch((error) => {
      console.error("Error deleting label: ", error);
    });
};

export const saveCMS = async (authUserId, paragraph) => {
  const cmsCollectionRef = collection(db, "users", authUserId, "cms");
  const newDocRef = doc(cmsCollectionRef);

  const field = {
    ori_text: paragraph,
    edit_text: "",
    remarks: "",
    label: [],
  };

  return setDoc(newDocRef, { field });
};

export const retrieveCMS = async (authUserId) => {
  const cmsCollectionRef = collection(db, "users", authUserId, "cms");

  return getDocs(cmsCollectionRef)
    .then((querySnapshot) => {
      const cmsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return cmsData;
    })
    .catch((error) => {
      console.error("Error retrieving CMS data:", error);
    });
};

export const updateCMS = async (authUserId, docId, field) => {
  const cmsDocRef = doc(db, "users", authUserId, "cms", docId);

  return updateDoc(cmsDocRef, { field })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

export const deleteCMS = async (authUserId, docId) => {
  const cmsDocRef = doc(db, "users", authUserId, "cms", docId);

  return deleteDoc(cmsDocRef)
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.error("Error deleting document: ", error);
    });
};