import React from "react";
import Meta from "./../components/Meta";
import PaymentResultSection from "./../components/PaymentResultSection";

function PaymentResultPage(props) {
  return (
    <>
      <Meta title="Payment Result" />
      <PaymentResultSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Payment Result"
        subtitle="epsum lorem ipsum"
      />
    </>
  );
}

export default PaymentResultPage;
