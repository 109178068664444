import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./../functionalComponent/LegacyModeSwitch";
import { useTranslation } from "react-i18next";
import LanguageOutput from './../functionalComponent/LanguageOutput';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "750px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function CfTheoryItems(props) {
  const { handleSubmit } = props
  const { 
    onProductNameChange, 
    onProductUspChange,
    onProductEndResultChange,
    onCustomerBaseChange,
    onProductIngredientChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Malay");

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务(required) */}
            Step 1: {t("CPW006")}
          </Typography>
          <TextField 
            onChange={onProductNameChange}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            // placeholder="输入品牌名字和卖的产品/服务种类"
            placeholder={t("CPW001") + t("CPW006")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 2: 品牌卖点 (USP)(required) */}
            Step 2: {t("CPW015")}
          </Typography>
          <TextField 
            onChange={onProductUspChange}
            value={props.productUsp}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            // placeholder="输入USP"
            placeholder={t("CPW001") + t("CPW015")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 3: End Result(required) */}
            Step 3: {t("CPW025")}
          </Typography>
          <TextField 
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            // placeholder="输入End Result"
            placeholder={t("CPW001") + t("CPW025")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 4: 客户群(required) */}
            Step 4: {t("CPW066")}
          </Typography>
          <TextField 
            onChange={onCustomerBaseChange}
            value={props.customerBase}
            margin="dense"
            name="customerBase"
            fullWidth
            variant="outlined"
            // placeholder="输入客户群"
            placeholder={t("CPW001") + t("CPW066")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 5: 详细成分(required) */}
            Step 5: {t("CPW067")}
          </Typography>
          <TextField 
            onChange={onProductIngredientChange}
            value={props.productIngredient}
            margin="dense"
            name="productIngredient"
            fullWidth
            variant="outlined"
            // placeholder="输入详细成分"
            placeholder={t("CPW001") + t("CPW067")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let theory;
              if (selectedLanguage === "Chinese") {
                theory = "cf-theory-cn";
              } else if (selectedLanguage === "English") {
                theory = "cf-theory-en";
              } else if (selectedLanguage === "Malay") {
                theory = "cf-theory-malay";
              }
              await handleSubmit(theory, e);
              // legacyMode && handleSaveInputs();
            }}
          >
            {/* 原理解释 */}
            {t("CPW068")}
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default CfTheoryItems;
