import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toaster, toast } from 'react-hot-toast';

function CopyButton({ content }) {
  // const handleCopy = () => {
  //   navigator.clipboard.writeText(content).then(
  //     () => {
  //       alert('Text copied to clipboard!');
  //     },
  //     (err) => {
  //       console.error('Error copying text:', err);
  //     },
  //   );
  // };

  return (
    <>
    <CopyToClipboard
      text={content}
      onCopy={() => toast.success("Successfully copied!")}
      options={{ format: "text/plain" }}
    >
      <IconButton color="default" size="small">
        <FileCopyIcon />
      </IconButton>
    </CopyToClipboard>
    </>
  );
}

export default CopyButton;
