import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../style/container.css'
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import CfIngredientsItems from "./CfIngredientsItems";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "685px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function CfIngredientsSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [productName, setProductName] = useState("");
  const [productIngredient, setProductIngredient] = useState("")
  const [productEndResult, setProductEndResult] = useState("");
  const [confidence1, setConfidence1] = useState("")
  const [confidence2, setConfidence2] = useState("")
  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductIngredientChange = (event) => {
    setProductIngredient(event.target.value);
  };

  const handleProductEndResultChange = (event) => {
    setProductEndResult(event.target.value);
  };

  const handleCondifence1Change = (event) => {
    setConfidence1(event.target.value);
  };

  const handleCondifence2Change = (event) => {
    setConfidence2(event.target.value);
  };
  let query 

  async function handleSubmit( id, e, lastInput = false, speechRes = false, credit = 0.4 ) {
    e && e.preventDefault();
    // const data = new FormData(formRef.current);

    // if (id === 'cf-ingredients') {
    //   query = `产品名字和种类是${productName}, 独特成分/技术是${productUsp}，功效（最终结果）是${productEndResult},
    //   信心事项1是${confidence1}, 信心事项2是${confidence2}`
    // }

    query = `Product info: \n
    The product name or type is: ${productName} \n
    The unique ingredient or technology is: ${productIngredient} \n
    The efficacy (end result) is: ${productEndResult} \n
    First confidence boosting recognitions are: ${confidence1} \n
    Second confidence boosting recognitions are: ${confidence2}`;
    
    let payload = { selfDefinedValue, query, id, credit }

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <CfIngredientsItems 
              handleSubmit={handleSubmit} 
              onProductNameChange={handleProductNameChange}
              onProductIngredientChange={handleProductIngredientChange}
              onProductEndResultChange={handleProductEndResultChange}
              onConfidence1Change={handleCondifence1Change}
              onConfidence2Change={handleCondifence2Change}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </Section>
  );
}

export default CfIngredientsSection;