import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import UspSection from "../../components/malay/UspSection";

function UspPage(props) {
  return (
    <>
      <Meta title="USP Generator (Malay)" />
      <UspSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="独特卖点 (马来文)"
        subtitle="马来文 USP"
      />
    </>
  );
}

export default requireProUser(UspPage);
