import React from "react";
import Meta from "../components/Meta";
import HeroSection from "../components/HeroSection";
import TeamBiosSection from "../components/TeamBiosSection";

function AccessDeniedPage(props) {
  return (
    <>
      <Meta title="Access Denied" description="Denied Access" />
      <HeroSection
        bgColor="primary"
        size="large"
        bgImage="https://images.unsplash.com/photo-1646217120680-735b95df956b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        bgImageOpacity={0.3}
        title="ClOSED BETA"
        subtitle="Please contact Zheng Hong or Callum to request for access. "
      />
    </>
  );
}

export default AccessDeniedPage;
