import React from "react";
import Meta from "../components/Meta";
import ReplaceHistorySection from "../components/ReplaceHistorySection";
import { requireAuth } from "../util/auth";

function ReplaceHistoryPage(props) {
  return (
    <>
      <Meta title="User History" />
      <ReplaceHistorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Replace History"
        subtitle="产品图生成历史"
      />
    </>
  );
}

export default requireAuth(ReplaceHistoryPage);
