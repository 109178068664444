import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme  } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Grid  } from "@material-ui/core";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import PricingImage from "../assets/pricing.jpg"

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  media : {
    width: '100%',
    height: 'auto',
  }
}));

function PricingSection(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6}>
            <SectionHeader title={props.title} subtitle={props.subtitle} size={2} textAlign={"center"} />
          </Grid>
          <Grid item xs={12} md={10}>
            <img
              className={classes.media}
              src={PricingImage}
              alt="CTG AI Pricing List"
            />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingSection;
