import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import SimplifierItems from "./SimplifierItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { chatContext } from "../context/ChatContext"
import '../style/container.css'
import generateUniqueId from "../util/generateuniqueid";
import typeText from "../util/typetext";
import loader from "../util/loader";
import chatStripe from "../util/chatStripe";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "695px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function BannedSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    loadingInterval,
    typingInterval,
    selfDefinedValue,
    setSelfDefinedValue,
  } = useContext(chatContext);

  const formRef = useRef(null);
  const [lastInputValue, setLastInputValue] = useState("");

  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [industry, setIndustry] = useState("product");
  const [copywriting, setCopywriting] = useState("");
  const [tone, setTone] = useState("");
  const [cwStyle, setCwStyle] = useState("");


  const checkBannedWords = (inputText) => {
    const bannedWords = ['是否', '彻底'];
    
    const foundBannedWords = bannedWords.filter((bannedWord) => {
      return inputText.indexOf(bannedWord) !== -1;
    });
  
    return foundBannedWords;
  };

  const handleCopywritingChange = (event) => {
    setCopywriting(event.target.value);
  };
  

  // bot's chatStripe
  let uniqueId;
  
  async function handleSubmit(id, e, lastInput = false, speechRes = false) {
    e && e.preventDefault();
    
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    let uniqueId = generateUniqueId();
    setLastUniqueId(uniqueId); 

    // chat_container_ref.current.innerHTML += chatStripe(true, " ", uniqueId);
    chat_container_ref.current.innerHTML = chatStripe(true, " ", uniqueId) + chat_container_ref.current.innerHTML;

    chat_container_ref.current.scrollTop =
      chat_container_ref.current.scrollHeight;




    // Check for banned words
    const foundBannedWords = checkBannedWords(copywriting);
  
    if (foundBannedWords.length > 0) {
      setError(true);
      setLoading(false);
      // Show the banned words found in the input
      const loadingDiv = document.getElementById(
        lastInput ? lastUniqueId : uniqueId
      );
      loadingDiv.innerHTML =
        "The following banned words were found: " +
        foundBannedWords.join(", ") +
        "\nPlease remove them and try again.";

      return;
    }
  
    // Display a message that no banned words were found
    setError(false);
    setLoading(false);
    const loadingDiv = document.getElementById(
      lastInput ? lastUniqueId : uniqueId
    );
    loadingDiv.innerHTML = "No banned words found in the input. Your text is safe to use!";
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <SimplifierItems 
              handleSubmit={handleSubmit} 
              copywriting={copywriting}
              onCopywritingChange={handleCopywritingChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    chatRef={chat_container_ref}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
      </Container>
    </Section>
  );
}

export default BannedSection;



function OutputContainer({ typing, load, error, chatRef, id }) {
  const classes = useStyles({ typing, load, error });

  return (
    <div ref={chatRef} id={id}>
      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}