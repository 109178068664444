import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
} from "@material-ui/core";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  doc,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { firebaseApp } from "../util/firebase";
import { Toaster, toast } from "react-hot-toast";
import { useRefresh } from "../context/RefreshContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    boxShadow: theme.shadows[4],
  },
  input: {
    width: "100%",
  },
}));

const SavedReplySection = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const db = getFirestore(firebaseApp);

  // const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownOptionsKey, setDropdownOptionsKey] = useState([]);
  const [dropdownOptionsName, setDropdownOptionsName] = useState([]);
  const [selectedFieldTarget, setSelectedFieldTarget] = useState("");
  const [selectedFieldTargetKey, setSelectedFieldTargetKey] = useState("");

  const [textFieldAppendStatus, settextFieldAppendStatus] = useState(false);
  const [obj, setObj] = useState(
    Object.fromEntries(
      Array.from({ length: 20 }, (_, i) => ["key" + (i + 1), ""])
    )
  );

  const emptyObj = Object.fromEntries(
    Array.from({ length: 20 }, (_, i) => ["key" + (i + 1), ""])
  );

  const { refresh, setRefresh } = useRefresh();

  useEffect(() => {
    // Fetch the dropdown options from Firebase on component mount
    const fetchDropdownOptions = async () => {
      const q = query(collectionGroup(db, "saved_options"));
      const snapshot = await getDocs(q);
      const optionsName = snapshot.docs.map((doc) => doc.data().name);
      const optionsKey = snapshot.docs.map((doc) => doc.data().key);
      setDropdownOptionsName(optionsName);
      setDropdownOptionsKey(optionsKey);
      if (optionsKey.length > 0) {
        setSelectedFieldTarget(optionsName[0]);
        setSelectedFieldTargetKey(optionsKey[0]);
      }
    };
    fetchDropdownOptions();
  }, []);

  const handleOptionChange = async (event, newValue) => {
    const selectedTarget = newValue;
    const selectedIndex = dropdownOptionsName.indexOf(selectedTarget);
    const selectedTargetKey = dropdownOptionsKey[selectedIndex];

    setSelectedFieldTarget(selectedTarget);
    setSelectedFieldTargetKey(selectedTargetKey);
    console.log(selectedFieldTarget);
    
    const userDocRef = collection(db, `users/${auth.user.uid}/savedInputs`);
    const docsCollectionRef = query(
      userDocRef,
      where("key", "==", selectedTargetKey)
    );
    const snapshot = await getDocs(docsCollectionRef);
    const textFieldAppend = snapshot.docs.map((doc) => doc.data().obj);

    const appendedValues = textFieldAppend[0];
    if (appendedValues == undefined) {
      setObj(emptyObj);
    } else {
      const updatedObj = { ...obj, ...appendedValues };
      setObj(updatedObj);
    }

    settextFieldAppendStatus(true);
  };

  const handleSave = async () => {
    try {
      const db = getFirestore(firebaseApp);

      // Get the data from the text fields
      const data = Object.entries(obj).reduce(
        (acc, [key, value]) => {
          acc.obj[key] = value;
          return acc;
        },
        { key: selectedFieldTargetKey, obj: {} }
      );
      // Save the data to Firebase
      await setDoc(
        doc(db, `users/${auth.user.uid}/savedInputs`, selectedFieldTargetKey),
        data
      );

      // Show a success message or perform any other necessary actions
      setRefresh(!refresh);
      console.log("Data saved successfully!");
    } catch (error) {
      // Handle any errors that occur during the saving process
      console.error("Error saving data:", error);
    }
  };

  // Render the component
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Toaster position="bottom-right" reverseOrder={false} />
        {/* <SectionHeader
				title={props.title}
				subtitle={props.subtitle}
				size={4}
				textalign="center"
				/> */}

        <Grid container={true} spacing={4} justifyContent="center">

          {/* Left Grid */}
          <Grid item={true} xs={12} md={4}>
            <Card>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Saved Reply</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: "100%",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  {/* Section */}

                  {dropdownOptionsName.length === 0 ? (
                    <CircularProgress /> // Loading spinner when dropdown options are not populated yet
                  ) : (
                    <>
                      <Tabs
                        value={
                          selectedFieldTarget === ""
                            ? "Product Name / Brand"
                            : selectedFieldTarget
                        }
                        onChange={handleOptionChange}
                        orientation="vertical"
                        variant="scrollable"
                        TabIndicatorProps={{
                          style: { backgroundColor: "#3f51b5" },
                        }}
                      >
                        {dropdownOptionsName.map((option) => (
                          <Tab key={option} value={option} label={option} />
                        ))}
                      </Tabs>

                      {/* Save Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "40%", marginTop: "2rem" }}
                        onClick={() => {
                          toast.success("Successfully saved!");
                          handleSave();
                        }}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Grid */}
          <Grid item xs={12} md={7}>
            <Card>
              {textFieldAppendStatus && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                  >
                    <Typography variant="h5">{selectedFieldTarget}</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "25%", marginLeft: "2rem" }}
                        onClick={() => {
                          if (window.confirm("Are you sure?")) {
                            setObj(emptyObj);
                            toast.success("Successfully cleared all!");
                          }
                        }}
                      >
                        Clear All
                      </Button>
                  </Box>
                  <Divider />
                  <CardContent className={classes.cardContent}>
                    <Box>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {Object.entries(obj).map(([key, value], index) => (
                              <TableRow key={key}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    padding: "0",
                                    margin: "0",
                                    textAlign: "center",
                                  }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <TextField
                                      value={value}
                                      className={classes.input}
                                      onChange={(event) => {
                                        const updatedObj = {
                                          ...obj,
                                          [key]: event.target.value,
                                        };
                                        setObj(updatedObj);
                                      }}
                                    />
                                    {/* Clear Button */}
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{ width: "20%" }}
                                      onClick={() => {
                                        const updatedObj = {
                                          ...obj,
                                          [key]: '',
                                        };
                                        setObj(updatedObj);
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* Save Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ 
                          width: "20%", 
                          marginTop: "2rem",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block", 
                        }}
                        onClick={() => {
                          toast.success("Successfully saved!");
                          handleSave();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>

        </Grid>
      </Container>
    </Section>
  );
};

export default SavedReplySection;
