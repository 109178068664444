import React from "react";
import ReactDom from "react-dom";
import './i18n.ts';
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import ChatContext from "./context/ChatContext";
ReactDom.render(<ChatContext><App /></ChatContext>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
