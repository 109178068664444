import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "../util/auth";
import { useRefresh } from "../context/RefreshContext";
import SavedReplySection from "./SavedReplySection";
import { saveInputs, retrieveInputs, resetInput, legacySavedOptionsMethod } from "../util/db";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./functionalComponent/LegacyModeSwitch";
import { useTranslation } from "react-i18next";
import LanguageOutput from './functionalComponent/LanguageOutput';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "950px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
}));

function ContentAngleItems(props) {
  const { handleSubmit } = props;
  const {
    onProductNameChange,
    onProductUSPChange,
    age,
    onAgeChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { refresh } = useRefresh();
  const { t, i18n } = useTranslation();
  // default input declaration
  const [productName, setProductName] = useState([]);
  const [productUSP, setProductUSP] = useState([]);
  // default input legacy declaration
  const [legacyMode, setLegacyMode] = useState(false);
  const [productNamelegacy, setProductNamelegacy] = useState([]);
  const [productUSPlegacy, setProductUSPlegacy] = useState([]);
  const [savedInputs, setSavedInputs] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [uspOptions, setUSPOptions] = useState([]);
  // savedInputs dialog
  const [openDialog, setOpenDialog] = useState(false);
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Chinese");

  // Handles the clearing of inputs
  const clearInputs = () => {
    setProductName([]);
    setProductUSP([]);
  };

  // Handles the legacy mode change
  const handleLegacyModeChange = (newValue) => {
    setLegacyMode(newValue);
  };

  // Handles the dialog toggle for savedInputs
  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  // Save inputs from the text fields (Only in Legacy Mode)
  const handleSaveInputs = () => {
    const inputsArray = [
      { key: "product_name", value: productNamelegacy },
      { key: "usp", value: productUSPlegacy },
    ];
    // Check if any of the values are undefined
    const hasUndefinedValues = inputsArray.some(
      (input) => input.value === undefined
    );
    if (hasUndefinedValues) {
      console.error("Some input values are undefined");
      return;
    }
    // Filter out inputs that already exist in the retrieved inputs
    const uniqueInputs = inputsArray.filter(
      (newInput) =>
        !savedInputs.some(
          (savedInput) =>
            savedInput.key === newInput.key &&
            savedInput.value === newInput.value
        )
    );
    // If all inputs are duplicates, stop execution
    if (uniqueInputs.length === 0) {
      console.log("No new inputs to save");
      return;
    }
    // Execute savedInputs
    saveInputs(auth.user.uid, uniqueInputs).then((savedKeys) => {
      handleRetrieveInputs(); // Retrieve the inputs again after saving
      if (savedKeys == undefined) {
        toast.success("Input has been saved");
      }
    });
  };

  // Delete inputs from the dropdown (Only in Legacy Mode)
  const handleDeleteInput = (inputId, inputValue) => {
    resetInput(auth.user.uid, inputId, inputValue)
      .then(() => {
        handleRetrieveInputs();
      })
      .catch((error) => {
        console.error("Error resetting input:", error);
      });
  };

  // Handles the retrieval of savedInputs
  const handleRetrieveInputs = async () => {
    try {
      const inputs = await retrieveInputs(auth.user.uid);
      const formattedInputs = inputs.map((input, index) => ({
        ...input,
      }));
      setSavedInputs(formattedInputs);
    } catch (error) {
      console.error("Error retrieving inputs:", error);
    }
  };

  const fetchLegacyMode = async () => {
    const legacyModeValue = await legacySavedOptionsMethod(auth.user.uid);
    setLegacyMode(legacyModeValue);
    props.onLegacyModeChange(legacyModeValue);
  };

  // useEffect for retrieve legacy mode status and set savedInputs 
  useEffect(async () => {
    fetchLegacyMode();
    handleRetrieveInputs();
  }, []);

  // useEffect for retrieve and set savedInputs after refresh
  useEffect(() => {
    handleRetrieveInputs();
  }, [refresh]);

  // useEffect to set savedInputs into options
  useEffect(() => {
    setNameOptions(
      savedInputs
        .filter((input) => input.key === "product_name")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );

    setUSPOptions(
      savedInputs
        .filter((input) => input.key === "usp")
        .map((input) => {
          const valuesArray = Object.values(input.obj).sort();
          return valuesArray.map((value, index) => ({
            value: value,
          }));
        })
        .flat()
    );
  }, [savedInputs]);

  // Only when legacy mode is false
  const onNameSelectChange = (event) => {
    setProductName(event.target.value);
    const nameValues = event.target.value.map((item) => item.value).join(" ");
    onProductNameChange(nameValues);
  };

  const onUSPSelectChange = (event) => {
    setProductUSP(event.target.value);
    const uspValues = event.target.value.map((item) => item.value).join(" ");
    onProductUSPChange(uspValues);
  };

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Toaster position="bottom-right" reverseOrder={false} />
          <Typography variant="h5">Generator</Typography>
          <Box>
            {!legacyMode && (
              <>
                <Tooltip title="Clear Inputs">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={clearInputs}
                    style={{ marginLeft: "1rem" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleDialogToggle}
                    style={{ marginLeft: "1rem" }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <LegacyModeSwitch
              auth={auth}
              initialLegacyMode={legacyMode}
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务 */}
            Step 1: {t("CPW006")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={nameOptions}
              value={productNamelegacy}
              onChange={(value) => {
                setProductNamelegacy(value);
                onProductNameChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("Product Name", value)}
              // placeholder="输入品牌名字和卖的产品/服务"
              placeholder={t("CPW001") + t("CPW006")}
              minRowsValue={2}
              maxRowsValue={2}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={nameOptions}
              value={productName}
              onChange={onNameSelectChange}
              // label="Product Name"
              label={t("CPW006")}
              id="product_name"
            />
          )}
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 2: 信心事项 */}
            Step 2: {t("CPW056")}
          </Typography>
          {legacyMode ? ( // Render AutoComplete when legacyMode is true
            <TruncateAutocomplete
              options={uspOptions}
              value={productUSPlegacy}
              onChange={(value) => {
                setProductUSPlegacy(value);
                onProductUSPChange({ target: { value } });
              }}
              onDelete={(value) => handleDeleteInput("USP", value)}
              // placeholder="输入品牌卖点(USP)的数据"
              placeholder={t("CPW001") + t("CPW056")}
              minRowsValue={3}
              maxRowsValue={3}
            />
          ) : (
            // Render Select when legacyMode is false
            <TruncateSelect
              options={uspOptions}
              value={productUSP}
              onChange={onUSPSelectChange}
              // label="USP"
              label={t("CPW015")}
              id="usp"
            />
          )}
        </Box>

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let caId;
              console.log(selectedLanguage)
              if (selectedLanguage === "Chinese") {
                caId = "cf-1-cn";
              } else if (selectedLanguage === "English") {
                caId = "cf-1-en";
              } else if (selectedLanguage === "Malay") {
                caId = "cf-1-malay";
              }
              await handleSubmit(caId, e);
              legacyMode && handleSaveInputs();
            }}
          >
            {/* 信心回复 */}
            {t("CPW104")}
          </Button>
        </Box>
      </Paper>

      {/* Dialog for users to edit saved replies */}
      <Dialog
        open={openDialog}
        onClose={handleDialogToggle}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {/* Edit Saved Replies */}
          {t("CPW003")}
        </DialogTitle>
        <DialogContent>
          <SavedReplySection />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContentAngleItems;
