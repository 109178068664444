import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfXinXinSection from "../../components/confidence/CfXinXinSection";

function CfXinXinPage(props) {
  return (
    <>
      <Meta title="Confidence Reply" />
      <CfXinXinSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply"
        subtitle="信心回复"
      />
    </>
  );
}

export default requireProUser(CfXinXinPage);
