import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import VideoAccordion from "./VideoAccordion";
import ReactPlayer from "react-player";
const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function TutorialSection(props) {
  const classes = useStyles();

  const videos = [
    { title: '1 - Introduction', id: 'NvYsjD_1lP8' },
    { title: '2 - Attention & Demand', id: '9JmA-S1fbP0' },
    { title: '3 - Description & Action', id: 'hbsLAlwAACI' },
    { title: '4 - 文案转白话 Simplifier', id: 'pXAiXMv_Omg' },
    { title: '5 - 独特卖点 USP', id: '6Ze9SqmHjuU' },
    { title: '6 - SWOT & Target Audience', id: 'k54y6DrcG9I' },
    { title: '7 - Reply SOP', id: '0y6CDMdpB9g' },
    { title: '8 - Confidence Reply', id: 'qp5izZVFNnQ' },
    { title: '9 - 马来文案和英语文案', id: '0OBe_5pJMCw' },
    { title: '10 - 图片背景生成', id: 'qnvD3vTQ7-k' },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={2}
              textAlign="center"
            />
          </Grid>
          <Grid item xs={12}>
            <VideoAccordion videos={videos} />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default TutorialSection;
