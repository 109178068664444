import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
  featuresContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(8, 3),
  },
  feature: {
    margin: theme.spacing(2, 0),
    border: '1px solid red'
  },
  featureTitle: {
    fontWeight: 700,
    textAlign: 'left',
  },
  featureDescription: {
    maxWidth: '280px',
    textAlign: 'left',
  },
  featureImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  descContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const featureVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function FeaturesSection() {
  const classes = useStyles();

  const features = [
    {
      title: 'Feature 1',
      description: 'Description for Feature 1 l.',
      image: 'https://via.placeholder.com/200',
    },
    {
      title: 'Feature 2',
      description: 'Description for Feature 2.',
      image: 'https://via.placeholder.com/200',
    },
    {
      title: 'Feature 3',
      description: 'Description for Feature 3.',
      image: 'https://via.placeholder.com/200',
    },
  ];

  return (
    <Container className={classes.featuresContainer}>
      <Grid container spacing={0}>
        {features.map((feature, index) => (
          <Grid item xs={12} key={index}>
            <motion.div
              className={classes.feature}
              variants={featureVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <Grid container>
                <Grid className={classes.imageContainer} item xs={12} sm={6}>
                  <img src={feature.image} alt={feature.title} className={classes.featureImage} />
                </Grid>
                <Grid className={classes.descContainer} item xs={12} sm={6}>
                  <Typography variant="h6" component="h3" className={classes.featureTitle}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" className={classes.featureDescription}>
                    {feature.description}
                  </Typography>
                </Grid>
              </Grid>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default FeaturesSection;
