import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ImageSection from "../components/image/ImageSection";

function ImgPage(props) {
  return (
    <>
      <Meta title="Image" />
      <ImageSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="图片生成"
        subtitle="Image"
      />
    </>
  );
}

export default requireProUser(ImgPage);
