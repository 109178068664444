import React from "react";
import Meta from "../components/Meta";
import { requireProUser, requireAdmin } from "../util/auth";
import ReplaceSection from "../components/ReplaceSection";

function ReplacePage(props) {
  return (
    <>
      <Meta title="Replace" />
      <ReplaceSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Replace"
        subtitle=""
      />
    </>
  );
}

export default requireProUser(ReplacePage);
