import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import SwotSection from "../../components/malay/SwotSection";

function SwotPage(props) {
  return (
    <>
      <Meta title="SWOT Analysis Report Generator (Malay)" />
      <SwotSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="强弱危机分析 (马来文)"
        subtitle="马来文 SWOT"
      />
    </>
  );
}

export default requireProUser(SwotPage);
