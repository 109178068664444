import React from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import TesterSection from "../components/TesterSection";

function TestPage(props) {
  return (
    <>
      <Meta title="Tester Generator" />
      <TesterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Test"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(TestPage);
