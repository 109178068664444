import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import { useTranslation } from "react-i18next";

function AuthSection(props) {
  const { t, i18n } = useTranslation();

  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      // title: "Get yourself an account",
      title: t("SIU001"),
      // Button text
      // buttonAction: "Sign up",
      buttonAction: t("SIU010"),
      // Footer text and links
      showFooter: true,
      // signinText: "Already have an account?",
      signinText: t("SIU004"),
      // signinAction: "Sign in",
      signinAction: t("SIU009"),
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      // privacyPolicyPath: "/legal/privacy-policy",
    },
    signin: {
      // title: "Welcome back",
      title: t("SIU011"),
      // buttonAction: "Sign in",
      buttonAction: t("SIU009"),
      showFooter: true,
      // signupAction: "Create an account",
      signupAction: t("SIU014"),
      signupPath: "/auth/signup",
      // forgotPassAction: "Forgot Password?",
      // forgotPassAction: t("SIU015"),
      // forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      // title: "Get a new password",
      title: t("SIU016"),
      // buttonAction: "Reset password",
      buttonAction: t("SIU017"),
      showFooter: true,
      // signinText: "Remember it after all?",
      signinText: t("SIU018"),
      // signinAction: "Sign in",
      signinAction: t("SIU009"),
      signinPath: "/auth/signin",
    },
    changepass: {
      // title: "Choose a new password",
      title: t("SIU019"),
      // buttonAction: "Change password",
      buttonAction: t("SIU020"),
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="xs">
        <SectionHeader
          title={options.title}
          subtitle=""
          size={4}
          textAlign="center"
        />
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </Container>
    </Section>
  );
}

export default AuthSection;
