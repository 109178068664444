import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Newsletter from "./Newsletter";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    lineHeight: "80px",
    // letterSpacing: "-2px",
  },
  subtitle: {
    // marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 6), // Add padding to the button, top/bottom padding: 2 * 8px, left/right padding: 6 * 8px
    fontSize: "1.25rem", // Increase the font size
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark, // Change the hover background color
    },
  },
}));
function NewsletterSection(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <SectionHeader title={props.title} subtitle={props.subtitle} size={4} />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color={props.buttonColor} size="large" type="submit">
              Feedback Form
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default NewsletterSection;
