import React from "react";
import Meta from "../../components/Meta";

import ColorPalette from "../../components/colorpalette/ColorPalette";

function ColorPalettePage(props) {
  return (
    <>
      <Meta title="Color Palette" description="Generator" />
      <ColorPalette
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Color Palette"
        subtitle="Generator"
      />
    </>
  );
}

export default ColorPalettePage;
