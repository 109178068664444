import React, { useEffect, useState } from "react";
import ColorThief from "colorthief";
import {
  makeStyles,
  Tooltip,
  Box,
  Card,
  Typography,
  Button,
  CircularProgress,
  Container,
} from "@material-ui/core";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1em",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5em",
    },
  },
  imagePreview: {
    width: "200px",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
  colorBox: {
    width: "100px",
    height: "200px",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
      height: "100px"
    },
  },
  tooltip: {
    backgroundColor: "black",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
  colorContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

const ColorPalette = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [dominantColor, setDominantColor] = useState("#FFFFFF");
  const [palette, setPalette] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const rgbToHex = (rgb) => {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex.toUpperCase();
  };

  const fullColorHex = (r, g, b) => {
    const red = rgbToHex(r);
    const green = rgbToHex(g);
    const blue = rgbToHex(b);
    return `#${red}${green}${blue}`;
  };

  const getLuminance = (r, g, b) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  };

  const handleBoxClick = (color) => {
    navigator.clipboard.writeText(color);
    toast.success("Color code is copied!");
  };

  useEffect(() => {
    if (imageFile) {
      setLoading(true);
      const colorThief = new ColorThief();
      const img = new Image();

      img.onload = function () {
        const color = colorThief.getColor(img);
        const paletteColors = colorThief.getPalette(img, 10);
        setDominantColor(`rgb(${color[0]}, ${color[1]}, ${color[2]})`);
        setPalette(paletteColors.map((color) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`));
        setLoading(false);
        setImageUploaded(true);
      };

      img.src = URL.createObjectURL(imageFile);
    }
  }, [imageFile]);

  const handleImageUpload = (event) => {
    setImageFile(event.target.files[0]);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Toaster position="top-right" reverseOrder={false} />
        <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />
        <Card className={classes.root}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={3}
          >
            {!imageUploaded && (
              <>
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  {/* 上传你的图片 */}
                  {t("CPW047")}
                </Typography>
                <Button variant="contained" color="primary" component="label">
                  {/* Upload */}
                  {t("CPW048")}
                  <input type="file" hidden accept="image/*" onChange={handleImageUpload} />
                </Button>
              </>
            )}
            {isLoading ? (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            ) : imageFile ? (
              <img
                className={classes.imagePreview}
                src={URL.createObjectURL(imageFile)}
                alt="Preview"
              />
            ) : null}
            {imageUploaded && (
              <>
                <Button
                  variant="contained"
                  component="label"
                  htmlFor="raised-button-file"
                  style={{ marginTop: "1rem" }}
                >
                  {/* Change Image */}
                  {t("CPW049")}
                  <input
                    accept="image/*"
                    hidden
                    id="raised-button-file"
                    type="file"
                    onChange={handleImageUpload}
                  />
                </Button>
                {/* <Typography variant="h6" style={{ marginTop: "1rem" }}>
                  主要色系:
                </Typography>
                <Tooltip
                  title={fullColorHex(...dominantColor.match(/\d+/g))}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Box className={classes.colorBox} bgcolor={dominantColor} />
                </Tooltip> */}
                <Typography variant="h6" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  适合的色系:
                  {t("CPW050")}
                </Typography>
                <Box className={classes.colorContainer}>
                  {palette.map((color, i) => {
                    const hexColor = fullColorHex(...color.match(/\d+/g));
                    const rgbColor = color.match(/\d+/g).map((n) => parseInt(n));
                    const luminance = getLuminance(...rgbColor);
                    const textColor = luminance > 0.5 ? "black" : "white";

                    return (
                      <Box
                        key={i}
                        className={classes.colorBox}
                        bgcolor={color}
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-start"
                        padding={3}
                        onClick={() => {
                          handleBoxClick(hexColor);
                          console.log(palette);
                        }}
                      >
                        <Typography style={{ color: textColor, fontWeight: "bold" }}>
                          {hexColor}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Container>
    </Section>
  );
};

export default ColorPalette;
