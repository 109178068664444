import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ContentAngleSection from "../components/ContentAngleSection";

function CAGeneratorPage(props) {
  return (
    <>
      <Meta title="Content Angle Generator" />
      <ContentAngleSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Content Angle"
        subtitle="销售角度生成"
      />
    </>
  );
}

export default requireProUser(CAGeneratorPage);
