import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ReelsSection from "../components/reels/ReelsSection";

function ReelsPage(props) {
  return (
    <>
      <Meta title="Reels Script Generator" />
      <ReelsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Reels"
        subtitle="剧本生成"
      />
    </>
  );
}

export default requireProUser(ReelsPage);