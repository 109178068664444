import React from "react";
import Meta from "../components/Meta";

import CfMenu from "../components/eng/CfMenuPage";

function CfMenuPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (English)" description="Menu" />
      <CfMenu 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (English)"
        subtitle="Menu"/>
    </>
  );
}

export default CfMenuPage;
