import React, { useState } from "react";
import {
  FormControlLabel,
  Switch,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "500px",
  },
  select: {
    marginTop: "0.5rem",
  },
}));

function LegacyModeSwitch({ initialLegacyMode, auth, onLegacyModeChange }) {
  const [legacyMode, setLegacyMode] = useState(initialLegacyMode);
  const db = getFirestore(firebaseApp);

  const handleLegacyModeSwitch = async (event) => {
    const newValue = event.target.checked;
    setLegacyMode(newValue);
    onLegacyModeChange(newValue);
    try {
      const docRef = doc(db, "users", auth.user.uid);
      await updateDoc(docRef, { savedLegacyMode: newValue });
    } catch (error) {
      console.error("Error updating legacy mode:", error);
    }
  };

  return (
    <Tooltip title="Saved Reply Mode">
      <FormControlLabel
        control={
          <Switch
            name="legacyModeSwitch"
            color="primary"
            checked={initialLegacyMode}
            onChange={handleLegacyModeSwitch}
          />
        }
        // label="Mode"
        labelPlacement="start"
      />
    </Tooltip>
  );
}

function TruncateSelect({ options, value, onChange, label, id }) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.select}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((option) => (
              <span key={option.id}>{option.value} </span>
            ))}
          </div>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem disabled value="">
          选择{label}
        </MenuItem>
        {options
          .filter((option) => option.value !== "")
          .map((option, index) => (
            <MenuItem
              // className={classes.truncate}
              key={option.id}
              value={option}
              title={option.value}
            >
              <Checkbox checked={value.includes(option)} color="primary" />
              <div className={classes.truncate}>
                {index + 1}. {option.value}
              </div>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

function TruncateAutocomplete({ options, value, onChange, onDelete, placeholder, minRowsValue, maxRowsValue }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
    <Autocomplete
      freeSolo
      options={options.filter((option) => option.value !== "")}
      getOptionLabel={(option) => option.value}
      onInputChange={(event, newInputValue) => {
        if (event.type !== 'blur') {
          onChange(newInputValue);
        }
      }}
      renderOption={(option, { selected }) => (
        <div
          key={option.value}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {option.value}
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(option.value);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          multiline
          minRows={minRowsValue}
          maxRows={maxRowsValue}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
}

export { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete };