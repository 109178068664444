import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "850px",
  },
  featured: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function PepcProblemItems(props) {
  const { handleSubmit } = props;
  const {
    onProductNameChange,
    onProductUspChange,
    onProductEndResultChange,
    onTimePeriod1Change,
    onTimePeriod2Change,
    onPainPoint1Change,
    onPainPoint2Change,
    timePeriod1,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const { data: items, status: itemsStatus, error: itemsError } = useItemsByOwner(auth.user.uid);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive && (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, {
        featured: !item.featured,
      });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
          <Typography variant="h5">Generator</Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务(required) */}
            Step 1: {t("CPW006")}
          </Typography>
          <TextField 
            onChange={onProductNameChange}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            // placeholder="输入品牌名字和卖的产品/服务种类"
            placeholder={t("CPW001") + t("CPW006")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 2: 品牌卖点 (USP)(required) */}
            Step 2: {t("CPW015")}
          </Typography>
          <TextField 
            onChange={onProductUspChange}
            value={props.productUsp}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            // placeholder="输入USP"
            placeholder={t("CPW001") + t("CPW015")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 3: 种类(required) */}
            Step 3: {t("CPW073")}
          </Typography>
          <FormControl>
            <InputLabel id="select-label" margin="dense" disableAnimation={true}>
              {/* 种类 */}
              {t("CPW073")}
            </InputLabel>
            <Select
              labelId="select-label"
              id="select"
              label="timePeriod1"
              value={timePeriod1}
              onChange={onTimePeriod1Change}
              required
            >
              {/* <MenuItem value={"针对痛点"}>针对痛点</MenuItem> */}
              <MenuItem value={"针对痛点"}>{t("CPW074")}</MenuItem>
              {/* <MenuItem value={"颠覆认知"}>颠覆认知</MenuItem> */}
              <MenuItem value={"颠覆认知"}>{t("CPW075")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">
            {/* Step 4: End Result(required) */}
            Step 4: {t("CPW025")}
          </Typography>
          <TextField
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            // placeholder={timePeriod1 === "针对痛点" ? "输入针对的痛点" : "输入针对的认知"}
            placeholder={timePeriod1 === "针对痛点" ? t("CPW001") + t("CPW074") : t("CPW001") + t("CPW075")}
            multiline
            minRows={4}
            maxRows={4}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {/* <Box display="flex" flexDirection="column" py={2} px={3}>
          <Typography component="span">Step 6 (optional)</Typography>
          <TextField
            onChange={onPainPoint1Change}
            value={props.painPoint1}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            placeholder="输入模仿风格"
            multiline
            minRows={1}
            maxRows={1}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box> */}

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit('pepc-p-1', e)}
          >
            PEPC - P (0.4)
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default PepcProblemItems;
