import React from "react";
import Meta from "../../components/Meta";
import { requireEliteUser } from "../../util/auth";
import DramaSection from "../../components/drama/DramaSection";

function DramaPage(props) {
  return (
    <>
      <Meta title="Video Idea" />
      <DramaSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="剧本大纲"
        subtitle="Idea"
      />
    </>
  );
}

export default requireEliteUser(DramaPage);
