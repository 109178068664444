import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import TesterItems from "./TesterItems";
import { Link, useRouter } from "../util/router";
import { useAuth } from "../util/auth";
import { chatContext } from "../context/ChatContext"
import '../style/container.css'
import generateUniqueId from "../util/generateuniqueid";
import typeText from "../util/typetext";
import loader from "../util/loader";
import chatStripe from "../util/chatStripe";
import { Divider } from "@material-ui/core";
import { toast, Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "695px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ai: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function TesterSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    loadingInterval,
    typingInterval,
    selfDefinedValue,
    setSelfDefinedValue,
  } = useContext(chatContext);

  const formRef = useRef(null);
  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [industry, setIndustry] = useState("product");

  const [productSellingPoint, setProductSellingPoint] = useState("");
  const [productType, setProductType] = useState("");

  const [tone, setTone] = useState("");
  const [cwStyle, setCwStyle] = useState("");

  let marketingPrompt
  let query 

  query = `产品是${productType}，独特卖点是${productSellingPoint}，`

  
  const handleProductSellingPointChange = (event) => {
    setProductSellingPoint(event.target.value);
  };
  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };
    

  async function handleSubmit( id, e, lastInput = false, speechRes = false) {
    e && e.preventDefault();
    // const data = new FormData(formRef.current);
    if (id === 1) {
      marketingPrompt = `
      以口语风格
      并以Interest模型为基础设计可以建立对我的的产品或服务的渴望的形容，带出我的产品成分可以解决的问题或改善生活的方式
      介绍我产品的独特卖点。加入数据阐述功效，并强调其对人体的益处和适用范围。
      我要你这样排列结果给我，分成几个部分
      卖点用一个列表列出来，列表符号不要用号码，用emoji。
      各个卖点后面再加入一个关于卖点的简短阐述
      字数不可以超过150个字。`
    } else if (id === 2) {
      marketingPrompt = `
      Your reply should be in Chinese.
      The customer's real estate is Alfa Bangsar, his current selling point is the only freehold unit in the area, fully furnished, his current target audience is middle aged expats, end result is Ideal Retirement Home and Lucrative Investment Opportunity。

      Following my instructions, your objective is to write a content on Facebook that describes the product in a compelling way that  motivates customers to make an appointment or purchase based on the information given.


      Instructions:
      Don't use fancy words; we want even children to understand what we are saying. Freely use creative and exaggerated adjectives to come up with the ideas.
      Based on the AIDA model, think step by step and think of a compelling way to structure a Facebook post that entices readers to want to know more.
      Incorporate each of the selling points given, and explain how the selling points and end result ideas are relevant to the target audience.

      Refrain from repeating your commands. Refrain from directly addressing and repeating our target audience in the output, we should only indirectly address them to increase marketing effectiveness.

      Output Language: Chinese.

      Output format:
      Think step by step and output a Facebook post based on output language (Chinese). 
      Firstly, start with a simple post headline (15 words and below). 
      After that, write a story explaining the appeal our product to make it relevant and entice the customer. (30 words and above)
      Below that is a list with relevant emojis as list-style, each list item should be the bolded selling points, followed by semi-colon (:) then 2 sentences that describes how the selling points are attractive and compelling to our target audience. 
      After the list is concluded, this is the part where the customers are interested, so add a short paragraph of a story describing an end result idea after purchasing the product. (30 words and above)
      Below the paragraph in a separate section, add a short call to action that compels the readers to 'SEND A MESSAGE' and ask for more details.

      Do note that you are not supposed to write advertisement content here, your task is just to objectively describe the product to entice the audiences. So do not include advertising headlines. `
    }

    let resPrompt = `${query + marketingPrompt}`
    let payload = { industry, selfDefinedValue, query, resPrompt}

    if (!query) return;

    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // bot's chatStripe
    let uniqueId;
    uniqueId = generateUniqueId();
    setLastUniqueId(uniqueId);
    // chat_container_ref.current.innerHTML += chatStripe(true, " ", uniqueId);
    chat_container_ref.current.innerHTML = chatStripe(true, " ", uniqueId) + chat_container_ref.current.innerHTML;

    chat_container_ref.current.scrollTop =
      chat_container_ref.current.scrollHeight;
    const loadingDiv = document.getElementById(
      lastInput ? lastUniqueId : uniqueId
    );
    // document.querySelector(".MuiCardContent-root")
    if (lastInput)
      loadingDiv.style.color = classes === "light" ? "#383838" : "#dcdcdc";

    loader(loadingDiv, loadingInterval);

    // fetching data from server 
    fetch("https://ctg-api.onrender.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: payload,
        name: auth.user.displayName,
        uid: auth.user.uid,
        email: auth.user.email
      }),
    })
      .then(async (response) => {
        clearInterval(loadingInterval.current);
        loadingDiv.innerHTML = "";
        if (response.ok) {
          setError(false);
          const data = await response.json();
          const parsedData = data.bot.trim();

          if (parsedData === "") {
            loadingDiv.innerHTML =
              "No results Found 😞\n<small>Change model</small>";
            setLoading(false);
          } else {
            // loadingDiv.innerHTML += parsedData
            setTyping(true);
            console.log("loadingDiv before typeText:", loadingDiv);
            typeText(
              loadingDiv,
              chat_container_ref.current,
              parsedData,
              typingInterval,
              setLoading,
              setTyping
            );
          }
        } else {
          toast.error('Oops, try refreshing or reducing word count. 😥')
          setError(true);
          setLoading(false);
          // const err = await response.text();
          loadingDiv.innerHTML =
            "An error occurred. \n<small>Hint: Try to <b>reduce your word count</b> or check your internet connection and then <b>try again</b><small>";
          loadingDiv.style.color = "#EF4444";
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        toast.error('Server under maintenance. 🚧')
        loadingDiv.innerHTML =
          "Sorry, server is down right now, try again later";
        loadingDiv.style.color = "#EF4444";
        clearInterval(loadingInterval.current);
        console.log(err)
      });
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Toaster />
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <TesterItems 
              handleSubmit={handleSubmit} 
              onProductSellingPointChange={handleProductSellingPointChange}
              onProductTypeChange={handleProductTypeChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    chatRef={chat_container_ref}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
      </Container>
    </Section>
  );
}

export default TesterSection;



function OutputContainer({ typing, load, error, chatRef, id }) {
  const classes = useStyles({ typing, load, error });

  return (
    <div ref={chatRef} id={id}>
      <div className={classes.ai}></div>
      <div className={classes.copy}></div>
    </div>
  );
}