import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  Box,
  FormLabel,
  Card,
} from "@material-ui/core";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import { firebaseApp } from "../../util/firebase";
import { useAuth } from "../../util/auth";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "15%",
    minWidth: 120,
  },
  searchButton: {
    backgroundColor: "#3b5998",
    color: "white",
    "&:hover": {
      backgroundColor: "blue",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "15%",
    marginTop: "1rem",
    marginBottom: "1rem",
    padding: theme.spacing(2),
    minWidth: 150,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1rem",
  },
  searchBarLabel: {
    marginBottom: "0.5rem",
  },
}));

const AdLibraryFilter = ({
  onSearchClick,
  selectedCards,
  saveMedia,
  onFilterChange,
  onFilterResultsChange,
  clearData,
  onClearSelection,
}) => {
  const classes = useStyles();
  const auth = useAuth();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [usedIndexes, setUsedIndexes] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const [isLoading, setIsLoading] = useState(false);

  function generateUniqueRandomIndexes(max, numRandomIndexes, usedIndexes) {
    let set = new Set(usedIndexes);
    while (set.size < numRandomIndexes + usedIndexes.length) {
      let randomIndex = Math.floor(Math.random() * max);
      if (!usedIndexes.includes(randomIndex)) {
        set.add(randomIndex);
      }
    }
    return [...set].slice(usedIndexes.length);
  }
  
  function roundToPrecision(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }

  const handleSearchClick = async () => {
    setIsLoading(true);

    const delay = Math.random() * 2000 + 1000;
    setTimeout(async () => {
      onSearchClick();

      // Calculate the number of new indexes to generate
      const numNewIndexes = Math.min(6, dataArray.length - usedIndexes.length);

      // Generate a set of unique random indexes excluding the used ones
      const randomIndexes = generateUniqueRandomIndexes(
        dataArray.length,
        numNewIndexes,
        usedIndexes
      );

      // Log the number of new indexes generated
      // console.log(`Generated ${numNewIndexes} new indexes`);
      // console.log("Used Indexes:", usedIndexes);
      // console.log("Random Indexes:", randomIndexes);

      // Update the usedIndexes state
      setUsedIndexes([...usedIndexes, ...randomIndexes]);

      // Get the items at the random indexes
      const nextItems = randomIndexes.map((index) => dataArray[index]);

      const db = getFirestore(firebaseApp);
      const userDocRef = doc(
        db,
        "users",
        auth.user.uid,
        "adData",
        `${selectedValue}`
      );
      // Fetch the existing document
      const userDoc = await getDoc(userDocRef);

      let existingItems = [];
      if (userDoc.exists()) {
        existingItems = userDoc.data().items || [];
      }

      // Combine existing items with new items
      const combinedItems = [...nextItems, ...existingItems];
      // console.log(combinedItems);

      await setDoc(
        userDocRef,
        {
          items: combinedItems,
          currentIndex: Math.min(currentIndex + 6, dataArray.length),
          usedIndexes: [...usedIndexes, ...randomIndexes], // Update usedIndexes in Firestore
        },
        { merge: true }
      );

      setCurrentIndex(Math.min(currentIndex + 6, dataArray.length));
      setIsLoading(false); // Set loading state to false after data is fetched
    }, delay);
    // Get current credits
    const db = getFirestore(firebaseApp);

    const userRef = doc(db, `users/${auth.user.uid}`);
    const userSnapshot = await getDoc(userRef);
    const currentCredits = userSnapshot.data().credits;
    const creditsToDeduct = 0.3;
    // Check if user has enough credits
    if (currentCredits < creditsToDeduct) {
      console.log("Not enough credits");
      setIsLoading(false);
      return;
    }

    // Deduct credits
    const newCredits = roundToPrecision(currentCredits - creditsToDeduct, 1);
    await setDoc(userRef, { credits: newCredits }, { merge: true });
  };

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore(firebaseApp);
      let allData = [];

      for (let i = 0; ; i++) {
        const chunkCollection = collection(
          db,
          `adsLibrary/filteredDatabase/${selectedValue}/chunks/chunk${i}`
        );
        const chunkSnapshot = await getDocs(chunkCollection);

        if (chunkSnapshot.empty) {
          break; // No more chunks, exit the loop
        }

        chunkSnapshot.forEach((doc) => {
          allData = [...allData, ...doc.data().obj.processedResults];
        });
      }

      setDataArray(
        allData.sort((a, b) => Number(a.adArchiveID) - Number(b.adArchiveID))
      );
    };

    fetchData();
  }, [selectedValue]);

  useEffect(() => {
    const fetchCurrentIndex = async () => {
      // console.log("fetchData called");

      const db = getFirestore(firebaseApp);
      const userDocRef = doc(
        db,
        "users",
        auth.user.uid,
        "adData",
        "mainDocumentId"
      );

      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        // console.log("Document exists, setting currentIndex");
        setCurrentIndex(userDoc.data().currentIndex || 0);
        setUsedIndexes(userDoc.data().usedIndexes || []);
      } else {
        console.log("Document does not exist");
      }
    };

    fetchCurrentIndex();
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <Box className={classes.container}>
        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="country-label"
            className={classes.searchBarLabel}
          >
            Country
          </FormLabel>
          <Select
            labelId="country-label"
            id="country-select"
            key="country-select"
            value="Malaysia"
            variant="outlined"
          >
            <MenuItem value="Malaysia">Malaysia</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} disabled>
          <FormLabel
            component="legend"
            id="category-label"
            className={classes.searchBarLabel}
          >
            Ad Type
          </FormLabel>
          <Select
            labelId="category-label"
            id="category-select"
            key="category-select"
            value="Active ads"
            variant="outlined"
          >
            <MenuItem value="Active ads">Active ads</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="search-label"
            className={classes.searchBarLabel}
          >
            Search
          </FormLabel>
          <Select
            labelId="search-label"
            id="search-select"
            key="search-select"
            value={selectedValue}
            variant="outlined"
            onChange={(event) => {
              clearData();
              setSelectedValue(event.target.value);
              onFilterChange(event.target.value);
            }}
          >
            <MenuItem value="保健">保健</MenuItem>
            <MenuItem value="心脏">心脏</MenuItem>
            <MenuItem value="祛斑">祛斑</MenuItem>
            <MenuItem value="课程">课程</MenuItem>
            <MenuItem value="金融">金融</MenuItem>
            <MenuItem value="风湿">风湿</MenuItem>
            <MenuItem value="胃痛">胃痛</MenuItem>
            <MenuItem value="礼盒">礼盒</MenuItem>
            <MenuItem value="房地产">房地产</MenuItem>
            <MenuItem value="宠物">宠物</MenuItem>
            <MenuItem value="失眠">失眠</MenuItem>
            <MenuItem value="关节痛">关节痛</MenuItem>
            <MenuItem value="偏头痛">偏头痛</MenuItem>
            <MenuItem value="补习">补习</MenuItem>
            <MenuItem value="头皮">头皮</MenuItem>
            <MenuItem value="眼睛干">眼睛干</MenuItem>
            {/* <MenuItem value="1212优惠">1212优惠</MenuItem>
            <MenuItem value="年终优惠">年终优惠</MenuItem> */}
            <MenuItem value="排毒">排毒</MenuItem>
            <MenuItem value="美白">美白</MenuItem>
            <MenuItem value="生发">生发</MenuItem>
            {/* <MenuItem value="室内设计">室内设计</MenuItem> */}
            <MenuItem value="备孕">备孕</MenuItem>
            <MenuItem value="优惠">优惠</MenuItem>
            <MenuItem value="补习">补习</MenuItem>
          </Select>
        </FormControl>

        {/* <FormControl className={classes.formControl}>
          <FormLabel
            component="legend"
            id="filter-label"
            className={classes.searchBarLabel}
          >
            Filter Results
          </FormLabel>
          <Select
            labelId="filter-label"
            id="filter-select"
            key="filter-select"
            value={filterValue}
            variant="outlined"
            onChange={(event) => {
              clearData();
              setFilterValue(event.target.value);
              onFilterResultsChange(event.target.value);
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Images">Images</MenuItem>
            <MenuItem value="Videos">Videos</MenuItem>
          </Select>
        </FormControl> */}
      </Box>

      <Box>
        <Button
          onClick={handleSearchClick}
          disabled={
            usedIndexes.length >= dataArray.length ||
            selectedValue === "" ||
            isLoading
          }
          variant="contained"
          className={classes.searchButton}
        >
          {isLoading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            "Search (0.3)"
          )}
        </Button>
        {selectedCards.length > 0 && (
          <>
            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={() => {
                // console.log(selectedCards)
                saveMedia();
              }}
            >
              Save Media
            </Button>

            <Button
              variant="contained"
              className={classes.searchButton}
              onClick={onClearSelection}
            >
              Clear Selection
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdLibraryFilter;
