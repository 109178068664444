import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import LanguageOutput from './../functionalComponent/LanguageOutput';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "760px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function SimplifierItems(props) {
  const { handleSubmit } = props;
  const { 
    onCopywritingChange, 
    onCwStyleChange, 
    onNegativePromptChange, 
    cwStyle 
  } = props;
  
  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("Malay");

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 1: 输入要转换的文案 */}
            Step 1: {t("CPW027")}
            </Typography>
          <TextField 
            onChange={onCopywritingChange}
            value={props.copywriting}
            margin="dense"
            name="copywriting"
            fullWidth
            variant="outlined"
            // placeholder={"输入要转换的文案"}
            placeholder={t("CPW001") + t("CPW027")}
            multiline
            minRows={15}
            maxRows={15}
            sx={{
              overflow: "hidden",
            }}
            
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 2: Target 年龄群(optional) */}
            Step 2: {t("CPW007")}(optional)
            </Typography>
          <FormControl>
            <InputLabel id="select-label" margin="dense" disableAnimation={true}>
              {/* 目标年龄群 */}
              {t("CPW008")}
            </InputLabel>
            <Select
              labelId="select-label"
              id="select"
              // label="age"
              label={t("CPW007")}
              value={cwStyle}
              onChange={onCwStyleChange}
            >
              {/* <MenuItem value={'general'}>通用</MenuItem> */}
              <MenuItem value={'general'}>{t("CPW009")}</MenuItem>
              {/* <MenuItem value={'年轻人'}>年轻人</MenuItem> */}
              <MenuItem value={'年轻人'}>{t("CPW028")}</MenuItem>
              {/* <MenuItem value={'中年人'}>中年人</MenuItem> */}
              <MenuItem value={'中年人'}>{t("CPW029")}</MenuItem>
              {/* <MenuItem value={'老年人'}>老年人</MenuItem> */}
              <MenuItem value={'老年人'}>{t("CPW030")}</MenuItem>
            </Select>
            </FormControl>   
        </Box>
        
        {/* <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 3</Typography>
          <TextField 
            onChange={onNegativePromptChange}
            value={props.negativePrompt}
            margin="dense"
            name="copywriting"
            fullWidth
            variant="outlined"
            placeholder={"输入不要出现的敏感字眼"}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
          />
        </Box> */}

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let simpliId;
              if (selectedLanguage === "Chinese") {
                simpliId = "simpli-1-cn";
              } else if (selectedLanguage === "English") {
                simpliId = "simpli-1-en";
              } else if (selectedLanguage === "Malay") {
                simpliId = "simpli-1-malay";
              }
              await handleSubmit(simpliId, e);
              // legacyMode && handleSaveInputs();
            }}
          >
            {/* 转化文案 */}
            {t("CPW031")}
          </Button>

        </Box>

      </Paper>
    </>
  );
}

export default SimplifierItems;
