import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfJiangXiangSection from "../../components/malay/CfJiangXiangSection";

function CfJiangXiangPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" />
      <CfJiangXiangSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="获得奖项"
      />
    </>
  );
}

export default requireProUser(CfJiangXiangPage);
