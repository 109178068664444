import React, { useState } from "react";
import {
	Card,
	CardContent,
	List,
	ListItem,
	ListItemText,
	Collapse,
	Typography,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const UpdateCard = ({ updates, title, videoLink }) => {
	const [expanded, setExpanded] = useState(false);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const isObject = (update) => typeof update === "object";

	return (
		<Box marginBottom={2} >
			<Accordion expanded={expanded === title} onChange={handleAccordionChange(title)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{display: "flex"}}>
					<Box padding={2}>
            <Typography variant="h5" style={{ fontWeight: "bold"}}>{title}</Typography>
						{videoLink && <Typography color="primary"style={{ marginTop: "1rem", fontWeight: "bold", color: "#3f51b5" }}>
							<a href={videoLink} target="_blank" rel="noopener noreferrer">
								ZOOM Update 影片 点击这里
							</a>
						</Typography>}
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<List component="div" disablePadding>
						{updates.map((update, index) => (
							<React.Fragment key={index}>
								<ListItem>
									<ListItemText
										primary={`${index + 1}. ${
											isObject(update) ? update.item : update
										}`}
									/>
								</ListItem>
								{isObject(update) && update.subItems && (
									<Collapse in={expanded === title} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{update.subItems.map((subItem, subIndex) => (
												<ListItem
													key={subIndex}
													style={{ paddingLeft: "30px" }}
												>
													<ListItemText primary={`- ${subItem}`} />
												</ListItem>
											))}
										</List>
									</Collapse>
								)}
							</React.Fragment>
						))}
					</List>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default UpdateCard;
