import React from "react";
import Meta from "../components/Meta";
import AttnDemSection from "../components/AttnDemSection";
import { requireProUser } from "../util/auth";

function AttnDemPage(props) {
  return (
    <>
      <Meta title="Attention - Demand Generator" />
      <AttnDemSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Attention - Demand"
        subtitle="中文文案"
      />
    </>
  );
}

export default requireProUser(AttnDemPage);
