import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import FeaturesSection2 from "./../components/FeaturesSection2";
import bannerImage from '../assets/banner.jpg'
import ServiceSection from "../components/ServiceSection";
import FeaturesSection3 from "../components/FeaturesSection3";

function LandingPage(props) {
  return (
    <>
        <Meta title="CTG-AI"/>
        <HeroSection2 />
        {/* <FeaturesSection2 /> */}
        <ServiceSection />
        <FeaturesSection3 
          title="Features"
          subtitle="Feel the magical wonders of technology"
        />
    </>
  );
}

export default LandingPage;
