import React from "react";
import Meta from "./../components/Meta";
import PaymentSection from "./../components/PaymentSection";

function PaymentPage(props) {
  return (
    <>
      <Meta title="Payment" />
      <PaymentSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Payment"
        subtitle="epsum lorem ipsum"
      />
    </>
  );
}

export default PaymentPage;
