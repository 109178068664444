import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import UserList from '../components/UserList';
import { requireAdmin, useAuth } from '../util/auth'; // Import the useAuth hook
import { requireAuth } from "../util/auth";
import Meta from '../components/Meta';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const AdminPanel = () => {
  const classes = useStyles();
  const auth = useAuth(); // Access the auth object

  // Check if the current user has an 'admin' role
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (auth.user && auth.user.role === 'admin') {
      setIsAdmin(true);
    }
  }, [auth.user]);

  if (!isAdmin) {
    return <Typography variant="h6">Access Denied. You are not an admin.</Typography>;
  }

  return (
    <>
        <Meta title="Admin Panel" />
        <UserList
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title="Admin Panel"
            subtitle=""
        />
    </>
  );
};

export default requireAdmin(AdminPanel);