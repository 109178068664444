import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import LongContent from "./LongContent";
import { List, ListItem, ListItemText } from "@material-ui/core";
import ReactPlayer from "react-player";
import { requireProUser } from "../util/auth";
import { Link } from "@material-ui/core";
import SectionHeader from "./SectionHeader";
import UpdateCard from "./UpdatesCard";

function AnnouncementSection(props) {
  const updates1 = [
    "加了Input会有记忆的function",
    "加了同时可以生成很多个的feature",
    "加了可以生成产品图的feature",
    "改善了后台中文的idea generation",
    "Confidence Reply方面做出了一些Fine tune的改",
    "UI Bugfix",
    "加了Facebook Login",
    "马来文后台优化，少了参杂印尼话的result",
    "白话文后台优化, 减少方言的weightage",
    "Improved Mobile layout for landing page",
    "Improved Mobile layout for tutorial page",
    "Improved server response speed for every section 40sec -> 13sec.",
    "Minor system bugs fixed.",
  ];

  const updates2 = [
    {
      item: "加强了PEPC生成出来的效果",
      subItems: ["减少离题几率", "可以加入USP让AI去相比较贴切的影片开头"],
    },
    {
      item: "Malay ADDA 加入了可以连续按很多次的function",
      subItems: ["Malay 部分统一了Input的结构"],
    },
    {
      item: "English ADDA 加入了可以连续按很多次的function",
      subItems: ["English 部分统一了Input的结构"],
    },
    {
      item: "产品图生成的feature加了很大的改动",
      subItems: ["UI上改成比较容易使用的format"],
    },
    {
      item: "加入了可以改动产品所在位置的变数",
      subItems: ["左边", "中间", "右边"],
    },
    {
      item: "在主题方面加入了4个全新的主题",
      subItems: ["家具 Furniture", "海滩 Beach", "大自然 Nature", "礼物 Gifts"],
    },
    {
      item: "加入了可以选择色系的Feature",
      subItems: ["Optional 自定义色系"],
    },
    "色系建议可以到新的生成色系Feature找灵感",
    {
      item: "加了一个新的生成色系的Feature",
      subItems: ["提供免费使用", "可以把适合的色系用在生成产品图上"],
    },
    "图片生成修复了UI呈现上的Bug",
  ];

  const updates3 = [
    {
      item: "增加了 Content Angle Generator 的功能",
      subItems: ["会持续增加不一样的形式"],
    },
    {
      item: "加入了新的 Saved Reply 功能",
      subItems: ["新版本和旧版本可以切换"],
    },
    {
      item: "每个页面现在都可以支持3语的输入",
      subItems: ["增加模型稳定性", "减少离题率"],
    },
    {
      item: "改良了ADDA文案里的 Demand 功能",
      subItems: [
        "Demand 功能里也增加了不一样的形式",
        "故事型 和 情景描述型",
        "使用感受型 正在测试中 暂时还没开放",
      ],
    },
    {
      item: "改良了ADDA文案里的 Description 功能",
      subItems: ["减少了单一性"],
    },
    {
      item: "改良了 USP 和 SWOT 功能的生成",
      subItems: [],
    },
    {
      item: "在马来文部分加入了 USP 和 SWOT 的功能",
      subItems: [],
    },
    {
      item: "在英文部分加入了 USP 和 SWOT 的功能",
      subItems: [],
    },
    {
      item: "把三语功能的UI改成同样的形式",
      subItems: [],
    },
    {
      item: "增加了后台数据库的数据",
      subItems: [],
    },
  ];

  const updates4 = [
    {
      item: "第一步：做市场调查 – 用 ads library 找出你的competitors做survey",
      subItems: [
        "“把基础建立在别人的成果之上”，Page transparency – ads library – search “Malaysia”+ “All ads”",
        "搜 direct competitors，灵感的来源",
        "看他开几个广告 ，ie 3 ads using this creative，就代表这个广告是跑得不错的",
        "深入研究那些爆的广告",
        "直接超越",
      ],
    },
    {
      item: "第二步：compile 所有competitors 的 USP （独特卖点）",
      subItems: ["Study competitor 的 USP"],
    },
    {
      item: "第三步：设计出属于你自己的USP",
      subItems: [
        "用AI赋能量输入第二步找到的USP，生成改良后的USP",
        "用content angle",
      ],
    },
    {
      item: "第四步：写文案",
      subItems: ["用AI赋能量generate文案 ADDA", "要第二次创作"],
    },
    {
      item: "第五步：二次创作",
      subItems: ["一定要二次创作，因为当很多人在用的时候就会有很多相似的东西"],
    },
    {
      item: "第六步：用图片做创意",
      subItems: [
        "Upload 有字的图片",
        "其实图片设计就是content angle加图片而已",
        "用稿定设计，创客贴",
      ],
    },
    {
      item: "第七步：在AI赋能量写Reply SOP",
      subItems: ["二次创作"],
    },
    {
      item: "第八步：开始打广告",
      subItems: ["敢敢打广告", "一定会遇到挫折和失败"],
    },
    {
      item: "第九步：算costing",
      subItems: [],
    },
    {
      item: "第十步：A/B test",
      subItems: ["在第八步一定会遇到挫折和失败，继续一直test"],
    },
  ];

  const updates5 = [
    {
      item: "Referral System 已经全面开放了",
      subItems: [
        "会员们可以通过referral link介绍人来使用我们的系统",
        "自己可以得到佣金",
        "Refer进来的伙伴也会得到额外的credits",
        "Referral Link 可以在 (hyperlink) 里面找到",
        "只要Refer到的朋友在网站里付款然后开通会员",
        "你就可以获得RM300的现金",
        "被refer进来的朋友也会得到额外RM150的credits",
        "RM150的credits可以做什么呢",
        "可以狂按375次，生成任何你想要的东西，去市场换业绩回来",
      ],
    },
    {
      item: "Simplifer 白话文功能",
      subItems: [
        "重新开启了",
        "减少了出现不合理的方言的机率",
        "广东话和福建话",
      ],
    },
    {
      item: "History Page 翻新",
      subItems: [
        "把每个Feature的生成分类起来",
        "能直接分行Paste在Google Sheets",
        "方便用户使用",
      ],
    },
    {
      item: "Saved Reply",
      subItems: ["增加了可以存入产品/服务名称", "方便切换产品/服务名称"],
    },
    {
      item: "ADDA文案里的 Demand 功能",
      subItems: ["开放用后使用感型", "可以生成用户使用后的感受类的文案"],
    },
    {
      item: "USP",
      subItems: [
        "优化了植入的数据库",
        "生成出来的结果会更贴切更有用",
        "结果资料也会比较多元化",
      ],
    },
    {
      item: "Content Angle Generator",
      subItems: ["新加入的Feature", "可以无限生成"],
    },
    {
      item: "General Improvements",
      subItems: [
        "减少离题机率",
        "减少生成错语言的机率",
        "优化了植入数据的流程",
      ],
    },
  ];


  const updates6 = [
    {
      item: "广告分析",
      subItems: [
        "这次改版推出的新功能",
        "帮助大家把市场调查的时间大量缩短",
        "大量找出市面上的Winning Ads",
        "陆续会增加更多的关键词",
      ],
    },
    {
      item: "产品图生成",
      subItems: [
        "可自己写你想要的主题",
        "用英文",
        "越仔细越好",
        "增加了一次性生成多图的稳定性",
        "会有几秒的间隔",
      ],
    },
    {
      item: "文案转白话",
      subItems: [
        "添加英语版的转白话功能",
        "转英文文案",
        "添加马来文版的转白话功能",
        "转马来文文案",
      ],
    },
    {
      item: "Reply SOP 回复攻略",
      subItems: [
        "添加英语版的Reply SOP功能",
        "会陆续优化后台数据和生成出来的构造",
        "添加马来文版的Reply SOP功能",
        "会陆续优化后台数据和生成出来的构造",
      ],
    },
    {
      item: "Confidence Reply 信心回复",
      subItems: [
        "添加英语版的Confidence Reply功能",
        "会陆续优化后台数据和生成出来的构造",
        "添加马来文版的Confidence Reply功能",
        "会陆续优化后台数据和生成出来的构造",
      ],
    },
    {
      item: "语言设置功能（中文，English, Malay）",
      subItems: ["APP里加入三语的界面"],
    },
  ];

  const updates7 = [
    {
      item: "广告分析",
      subItems: [
        "修复了一些分析时会遇到的bug",
        "如：之前金融关键词的搜索器会出现当机情况",
        "改写一些后台的分析流程",
        "现在会比较快把结果拿出来",
        "收到feedback说分析历史太多时页面会lag",
        "已经做出改动",
        "短期内会加入翻页的功能",
      ],
    },
    {
      item: "产品图生成",
      subItems: [
        "重新写过了后台",
        "调用了全新的图片模型",
        "对于人物和场景的真实度做出了调整",
        "现在用新一代的生成出来的图片真实性会大大提升",
        "加入了优化关键词和翻译的功能",
        "只要使用优化的按钮生成",
        "可以用任何语言写你想要的主题",
        "输入的关键词会被后台AI优化",
        "产出的图片质量也会大大提升",
      ],
    },
  ];
  const updates8 = [
    {
      item: "广告分析",
      subItems: [
        "修复了一些分析时会遇到的bug",
        "如：多图不会显示",
        "目前多图已经可以显示出来",
        "用照片旁的箭头去翻阅前后",
        "也有Auto Scroll功能",
      ],
    },
    {
      item: "生成图片",
      subItems: [
        "调整了我们的图片模型",
        "增加了真实模型和卡通模型",
        "真实模型生成出来的图会偏向真实照片",
        "卡通模型生成出来的图可以比较夸张",
        "夸张的表情",
        "夸张的情景",
        "等等...",
        "真实模型的优化改动",
        "基于原有的模型我们这次把真实模型对于Prompt的权重做出了优化",
        "AI会更看重人物动作和表情的Prompt",
        "减少出来和指示不一样的图",
        "根据反馈调整了后台对于优化Prompt的机制",
        "AI会尽量贴近我们给的Prompt去做翻译优化",
        "大大减少离题的机率",
        "增加了相机距离的选项",
        "远距离，中距离和近距离",
        "主要是远距离出来的图会比较Zoomed out",
        "四周空位会比较多",
        "方便也比较适合做广告图",
      ],
    },
  ];
  const updates9 = [
    {
      item: "CTG Editor",
      subItems: [
        "推出我们自家先行版的制图系统",
        "Layering 图层",
        "字体设计",
        "包括字体描边，字体影子，等等",
        "AI系统里生成的图Integration",
        "在应用, Image History",
        "按一下图片就会自动加入画布",
        "素材库",
        "目前已经置入",
        "搜索功能会在下个更新推出",
      ],
    },
    {
      item: "文案ADDA",
      subItems: [
        "加入了全新的Attention模型",
        "目前计划会把两个版本都留着",
        "更多的优化和改进会持续推出",
      ],
    },
  ];

  const sharing1 = [
    {
      item: "【创业成功必须要知道的能力值】🔥🔥",
      subItems: [
        "一个人能不能成功，从行为举止就可以看得出这个人有没有【成功的特质】。",
        "往往拥有这些【能力值越高的人、成功几率就越高】✨而好消息是这些能力值是可以被训练的🥂 ！",
      ],
    },
  ];
  const sharing2 = [
    {
      item: "【解剖成功电商的Case Study】🌐🌐 ",
      subItems: [
      ],
    },
  ];
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Box mt={3}>
        <Container>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            textAlign="center"
          />
          <LongContent>
            <UpdateCard
              title="【解剖成功电商的Case Study】🌐🌐 "
              videoLink="https://youtu.be/JpBaPVGoZ74"
              updates={sharing2}
            />
            <UpdateCard
              title="【创业成功必须要知道的能力值】🔥🔥"
              videoLink="https://youtu.be/IImw6e_zydw"
              updates={sharing1}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #8- 26/1/2024"
              // videoLink="https://youtu.be/luaYa36P8Sg"
              updates={updates9}
            />
            <UpdateCard

              title="📣 AI 赋能量 Update #7 - 12/1/2024"
              videoLink="https://youtu.be/ilB_e-0Y3vg"
              updates={updates8}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #6 - 13/12/2023"
              videoLink="https://youtu.be/ywbGjJZxP1k4"
              updates={updates7}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #5 - 21/11/2023"
              videoLink="https://youtu.be/OdRY92mDSV4"
              updates={updates6}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #4 - 17/10/2023"
              videoLink="https://youtu.be/vAQn2ZacIRE"
              updates={updates5}
            />
            <UpdateCard
              title="📣 如何用AI赋能量在市场真正赚钱 20/9/2023"
              videoLink="https://youtu.be/fT6qlQTIsnQ"
              updates={updates4}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #3 - 30/08/2023"
              videoLink="https://youtu.be/u-Vw7URMqx0"
              updates={updates3}
            />
            <UpdateCard
              title="📣 AI 赋能量 Update #2 - 28/07/2023"
              videoLink="https://youtu.be/awSW38t8g-Q"
              updates={updates2}
            />

            <UpdateCard
              title="📣 AI 赋能量 Update #1 - 28/06/2023"
              videoLink="https://youtu.be/I7tIzHqA890"
              updates={updates1}
            />

            {/* <h2>📣 AI 赋能量 Update #2 28/07/2023</h2>
						<ReactPlayer url={`https://www.youtube.com/watch?v=I7tIzHqA890`} playing width={"100%"}/>
						<Link
							href="https://youtu.be/awSW38t8g-Q"
							target="_blank"
							style={{ fontWeight: "bold", color: "#3f51b5" }}
						>
							ZOOM Update 影片 点击这里
						</Link>
						<List aria-label="List of updates">
							{updates2.map((update, index) => {
								if (typeof update === "string") {
									return (
										<ListItem key={index}>
											<ListItemText primary={`${index + 1}. ${update}`} />
										</ListItem>
									);
								} else if (typeof update === "object") {
									return (
										<React.Fragment key={index}>
											<ListItem>
												<ListItemText
													primary={`${index + 1}. ${update.item}`}
												/>
											</ListItem>
											<List component="div" disablePadding>
												{update.subItems.map((subItem, subIndex) => (
													<ListItem
														key={subIndex}
														style={{ paddingLeft: "30px" }}
													>
														<ListItemText primary={`- ${subItem}`} />
													</ListItem>
												))}
											</List>
										</React.Fragment>
									);
								}
							})}
						</List>

						<h2>📣 AI 赋能量 Update #1 28/06/2023</h2>
						<ReactPlayer url={`https://www.youtube.com/watch?v=I7tIzHqA890`} playing width={"100%"}/>
						<Link
							href="https://www.youtube.com/watch?v=I7tIzHqA890"
							target="_blank"
							style={{ fontWeight: "bold", color: "#3f51b5" }}
						>
							ZOOM Update 影片 点击这里
						</Link>
						<List aria-label="List of updates">
							{updates1.map((update, index) => (
								<ListItem key={index}>
									<ListItemText primary={`${index + 1}. ${update}`} />
								</ListItem>
							))}
						</List> */}
          </LongContent>
        </Container>
      </Box>
    </Section>
  );
}

export default requireProUser(AnnouncementSection);