import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import ContentAngleItems from "./ContentAngleItems";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext";
import "../../style/container.css";
import { Divider } from "@material-ui/core";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  ay: {},
  copy: {
    display: ({ typing, load, error }) =>
      typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  },
}));

function ContentAngleSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [age, setAge] = useState("general");
  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  async function handleSubmit(id, e, lastInput = false, speechRes = false, credit = 0.8) {
    e && e.preventDefault();
    e && e.stopPropagation();

    let query;
    const ageGroups = {
      20: "20-30岁的人",
      30: "30-40岁的人",
      40: "40-50岁的人",
      50: "50-60岁的人",
      general: "所有年龄段的人",
    };

    let ageGroup = ageGroups[age] || ageGroups["general"];

    query = `我的产品是${productName}，目标人群是${ageGroup}，独特卖点是${productUSP}。以生活化情景来写销售角度。`;

    let payload = { selfDefinedValue, id, query, credit };

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} spacing={4}>
          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <ContentAngleItems
              handleSubmit={handleSubmit}
              age={age}
              productName={productName}
              productUSP={productUSP}
              onProductNameChange={handleProductNameChange}
              onProductUSPChange={handleProductUSPChange}
              onAgeChange={handleAgeChange}
              legacyMode={legacyMode}
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Grid>

          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>
                  <OutputContainer
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </Section>
  );
}

export default ContentAngleSection;