import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./../functionalComponent/LegacyModeSwitch";
import { useTranslation } from "react-i18next";
import LanguageOutput from './../functionalComponent/LanguageOutput';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "650px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function CfXinXinItems(props) {
  const { handleSubmit } = props
  const { 
    onProductNameChange, 
    onProductCertChange,
    onProductEndResultChange,
    onTimePeriod1Change,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Malay");

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务(required) */}
            Step 1: {t("CPW006")}
          </Typography>
          <TextField 
            onChange={onProductNameChange}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            // placeholder="输入品牌名字和卖的产品/服务种类"
            placeholder={t("CPW001") + t("CPW006")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 2: 获得的认证/奖项(required) */}
            Step 2: {t("CPW057")}
          </Typography>
          <TextField 
            onChange={onProductCertChange}
            value={props.productCert}
            margin="dense"
            name="productCert"
            fullWidth
            variant="outlined"
            // placeholder="输入获得的认证/奖项"
            placeholder={t("CPW001") + t("CPW057")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 3: 用了多久时间达到下面的End Result(required) */}
            Step 3: {t("CPW060")}
          </Typography>
          <TextField 
            onChange={onTimePeriod1Change}
            value={props.timePeriod1}
            margin="dense"
            name="timePeriod1"
            fullWidth
            variant="outlined"
            // placeholder="输入用了多久时间达到下面的End Result"
            placeholder={t("CPW001") + t("CPW060")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        
        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 4: 达到的End Result(required) */}
            Step 4: {t("CPW061")}
          </Typography>
          <TextField 
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            // placeholder="输入达到的End Result"
            placeholder={t("CPW001") + t("CPW061")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let xinxin;
              if (selectedLanguage === "Chinese") {
                xinxin = "cf-xinxin-cn";
              } else if (selectedLanguage === "English") {
                xinxin = "cf-xinxin-en";
              } else if (selectedLanguage === "Malay") {
                xinxin = "cf-xinxin-malay";
              }
              await handleSubmit(xinxin, e);
              // legacyMode && handleSaveInputs();
            }}
          >
            {/* 信心回复 */}
            {t("CPW070")}
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default CfXinXinItems;
