import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import { LegacyModeSwitch, TruncateSelect, TruncateAutocomplete } from "./../functionalComponent/LegacyModeSwitch";
import { useTranslation } from "react-i18next";
import LanguageOutput from './../functionalComponent/LanguageOutput';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "850px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function CfEndResultItems(props) {
  const { handleSubmit } = props
  const { 
    onProductNameChange, 
    onProductUspChange,
    onProductEndResultChange,
    onTimePeriod1Change,
    onTimePeriod2Change,
    onPainPoint1Change,
    onPainPoint2Change,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  // multiple languages support
  const [selectedLanguage, setSelectedLanguage] = useState("Malay");

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 1: 品牌名字和卖的产品/服务(required) */}
            Step 1: {t("CPW006")}
          </Typography>
          <TextField 
            onChange={onProductNameChange}
            value={props.productName}
            margin="dense"
            name="productName"
            fullWidth
            variant="outlined"
            // placeholder="输入品牌名字和卖的产品/服务种类"
            placeholder={t("CPW001") + t("CPW006")}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 2: 品牌卖点 (USP)(required) */}
            Step 2: {t("CPW015")}
          </Typography>
          <TextField 
            onChange={onProductUspChange}
            value={props.productUsp}
            margin="dense"
            name="productElements"
            fullWidth
            variant="outlined"
            // placeholder="输入USP"
            placeholder={t("CPW001") + t("CPW015")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 3: End Result(required) */}
            Step 3: {t("CPW025")}
          </Typography>
          <TextField 
            onChange={onProductEndResultChange}
            value={props.productEndResult}
            margin="dense"
            name="productEndResult"
            fullWidth
            variant="outlined"
            // placeholder="输入End Result"
            placeholder={t("CPW001") + t("CPW025")}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Box display="flex">
        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 4: 时长1(required) */}
            Step 4: {t("CPW051")}1
          </Typography>
          <TextField 
            onChange={onTimePeriod1Change}
            value={props.timePeriod1}
            margin="dense"
            name="timePeriod1"
            fullWidth
            variant="outlined"
            // placeholder="输入时长1"
            placeholder={t("CPW001") + t("CPW051")+"1"}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" flexGrow="1"  py={2} px={3} >
          <Typography component="span">
            {/* Step 5: 针对的痛点1(required) */}
            Step 5: {t("CPW052")}1
          </Typography>
          <TextField 
            onChange={onPainPoint1Change}
            value={props.painPoint1}
            margin="dense"
            name="painPoint1"
            fullWidth
            variant="outlined"
            // placeholder="输入针对的痛点1"
            placeholder={t("CPW001") + t("CPW052")+"1"}
            multiline
            minRows={1}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        </Box>

        <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">
            {/* Step 6: 时长2(optional) */}
            Step 6: {t("CPW051")}2
          </Typography>
          <TextField 
            onChange={onTimePeriod2Change}
            value={props.timePeriod2}
            margin="dense"
            name="timePeriod2"
            fullWidth
            variant="outlined"
            // placeholder="输入时长2"
            placeholder={t("CPW001") + t("CPW051")+"2"}
            multiline
            minRows={1}
            maxRows={1}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" flexGrow="1"  py={2} px={3} >
          <Typography component="span">
            {/* Step 7: 针对的痛点2(optional) */}
            Step 7: {t("CPW052")}2
          </Typography>
          <TextField 
            onChange={onPainPoint2Change}
            value={props.painPoint2}
            margin="dense"
            name="painPoint2"
            fullWidth
            variant="outlined"
            // placeholder="输入针对的痛点2"
            placeholder={t("CPW001") + t("CPW052")+"2"}
            multiline
            minRows={1}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        </Box>

        {/* <LanguageOutput selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> */}

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={async (e) => {
              let endres;
              if (selectedLanguage === "Chinese") {
                endres = "cf-endres-cn";
              } else if (selectedLanguage === "English") {
                endres = "cf-endres-en";
              } else if (selectedLanguage === "Malay") {
                endres = "cf-endres-malay";
              }
              await handleSubmit(endres, e);
              // legacyMode && handleSaveInputs();
            }}
          >
            END RES
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default CfEndResultItems;
