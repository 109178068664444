import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import BannedSection from "../components/BannedSection";

function BannedPage(props) {
  return (
    <>
      <Meta title="Content Angle Generator" />
      <BannedSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Banned Checker"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(BannedPage);
