import React from "react";
import Meta from "../components/Meta";
import ImageHistorySection from "../components/ImageHistorySection";
import { requireAuth } from "../util/auth";

function ImageHistoryPage(props) {
  return (
    <>
      <Meta title="User History" />
      <ImageHistorySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Image History"
        subtitle="图片生成历史"
      />
    </>
  );
}

export default requireAuth(ImageHistoryPage);
