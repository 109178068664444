import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import SwotItems from "./SwotItems";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import '../../style/container.css'
import { Divider } from "@material-ui/core";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "885px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function SwotSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);

  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const formRef = useRef(null);
  const chat_container_ref = useRef(null);
  const [lastUniqueId, setLastUniqueId] = useState("");

  const [industry, setIndustry] = useState("product");
  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [productTargetAge, setProductTargetAge] = useState("");
  const [monthlyRevenue, setMonthlyRevenue] = useState("");
  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductTargetAgeChange = (event) => {
    setProductTargetAge(event.target.value);
  };

  const handleMonthlyRevenueChange = (event) => {
    setMonthlyRevenue(event.target.value);
  };

  async function handleSubmit( id, e, lastInput = false, speechRes = false, credit = 0.2) {
    e && e.preventDefault();

    let query 
    if (monthlyRevenue === "") {
      // query = `我客户的产品是${productName}，独特卖点是${productUSP}，目前目标人群年龄是${productTargetAge}`
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n
      The target age is: ${productTargetAge} \n` 
    } else {
      // query = `我客户的产品是${productName}，独特卖点是${productUSP}，目前目标人群年龄是${productTargetAge}，目前一个月的销售额平均是${monthlyRevenue}`
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n
      The target age is: ${productTargetAge} \n
      The monthly revenue for now is: ${monthlyRevenue} \n` 
    }

    if (id === "swot-audience-cn" || id === "swot-audience-en" || id === "swot-audience-malay") {
      credit = 0.3
      // query = `我客户的产品是${productName}，独特卖点是${productUSP}`
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n` 
    }

    let payload = { industry, selfDefinedValue, query, id, credit }

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <SwotItems 
              monthlyRevenue={monthlyRevenue}
              productTargetAge={productTargetAge}
              handleSubmit={handleSubmit} 
              productName={productName}
              onProductNameChange={handleProductNameChange}
              onProductUSPChange={handleProductUSPChange}
              onProductTargetAgeChange={handleProductTargetAgeChange}
              onMonthlyRevenueChange={handleMonthlyRevenueChange}
              legacyMode={legacyMode} 
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </Section>
  );
}

export default SwotSection;