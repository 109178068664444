import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ContentManagementSection from "../components/ContentManagementSection";

function ContentManagementPage(props) {
  return (
    <>
      <Meta title="Content Management" />
      <ContentManagementSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Content Management"
        subtitle="内容管理"
      />
    </>
  );
}

export default requireProUser(ContentManagementPage);