import botIcon from "../assets/ctg.ico";
// import copyIcon from "../assets/copy.svg";

function chatStripe(isAi, value, uniqueId, showIcon = true) {
  return `<Box class="wrapper ${isAi && "ai"}">
      <div class="chat">
      ${
        showIcon
          ? `<div class="profile">
              <img src="${botIcon}" alt="${
              isAi ? "bot-icon" : "user-icon"
            }" />
            </div>`
          : ""
      }
        <div class="message" id="${uniqueId}">${value}</div>
      </div>

    </Box>`;
}
export default chatStripe;
