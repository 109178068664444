import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import ReplySection from "../../components/eng/ReplySection";

function ReplyPage(props) {
  return (
    <>
      <Meta title="Reply SOP (English)" />
      <ReplySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="回复攻略 (英文)"
        subtitle="英文 Reply SOP"
      />
    </>
  );
}

export default requireProUser(ReplyPage);
