import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import ConfidenceSection from "../components/ConfidenceSection";

function ConfidencePage(props) {
  return (
    <>
      <Meta title="Confidence Reply" />
      <ConfidenceSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply"
        subtitle=""
      />
    </>
  );
}

export default requireProUser(ConfidencePage);
