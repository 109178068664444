import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardActionArea, CardContent, Typography, Container, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from './Section';
import SectionHeader from './SectionHeader';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    gridContainer: {
      justifyContent: 'center',
    },
    gridItem: {
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      maxWidth: 345, // Set a maximum width for the cards
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardContent: {
      flexGrow: 1,
    },
  }));
  
  

const sections = [
{ title: '成分/技术', path: '/confidence/ingredients' },
{ title: '权威认证', path: '/confidence/authority' },
{ title: '信心回复', path: '/confidence/cfreply' },
{ title: '客户见证', path: '/confidence/testi' },
{ title: '独特卖点', path: '/confidence/usp' },
{ title: '公司背景', path: '/confidence/company' },
{ title: '专业背景', path: '/confidence/professional' },
{ title: '获得奖项', path: '/confidence/awards' },
// ... add more sections here
];

const CfServiceMenu = (props) => {
    const classes = useStyles();
    const history = useHistory();
  
    const handleClick = (path) => {
      history.push(path);
    };
  
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={4}
              textAlign="center"
            />
    
          <Box className={classes.root}>
            <Grid container spacing={3} className={classes.gridContainer}>
              {sections.map((section, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} className={classes.gridItem}>
                  <Card className={classes.card}>
                    <CardActionArea onClick={() => handleClick(section.path)}>
                      <CardContent className={classes.cardContent} >
                        <Typography gutterBottom variant="h5" component="h2">
                          {section.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Section>
    );
  };
  
  export default CfServiceMenu;
  
  