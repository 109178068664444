import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ServiceThumb from '../assets/robot_paperwork.png';
import Banner1 from '../assets/banner1.jpeg';
import Banner2 from '../assets/banner2.jpeg';
import shapePattern from '../assets/shape-pattern1.png';
import Smart from '../assets/services/smart.svg';
import Secure from '../assets/services/secure.svg';


const useStyles = makeStyles((theme) => ({
  services: {
    position: 'relative',
    paddingBottom: theme.spacing(7),
  },
  containerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  thumbnail: {
    // marginRight: theme.spacing(6),
    width: '100%',
    display: 'flex',
    position: 'relative',
    '& img': {
      position: 'relative',
      zIndex: 1,
      width: '100%', 
      height: 'auto',
    },
  },
  shapeBox: {
    position: 'absolute',
    bottom: -68,
    left: -160,
    zIndex: -1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  // contentBox: {
  //   width: '100%',
  //   flexShrink: 0,
  //   textAlign: 'left',
  //   marginBottom: theme.spacing(7),
  //   paddingTop: theme.spacing(5),
  //   paddingBottom: theme.spacing(5),
  //   paddingRight: theme.spacing(2),
  //   paddingLeft: theme.spacing(2),
  //   [theme.breakpoints.up('md')]: {
  //     width: 550,
  //     marginBottom: 0,
  //   },
  //   border: '1px solid red'
  // },
  // grid: {
  //   paddingRight: theme.spacing(2),
  //   paddingLeft: theme.spacing(2),
  //   paddingTop: theme.spacing(2),
  //   width: '100%',
  //   gridGap: theme.spacing(3.5),
  //   gridTemplateColumns: 'repeat(1,1fr)',
  // },
  // card: {
  //   display: 'flex',
  //   alignItems: 'flex-start',
  //   transition: 'all 0.3s',
  //   marginTop: theme.spacing(1),
  // },
  // icon: {
  //   width: '50px',
  //   height: 'auto',
  //   flexShrink: 0,
  //   marginRight: theme.spacing(4),
  // },
  // wrapper: {
  //   width: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   textAlign: 'left',
  //   marginTop: '-5px',
  // },
  // subtitle: {
  //   marginBottom: theme.spacing(1),
  // },
  // title: {
  //   marginBottom: theme.spacing(1),
  //   fontWeight: 700,
  // }
}));



// const data = {
//   subTitle: 'next level',
//   title: 'COPYWRITING',
//   features: [
//     {
//       id: 1,
//       imgSrc: Smart,
//       altText: 'AI Copywriting',
//       title: 'AI Copywriting',
//       text:
//         'Get your blood tests delivered at let home collect sample from the victory of the managements. your blood tests.',
//     },
//     {
//       id: 2,
//       imgSrc: Secure,
//       altText: 'Rewrite Contents',
//       title: 'Rewrite Contents',
//       text:
//         'Get your blood tests delivered at let home collect sample from the victory of the managements. your blood tests.',
//     },
//   ],
// };


export default function ServiceSection() {
  const classes = useStyles();

  return (
    <section className={classes.services}>
      <Container className={classes.containerBox}>
        <div className={classes.thumbnail}>
          <img src={Banner1} alt="Thumbnail" />

          <div className={classes.shapeBox}>
            <img src={shapePattern} alt="Shape" />
          </div>
        </div>
        {/* <div className={classes.contentBox}>
          <Typography variant="span" component="h3" className={classes.subtitle}>
            {data.subTitle}
          </Typography>
          <Typography variant="h4" component="h2" className={classes.title}>
            {data.title}
          </Typography>


          <Grid container spacing={5} className={classes.grid}> 
            {data.features.map((item) => (
              <Grid item xs={12} key={item.id}>
                <div className={classes.card}>
                  <img src={item.imgSrc} alt={item.altText} className={classes.icon} />

                  <div className={classes.wrapper}>
                    <Typography variant="h5" component="h3">
                      {item.title}
                    </Typography>
                    <Typography variant="body1">{item.text}</Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div> */}
      </Container>
    </section>
  );
}
