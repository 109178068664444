import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../../util/db";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "760px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  tooltip: {
    fontSize: "1em",
  },
}));

function ImageItems(props) {
  const {
    handleSubmit,
    onImagePromptChange,
    theme,
    onThemeChange,
    description,
    onDescriptionChange,
    aspectRatio,
    onAspectRatioChange,
    model,
    onModelChange,
    distance,
    onDistanceChange,
  } = props;

  const classes = useStyles();
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>
        </Box>
        <Divider />

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            py={2}
            px={3}
            minWidth="200px"
            width="60%"
          >
            <Typography component="span">
              {/* Preset Prompts */}
              Step 1: {t("CPW039")}
            </Typography>
            <FormControl>
              <InputLabel
                id="select-label"
                margin="dense"
                disableAnimation={true}
              >
                {/* 选择主题 */}
                {t("CPW080")}
              </InputLabel>
              <Select
                labelId="select-label"
                id="theme"
                label="tone"
                value={theme}
                onChange={onThemeChange}
              >
                <MenuItem value={"self"}>自定义 Self-Defined</MenuItem>
                <MenuItem value={"cafe"}>咖啡厅 Cafe</MenuItem>
                <MenuItem value={"office"}>办公室 Office</MenuItem>
                <MenuItem value={"hospital"}>医院 Hospital</MenuItem>
                <MenuItem value={"trad-kitchen"}>传统厨房 Kitchen</MenuItem>
                <MenuItem value={"modern-kitchen"}>现代厨房 Kitchen</MenuItem>
                <MenuItem value={"trad-livingroom"}>
                  传统客厅 Living Room
                </MenuItem>
                <MenuItem value={"modern-livingroom"}>
                  现代客厅 Living Room
                </MenuItem>
                <MenuItem value={"garage"}>车库 Garage</MenuItem>
                <MenuItem value={"pet-cafe"}>宠物咖啡厅 Pet Cafe</MenuItem>
                <MenuItem value={"clinic"}>诊所 Clinic</MenuItem>
                <MenuItem value={"restaurant"}>高级餐厅 Restaurant</MenuItem>
              </Select>
            </FormControl>

            {theme === "self" && (
              <Box display="flex" flexDirection="column" pt={1} px={1}>
                <TextField
                  id="description"
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={t("CPW082")}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows="3"
                  maxRows="3"
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            py={2}
            px={3}
            minWidth="200px"
            width="60%"
          >
            <Typography component="span">
              {/* Aspect Ratio */}
              Step 2: {t("CPW084")}
            </Typography>
            <FormControl>
              <InputLabel
                id="select-aspect"
                margin="dense"
                disableAnimation={true}
              >
                {/* 选择照片宽高比 */}
                {t("CPW084")}
              </InputLabel>
              <Select
                labelId="select-aspect"
                id="aspectRatio"
                label="tone"
                value={aspectRatio}
                onChange={onAspectRatioChange}
              >
                <MenuItem value={"square"}>{t("CPW085")}</MenuItem>
                <MenuItem value={"vertical"}>{t("CPW086")}</MenuItem>
                <MenuItem value={"horizontal"}>{t("CPW087")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            py={2}
            px={3}
            minWidth="200px"
            width="60%"
          >
            <Typography component="span">
              {/* Model Selection */}
              Step 3: {t("CPW090")}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="model"
                name="model"
                value={model}
                onChange={onModelChange}
              >
                <FormControlLabel
                  value="realistic"
                  control={<Radio />}
                  label={t("CPW091")}
                />
                <FormControlLabel
                  value="cartoon"
                  control={<Radio />}
                  label={t("CPW092")}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            py={2}
            px={3}
            minWidth="200px"
            width="60%"
          >
            <Typography component="span">
              {/* Distance */}
              Step 4: {t("CPW093")}
            </Typography>
            <FormControl>
              <InputLabel
                id="select-distance"
                margin="dense"
                disableAnimation={true}
              >
                {/* 选择相机距离 */}
                {t("CPW093")}
              </InputLabel>
              <Select
                labelId="select-distance"
                id="distance"
                label="tone"
                value={distance}
                onChange={onDistanceChange}
              >
                <MenuItem value={"long"}>{t("CPW094")}</MenuItem>
                <MenuItem value={"medium"}>{t("CPW095")}</MenuItem>
                <MenuItem value={"short"}>{t("CPW096")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Buttons */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Tooltip title={t("CPW088")} classes={{ tooltip: classes.tooltip }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={!description && !theme}
              onClick={(e) => handleSubmit(1, e)}
              mb={2}
            >
              {/* 生成图片 */}
              {t("CPW036")}
            </Button>
          </Tooltip>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          py={2}
          px={3}
        >
          <Tooltip title={t("CPW089")} classes={{ tooltip: classes.tooltip }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={(e) => handleSubmit(2, e)}
              disabled={!description}
            >
              {/* 优化提示 */}
              {t("CPW083")}
            </Button>
          </Tooltip>
        </Box>
      </Paper>
    </>
  );
}

export default ImageItems;
