import React from "react";
import Meta from "../components/Meta";

import CfMenu from "../components/malay/CfMenuPage";

function CfMenuPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" description="Menu" />
      <CfMenu 
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="Menu"/>
    </>
  );
}

export default CfMenuPage;
