import React, { useState, useEffect } from 'react';
import { getFirestore, collectionGroup, query, orderBy, onSnapshot, endBefore, limit, where } from 'firebase/firestore';
import { firebaseApp } from '../util/firebase';
import Box from "@material-ui/core/Box";
import { Paper, Tab, Tabs, Typography, Button, IconButton, Select, Menu } from '@material-ui/core';
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../util/auth";
import { Link } from "./../util/router";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import copy, { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toaster, toast } from 'react-hot-toast';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IdIdentifierGeneral, IdIdentifierSpecific } from './functionalComponent/IdIdentifier';
import { debounce } from 'lodash';
import { NoteAddOutlined } from '@material-ui/icons';
import { saveCMS } from '../util/db.js';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    boxShadow: theme.shadows[4],
  },
  button: {
    color: 'white',
    backgroundColor: '#3f51b5',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
    margin: '10px',
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    margin: '10px',
  },
  typography: {
    fontWeight: 'bold',
  },
    container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const HistorySection = (props) => {
  const classes = useStyles();
  const auth = useAuth();

  const [usageData, setUsageData] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [selectedTab, setSelectedTab] = useState('');
  const [tabValues, setTabValues] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({});

  const maxResults = 25;
  const userEmail = auth.user.email;
  const filteredData = usageData.filter(item => IdIdentifierGeneral({ id: item.obj.id }) === selectedTab);

  const fetchData = () => {
    const db = getFirestore(firebaseApp);

    const timeFrame = new Date();
    timeFrame.setMonth(timeFrame.getMonth() - 2); // get last 2 months

    const q = query(
            collectionGroup(db, "usage"),
            orderBy("obj.time", "desc"),
            // endBefore(timeFrame),  // get data by last # months
            limit(200),  // get data by latest # number of results
            where("obj.email", "==", userEmail),
          )
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsageData(data);
        setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
        setHasNextPage(data.length === maxResults);
      } else {
        setHasNextPage(false);
      }
    }, (error) => {
      console.log("Error fetching data: ", error);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = fetchData();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const uniqueValues = [...new Set(usageData.map(item => IdIdentifierGeneral({ id: item.obj.id })))];
    setTabValues(uniqueValues);
    if (uniqueValues.length > 0) {
      setSelectedTab(uniqueValues[0]);
      let initialPaginationInfo = {};
      uniqueValues.forEach(value => {
        initialPaginationInfo[value] = { currentPage: 1, totalPages: Math.ceil(usageData.filter(item => IdIdentifierGeneral({ id: item.obj.id }) === value).length / maxResults) };
      });
      setPaginationInfo(initialPaginationInfo);
    }
  }, [usageData]);

  useEffect(() => {
    const currentPage = paginationInfo[selectedTab]?.currentPage || 1;
    const start = (currentPage - 1) * maxResults;
    const end = start + maxResults;
    const newPaginatedData = filteredData.slice(start, end);
  
    // Only update state if paginatedData has changed
    if (JSON.stringify(paginationInfo[selectedTab]?.paginatedData) !== JSON.stringify(newPaginatedData)) {
      setPaginationInfo(prevPaginationInfo => ({
        ...prevPaginationInfo,
        [selectedTab]: {
          ...prevPaginationInfo[selectedTab],
          paginatedData: newPaginatedData
        }
      }));
    }
  }, [filteredData, selectedTab]);  

  const handleTabChange = debounce((event, newValue) => {
    setSelectedTab(newValue);
    setPaginationInfo(prevPaginationInfo => ({
      ...prevPaginationInfo,
      [newValue]: {
        ...prevPaginationInfo[newValue],
        currentPage: 1
      }
    }));
  }, 500);

  const nextPage = () => {
    const currentPage = paginationInfo[selectedTab]?.currentPage || 1;
    const totalPages = paginationInfo[selectedTab]?.totalPages || 0;
    if (currentPage < totalPages) {
      setPaginationInfo(prevPaginationInfo => ({
        ...prevPaginationInfo,
        [selectedTab]: {
          ...prevPaginationInfo[selectedTab],
          currentPage: currentPage + 1
        }
      }));
    }
  }

  const prevPage = () => {
    const currentPage = paginationInfo[selectedTab]?.currentPage || 1;
    if (currentPage > 1) {
      setPaginationInfo(prevPaginationInfo => ({
        ...prevPaginationInfo,
        [selectedTab]: {
          ...prevPaginationInfo[selectedTab],
          currentPage: currentPage - 1
        }
      }));
    }
  }

  return (
    <Section
    bgColor={props.bgColor}
    size={props.size}
    bgImage={props.bgImage}
    bgImageOpacity={props.bgImageOpacity}
  >
    <Container>
      <Toaster position="top-right" reverseOrder={false}/>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={4}
        textAlign="center"
      />

      <Box sx={{ m: 0, p: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Button fullWidth color="inherit" component={Link} to="/image-history">
            图片生成历史 Image History
          </Button>
        </Box>
        <Box flexGrow={1}>
          <Button fullWidth color="inherit" component={Link} to="/replace-history">
            产品图生成历史 Replace History
          </Button>
        </Box>
      </Box>

        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable">
          {tabValues.map((value, index) => (
            <Tab label={value} value={value} key={index} />
          ))}
        </Tabs>

        {tabValues.map((value, index) => {
          if (value === selectedTab) {
            const currentPage = paginationInfo[value]?.currentPage || 1;
            const totalPages = paginationInfo[value]?.totalPages || 0;
            const paginatedData = paginationInfo[value]?.paginatedData || [];
            return (
              <div key={index}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? classes.disabledButton : classes.button}
                  >
                    Prev
                  </Button>
                  <Box sx={{ p: 2}}>
                    <Typography variant='subtitle2'>
                      Page {currentPage} of {totalPages}
                    </Typography>
                  </Box>

                  <Button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className={currentPage === totalPages ? classes.disabledButton : classes.button}
                  >
                    Next
                  </Button>
                </Box>
                {paginatedData.map((item, index) => (
                  <Paper className={classes.paper} key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2">{new Date(item.obj.time.seconds * 1000 + (8 * 60 * 60 * 1000)).toLocaleString()}</Typography>
                      <Typography variant="body2"><IdIdentifierSpecific id={item.obj.id} /></Typography>
                      <CopyToClipboard
                        text={item.obj.output}
                        onCopy={() => toast.success('Successfully copied!')}
                        options={{format: 'text/plain'}}
                      >
                        <IconButton color="default">
                          <FileCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </Box>
                    <br />
                    <Typography variant="body1">
                      {selectedTab === "Attention 文案" ? 
                        item.obj.output
                        .split(/\n|<br \/>/)
                        .map((paragraph) => (
                          <React.Fragment key={paragraph}>
                            {/* <IconButton onClick={() => saveCMS(auth.user.uid, paragraph)}>
                              <NoteAddOutlined />
                            </IconButton> */}
                            {paragraph}<br />
                          </React.Fragment>
                        ))
                        :
                        item.obj.output
                          .split(/\n|<br \/>/)
                          .map((paragraph, index) => (
                            <React.Fragment key={index}>{paragraph}<br /></React.Fragment>
                          ))
                      }
                    </Typography>
                  </Paper>
                ))}
              </div>
            );
          }
          return null;
        })}
      </Box>
    </Container>
    </Section>
    );
};

export default HistorySection;
