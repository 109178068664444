import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfIngredientsSection from "../../components/malay/CfIngredientsSection";

function CfIngredientsPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" />
      <CfIngredientsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="独家成分/技术"
      />
    </>
  );
}

export default requireProUser(CfIngredientsPage);
