import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfGongSiSection from "../../components/malay/CfGongSiSection";

function CfGongSiPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (Malay)" />
      <CfGongSiSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (Malay)"
        subtitle="公司背景"
      />
    </>
  );
}

export default requireProUser(CfGongSiPage);
