import React from "react";
import Meta from "../components/Meta";
import { requireProUser } from "../util/auth";
import SwotSection from "../components/SwotSection";

function SwotPage(props) {
  return (
    <>
      <Meta title="SWOT Analysis Report Generator" />
      <SwotSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="强弱危机分析"
        subtitle="SWOT"
      />
    </>
  );
}

export default requireProUser(SwotPage);
