import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { updateItem, deleteItem, useItemsByOwner } from "../util/db";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
    height: "760px"
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function TesterItems(props) {
  const { handleSubmit, onProductTypeChange, onProductSellingPointChange } = props;
  
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);


  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");

  const handleStarItem = (item) => {
    if (canUseStar) {
      updateItem(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  return (
    <>
      {itemsError && (
        <Box mb={3}>
          <Alert severity="error">{itemsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Generator</Typography>

        </Box>
        <Divider />

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 1</Typography>
          <TextField 
            onChange={onProductTypeChange}
            value={props.productType}
            margin="dense"
            name="productType"
            fullWidth
            variant="outlined"
            placeholder={"输入你的产品种类"}
            multiline
            minRows={3}
            maxRows={3}
            sx={{
              overflow: "hidden",
            }}
            required
          />
        </Box>

        <Box display="flex" flexDirection="column" py={2} px={3} >
          <Typography component="span">Step 2</Typography>
          <TextField 
            onChange={onProductSellingPointChange}
            value={props.productSellingPoint}
            margin="dense"
            name="productSellingPoint"
            fullWidth
            variant="outlined"
            placeholder={"输入你的产品独特卖点"}
            multiline
            minRows={2}
            maxRows={2}
            sx={{
              overflow: "hidden",
            }}
            required
          />
        </Box>


        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(1, e)}
          >
            Test
          </Button>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-around" py={2} px={3}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            onClick={(e) => handleSubmit(2, e)}
          >
            Test 2
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default TesterItems;
