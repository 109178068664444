import React, { useEffect, useState } from 'react';

function Loader() {
  const [loadingText, setLoadingText] = useState("生成中...");

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      switch (loadingText) {
        case "生成中...":
          setLoadingText("生成中");
          break;
        case "生成中":
          setLoadingText("生成中.");
          break;
        case "生成中.":
          setLoadingText("生成中..");
          break;
        case "生成中..":
          setLoadingText("生成中...");
          break;
        default:
          setLoadingText("生成中...");
      }
    }, 300);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(loadingInterval);
    };
  }, [loadingText]); // re-run effect when `loadingText` changes

  return <div>{loadingText}</div>;
}

export default Loader;
