import React from "react";
import Meta from "../../components/Meta";
import { requireProUser } from "../../util/auth";
import CfEndResultSection from "../../components/eng/CfEndResultSection";

function CfEndResultPage(props) {
  return (
    <>
      <Meta title="Confidence Reply (English)" />
      <CfEndResultSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Confidence Reply (English)"
        subtitle="End Result"
      />
    </>
  );
}

export default requireProUser(CfEndResultPage);
