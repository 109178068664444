import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../style/container.css'
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import CfZhuanYeItems from "./CfZhuanYeItems";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "585px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function CfZhuanYeSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const [productName, setProductName] = useState("");
  const [productDistinct, setProductDistinct] = useState("");
  const [productCert, setProductCert] = useState("")

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductDistinctChange = (event) => {
    setProductDistinct(event.target.value);
  };

  const handleProductCertChange = (event) => {
    setProductCert(event.target.value);
  };

  let query 

  async function handleSubmit( id, e, lastInput = false, speechRes = false, credit = 0.4 ) {
    e && e.preventDefault();
    // const data = new FormData(formRef.current);


    // if (id === 'cf-zhuanye') {
    //   query = `产品名字是${productName}, 种类是${productUsp}，与众不同的专业背景是${productEndResult}，得到的认证是${productCert}`
    // }

    query = `Product info: \n
    The product name and type is: ${productName} \n
    The distinctive professional background is ${productDistinct} \n
    The received award is: ${productCert}`;
    
    let payload = { selfDefinedValue, query, id, credit }

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <CfZhuanYeItems 
              handleSubmit={handleSubmit} 
              onProductNameChange={handleProductNameChange}
              onProductDistinctChange={handleProductDistinctChange}
              onProductCertChange={handleProductCertChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default CfZhuanYeSection;