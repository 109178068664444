import React, { useContext, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import { useAuth } from "../../util/auth";
import { chatContext } from "../../context/ChatContext"
import '../../style/container.css'
import { Divider } from "@material-ui/core";
import ReplyItems from "./ReplyItems";
import { fetchData, OutputContainer } from "./../functionalComponent/fetchData";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    // padding: theme.spacing(3),
    height: "985px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  ay: {
  },
  copy: {
    display: ({ typing, load, error }) => typing || load || error ? "none" : "block",
    cursor: "pointer",
    "& img": { width: "22px" },
  }
}));

function ReplySection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [error, setError] = useState(false);
  
  const {
    setIsChatOpen,
    setClearChat,
    typingInterval,
    selfDefinedValue,
  } = useContext(chatContext);

  const chat_container_ref = useRef(null);
  const [productName, setProductName] = useState("");
  const [productUSP, setProductUSP] = useState("");
  const [painPoint, setPainPoint] = useState("");
  const [productIngredient, setProductIngredient] = useState("");
  const [productEndResult, setProductEndResult] = useState("");
  const [productPromo, setProductPromo] = useState("");

  const [legacyMode, setLegacyMode] = useState(false);

  // State to manage the list of messages
  const [messages, setMessages] = useState([]);

  const handleLegacyModeChange = (newLegacyMode) => {
    setLegacyMode(newLegacyMode);
  };

  const handleProductNameChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductName(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductUSPChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductUSP(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handlePainPointChange = (event) => {
    setPainPoint(event.target.value);
  };

  const handleProductIngredientChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductIngredient(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductEndResultChange = (event) => {
    if (!event || (typeof event === 'object' && !event.target)) {
      return;
    } else {
      setProductEndResult(event.target && event.target.value ? event.target.value : event);
    }
  };

  const handleProductPromoChange = (event) => {
    setProductPromo(event.target.value);
  };

  async function handleSubmit(id, e, lastInput = false, speechRes = false, credit = 0.4) {
    e && e.preventDefault();

    let query 
    // query = `产品名字和种类是${productName}, 独特卖点是${productUSP}，成分是${productIngredient}，功效是${productEndResult}，痛点是${painPoint}`
    query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n
      The unique ingredient or technology is: ${productIngredient} \n
      The end result is: ${productEndResult} \n
      The pain point is: ${painPoint} \n` 

    if (id === "acca-action-cn" || id === "acca-action-en" || id === "acca-action-malay") {
      // query = `产品名字和种类是${productName}, 独特卖点是${productUSP}，成分是${productIngredient}，功效是${productEndResult}，我们的优惠配套资料是${productPromo}`
      query = `Product info: \n
      The product name or type is: ${productName} \n
      Unique Selling Point is: ${productUSP} \n
      The unique ingredient or technology is: ${productIngredient} \n
      The end result is: ${productEndResult} \n
      The pain point is: ${painPoint} \n
      Current promotional information is: ${productPromo} \n` 
    }

    let payload = { selfDefinedValue, query, id, credit }

    if (!query) return;

    // bot's chatStripe
    setLoading(true);
    setIsChatOpen(true); 
    setClearChat(false);

    // Call the fetchData function
    fetchData(payload, auth, setMessages, setError, setLoading, setTyping, typingInterval);
  }
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />


        <Grid container={true} spacing={4}>

          {/* Left Grid */}
          <Grid item={true} xs={12} md={6}>
            <ReplyItems 
              handleSubmit={handleSubmit}
              productName={productName}
              painPoint={painPoint}
              productUSP={productUSP}
              productIngredient={productIngredient}
              productEndResult={productEndResult}
              productPromo={productPromo}
              onProductNameChange={handleProductNameChange}
              onProductUSPChange={handleProductUSPChange}
              onPainPointChange={handlePainPointChange}
              onProductIngredientChange={handleProductIngredientChange}
              onProductEndResultChange={handleProductEndResultChange}
              onProductPromoChange={handleProductPromoChange}
              legacyMode={legacyMode} 
              onLegacyModeChange={handleLegacyModeChange}
            />
          </Grid>
        
          {/* Right Grid */}
          <Grid item={true} xs={12} md={6}>
            <Card>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Output</Typography>
              </Box>
              <Divider />
              <CardContent className={classes.cardContent}>
                <Box>

                  <OutputContainer 
                    typing={typing}
                    load={loading}
                    error={error}
                    messages={messages}
                    id="chat_container"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default ReplySection;