import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collectionGroup, query, orderBy, onSnapshot, limit, startAfter, endBefore } from 'firebase/firestore';
import { firebaseApp } from '../util/firebase';

import Box from "@material-ui/core/Box";
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    boxShadow: theme.shadows[4],
  },
}));

const TrackingSection = () => {
  const [usageData, setUsageData] = useState([]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [firstVisibleDoc, setFirstVisibleDoc] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const classes = useStyles();
  const maxResults = 20;

  // Helper function to create a query based on the direction and cursor
  const createQuery = (db, direction, cursor) => {
    if (direction === "next") {
      return cursor
        ? query(
            collectionGroup(db, "usage"),
            orderBy("obj.time", "desc"),
            startAfter(cursor),
            limit(maxResults)
          )
        : query(collectionGroup(db, "usage"), orderBy("obj.time", "desc"), limit(maxResults));
    } else if (direction === "prev") {
      return query(
        collectionGroup(db, "usage"),
        orderBy("obj.time", "desc"),
        endBefore(cursor),
        limit(maxResults)
      );
    }
  };

  // Fetch usage data from Firestore
  const fetchData = async (cursor = null, direction = "next") => {
    const db = getFirestore(firebaseApp);
    const q = createQuery(db, direction, cursor);
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFirstVisibleDoc(snapshot.docs[0]);
        setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);
        setUsageData(data);
        setHasNextPage(data.length === maxResults);
        setHasPrevPage(!!cursor);
      } else {
        setHasNextPage(false);
      }
    });
  
    return unsubscribe;
  };

  // Navigate to the next page
  const nextPage = () => {
    if (hasNextPage) {
      const unsubscribe = fetchData(lastVisibleDoc, "next");
      return unsubscribe;
    }
  };
  
  // Navigate to the previous page
  const prevPage = () => {
    if (hasPrevPage) {
      const unsubscribe = fetchData(firstVisibleDoc, "prev");
      return unsubscribe;
    }
  };
  
  // Fetch data on component mount
  useEffect(() => {
    const unsubscribe = fetchData();

    return unsubscribe
  }, []);

  
  // Render the component
  return (
    <Box sx={{ m: 5, p: 5, display: "flex", flexDirection: "column", justifyContent: "center" }}>
      {usageData.map((item) => (
        <Paper className={classes.paper}>
            <Typography variant="h6">{item.obj.name}</Typography>
            <Typography variant="h6">{item.obj.email}</Typography>
            <Typography variant="body1">Input: {item.obj.input}</Typography>
            <Typography variant="body1">Output: {item.obj.output}</Typography>
            <Typography variant="body2">Time: {new Date(item.obj.time.seconds * 1000 + (8 * 60 * 60 * 1000)).toLocaleString()}</Typography>
        </Paper>
      ))}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <button onClick={prevPage} disabled={!hasPrevPage}>
        Previous
        </button>
        <button onClick={nextPage} disabled={!hasNextPage}>
        Next
        </button>
      </Box>
    </Box>
    );
    };

export default TrackingSection;
