import React, { useState } from 'react';
import { useUsers, updateUser } from '../util/db';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment 
} from '@material-ui/core';
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { toast, Toaster } from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: '110px',
  },
}));

function UserList(props) {
  const classes = useStyles();
  const { data: users, isLoading, error } = useUsers();

  const [selectedUserRoles, setSelectedUserRoles] = useState({});
  const [selectedUserCredits, setSelectedUserCredits] = useState({});
  const [search, setSearch] = useState('');
  
  // const filteredUsers = ( users && users.filter((user) => {
  //   if (search === '') return true;
  
  //   return user.email.toLowerCase().includes(search.toLowerCase());
  // }))
  const filteredUsers = users && users.filter(user => {
    if (search === '') return true;
    if (user.email) {
      return user.email.toLowerCase().includes(search.toLowerCase());
    } else {
      return false;
    }
  });
  const handleRoleChange = (userId, newRole) => {
    setSelectedUserRoles({ ...selectedUserRoles, [userId]: newRole });
  };

  const handleCreditChange = (userId, newCredits) => {
    setSelectedUserCredits({ ...selectedUserCredits, [userId]: newCredits });
  };

  const saveChanges = async (userId) => {
    try {
      if (selectedUserRoles[userId]) {
        await updateUser(userId, { role: selectedUserRoles[userId] });
      }
      if (selectedUserCredits[userId]) {
        await updateUser(userId, { credits: parseInt(selectedUserCredits[userId]) });
      }
      setSelectedUserRoles({})
      setSelectedUserCredits({})
      toast.success('Changes saved successfully');
    } catch (err) {
      console.error('Error updating user data:', err);
      toast.error('Error saving changes');
    }
  };
  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="h6">Error: {error.message}</Typography>;
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
        <Toaster />
        <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Typography variant="h4">User List</Typography>
        <TextField
            label="Search users"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <SearchIcon />
                </InputAdornment>
                ),
            }}
            fullWidth
            margin="normal"
            />
            <List className={classes.root}>
            {filteredUsers.map((user) => (
            <ListItem key={user.id}>
                <ListItemText primary={user.email} secondary={`Role: ${user.role} | Credits: ${user.credits}`} />
                <ListItemSecondaryAction>
                <TextField
                    select
                    className={classes.textField}
                    label="Role"
                    value={selectedUserRoles[user.id] || ''}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    SelectProps={{
                    native: true,
                    }}
                    variant="outlined"
                >
                    <option value=""></option>
                    <option value="user">User</option>
                    <option value="Pro">Pro</option>
                    <option value="Elite">Elite</option>
                    {/* <option value="admin">Admin</option> */}
                </TextField>
                <TextField
                    select
                    className={classes.textField}
                    label="Credits"
                    value={selectedUserCredits[user.id] || ''}
                    onChange={(e) => handleCreditChange(user.id, e.target.value)}
                    SelectProps={{
                    native: true,
                    }}
                    variant="outlined"
                >
                    <option value=""></option>
                    <option value="0">0</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </TextField>
                <IconButton edge="end" onClick={() => saveChanges(user.id)}>
                    <SaveIcon />
                </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            ))}
            </List>
        </Container>
    </Section>
  );
}

export default UserList;
